<template>
    <div class="col-sm-12 nopads p-1 mb-2">
        <b-row class="nopads">
            <b-col cols="4">
                <b-form-group :label="$t('trip_list.select_user')">
                    <multiselect
                        v-model="user"
                        :placeholder="$t('trip_list.select_user')"
                        :deselect-label="$t('common.remove_selection')"
                        :select-label="$t('common.select')"
                        :close-on-select="true"
                        :multiple="false"
                        label="name"
                        :custom-label="userName"
                        track-by="name"
                        :searchable="true"
                        :internal-search="true"
                        :selected-label="$t('common.selected')"
                        :options="users">
                    </multiselect>
                </b-form-group>
            </b-col>
            <b-col cols="8">
                <b-row v-for="(row, index) in scheduledPeriods" :key="'item' + index" class="align-items-center nopads">
                    <b-col cols="4">
                        <b-form-group :label="$t('common.start_date')">
                            <datetime
                                v-model="row.start_date"
                                type="datetime"
                                format="dd.MM.yyyy HH:mm"
                                class="routa-input"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="4">
                        <b-form-group :label="$t('common.end_date')">
                            <datetime
                                v-model="row.end_date"
                                type="datetime"
                                :min-datetime="row.start_date"
                                format="dd.MM.yyyy HH:mm"
                                class="routa-input"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="2" class="pt-2">
                        <b-button
                            variant="danger"
                            size="sm"
                            class="pt-1 pb-1 mx-1"
                            :disabled="scheduledPeriods.length <= 1"
                            @click.stop="removeDate(index)"
                        >
                            <i class="fa fa-trash" style="font-size: .8em; padding: 0; line-height: 1em;"/>
                        </b-button>
                        <b-button
                            variant="success"
                            size="sm"
                            class="pt-1 pb-1 mx-1"
                            @click.stop="addDate"
                        >
                            <i class="fa fa-plus" style="font-size: .8em; padding: 0; line-height: 1em;"/>
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <div class="button-container">
            <b-button
                size="sm"
                variant="secondary"
                class="form-button"
                @click.stop="clearForm">
                {{ $t('common.clear') }}
            </b-button>
            <b-button
                size="sm"
                variant="success"
                class="form-button"
                :disabled="!isFormFilled"
                @click.stop="saveSchedule">
                {{ $t('common.save') }}
            </b-button>
        </div>
    </div>
</template>

<script>

export default {
    name: "ContractDatePicker",
    props: {
        index: {
            type: Number,
            default: null
        },
        dutyOfficerSchedules: {
            type: Array,
            default() {
                return []
            }
        },
        users: {
            type: Array,
            default() {
                return []
            }
        }
    },
    computed: {
        isFormFilled() {
            let formFilled = true
            for (const schedule of this.scheduledPeriods) {
                const filled = Object.values(schedule).every(value => !!value)
                if (!filled) {
                    formFilled = false
                    break
                }
            }

            return !!this.user && formFilled
        },
    },
    data() {
        return {
            scheduledPeriods: [{}],
            user: null,
        };
    },
    methods: {
        saveSchedule() {
            const data = {'user': this.user, 'dates': this.scheduledPeriods};
            this.$emit('save', data);
        },
        clearForm() {
            this.user = null
            this.scheduledPeriods = [{}]
        },
        userName({first_name, last_name}) {
            return `${first_name}  ${last_name}`;
        },
        removeDate(index) {
            this.scheduledPeriods.splice(index, 1);
        },
        addDate() {
            this.scheduledPeriods.push({})
        }

    }
}
</script>

<style scoped>

</style>
