<script src="../mixins/RestApiMixin.js"></script>
<template>
    <div v-show="false">
        <div v-if="svgPath" ref="svgIcon">
            <div class="image-container">
                <img v-if="greyScale" :src="svgPath" class="image" style="filter: grayscale(100%)" alt="Ei näy kuvaa"/>
                <img v-else :src="svgPath" class="image" alt="Ei näy kuvaa"/>
                <span v-if="text" class="overlay-text">{{ text }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MapSvgMarker',
    data() {
        return {
            svgPath: null,
            greyScale: false,
            text: null,
            leaflet: Boolean
        }
    },
    methods: {
        setSvgIcon: function (svg, greyscale, value = null) {
            this.svgPath = svg;
            this.greyScale = greyscale
            this.text = value
        },
        getSvgIcon: function () {
            return this.$refs.svgIcon.innerHTML
        }
    }
}
</script>
<style scoped>
.image-container {
    position: relative;
    display: inline-block;
    width: 1.9em; /* Set width for the container (adjust as needed) */
    height: 1.9em; /* Set height for the container (adjust as needed) */
}

.image {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensures the image covers the container without distortion */
}

.overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000000;
    font-size: .9em;
    font-weight: bold;
    text-align: center;
}


</style>
