<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="col-sm-12 list-container">
        <transition name="fade">
            <div
                v-if="results"
                id="accordion"
                class="col-sm-12 list-content result-table"
            >
                <b-table
                    hover
                    show-empty
                    small
                    class="pointer"
                    :items="results"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    @row-clicked="emitEdit"
                    :sort-compare-locale="$i18n.locale"
                >
                    <template slot="empty">
                        <div class="text-center my-2">
                            {{ $t('common.no_results') }}
                        </div>
                    </template>
                    <template v-slot:cell(created)="row">
                        {{ getShortDateString(row.value) }}
                    </template>
                    <template v-slot:cell(closing_date)="row">
                        {{ getDateString(row.value) }}
                    </template>
                    <template v-slot:cell(created_by)="row">
                        {{ row.value.last_name + ' ' + row.value.first_name }}
                    </template>
                    <template v-slot:cell(task)="data">
                        {{ getTitle(data.item) }}
                    </template>
                    <template v-slot:cell(assigned_to)="row">
                        {{ row.value ? row.value.last_name + ' ' + row.value.first_name : '' }}
                    </template>
                    <template v-slot:cell(status)="row">
                        {{ getStatusString(row.value) }}
                    </template>
                    <template v-slot:cell(priority_value)="row">
                        {{ getPriorityString(row.value) }}
                    </template>
                    <template v-slot:cell(progress_value)="row">
                        {{ getProgressValue(row.item) }}
                    </template>
                    <template v-slot:cell(work_time)="row">
                        {{ row.item.work_time && row.item.work_time > 0 ? row.item.work_time : '' }}
                    </template>
                </b-table>
            </div>
        </transition>
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {workAssignmentHelper} from '../mixins/WorkAssignmentMixin'

export default {
    name: 'WorkAssignmentList',
    mixins: [timeUtils, workAssignmentHelper],
    props: {
        results: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            sortBy: 'created',
            sortDesc: false,

        }
    },
    computed: {
        fields() {
            return [
                {key: 'id', label: this.$t('work_assignment.id'), sortable: true},
                {key: 'task', label: this.$t('work_assignment.task'), sortable: true},
                {key: 'created', label: this.$t('work_assignment.created_time'), sortable: true},
                {key: 'created_by', label: this.$t('work_assignment.creator'), sortable: true},
                {key: 'assigned_to', label: this.$t('work_assignment.assigned_to'), sortable: true},
                {key: 'status', label: this.$t('orders.status'), sortable: true},
                {key: 'priority_value', label: this.$t('work_assignment.select_priority'), sortable: true},
                {key: 'progress_value', label: this.$t('work_assignment.work_progress'), sortable: true},
                {key: 'closing_date', label: this.$t('work_assignment.closed_time'), sortable: true},
                {key: 'work_time', label: this.$t('work_assignment.work_time'), sortable: true},
                {key: 'info', label: this.$t('area_report_details.info'), sortable: false},
            ]
        }
    },
    methods: {
        emitEdit: function (details) {
            this.$emit('edit', details.id)
        },
        getProgressValue(item) {
            if (item.progress_value) {
                return item.progress_value + " %"
            } else if (item.status === this.COMPLETED) {
                return "100 %"
            } else {
                return '-'
            }
        }
    }
}
</script>
