<template>
    <div class="details-container" style="z-index: 20 !important;">
        <div class="details dynamic_width">
            <div class="col-sm-12 nopads pl-2 pr-2">
                <span class="pl-2 pb-2 title" style="text-transform: uppercase">{{ $t('orders.copy_geometry_title') }}</span>
                <span class="pl-2 pr-2 pb-2">{{ $t('orders.export_geometry_title') }}</span>
            </div>
            <div class="col-12">
                <!-- Task type selection - only in add mode -->
                <span class="tip-light">
                    {{ $t('orders.contract') }}
                </span>
                <open-contract-filter-multi-select
                    :from="(new Date()).toISOString()"
                    :pre-selected-contract="contract ? contract.id : null"
                    :showOnlyMyContractsOption="true"
                    :showTitle="false"
                    :show-company="false"
                    @contractsSelected="setContracts"
                />
                <!-- Task type selection - only in add mode -->
                <span class="tip-light">
                    {{ $t('orders.order') }}
                </span>
                <div class="pl-3 pr-3">
                    <multiselect
                        v-model="selectedExportOrders"
                        :options="orderOptions"
                        :multiple="true"
                        :close-on-select="false"
                        label="text"
                        track-by="value"
                        :searchable="true"
                        :internal-search="true"
                        :selected-label="$t('common.selected')"
                        :select-label="$t('common.select')"
                        :deselect-label="$t('common.remove_selection')"
                        :placeholder="$t('orders.select_order')"
                    >
                        <span slot="noResult">{{ $t('common.not_found') }}</span>
                        <span slot="noOptions">{{ $t('common.no_results') }}</span>
                    </multiselect>
                </div>
            </div>
            <div class="col-12 button-container">
                <b-button size="sm" class="form-button" variant="secondary" @click="cancelCopying">
                    {{ $t('common.cancel') }}
                </b-button>
                <b-button size="sm" class="form-button" variant="success" @click="doCopyOrderGeometry">
                    {{ $t('common.copy') }}
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import {restApi} from "@/components/mixins/RestApiMixin";
import {orderMixin} from "@/components/mixins/OrderMixin";
import OpenContractFilterMultiSelect from "@/components/contract/OpenContractFilterMultiSelect.vue";

export default {
    name: "OrderGeometryExportModal",
    components: {OpenContractFilterMultiSelect},
    mixins: [restApi, orderMixin],
    props: {
        contract: {
            type: Object,
            default: null
        },
    },
    watch: {
        contractIds() {
            this.fetchOrderOptions(this.contractIds)
        }
    },
    data: function () {
        return {
            contractIds: [],
            orderOptions: [],
            selectedExportOrders: []
        }
    },
    methods: {
        fetchOrderOptions: function (contractIds) {
            this.loading = true
            this.orderOptions = []
            this.$nextTick(() => {
                this.restFetchParams(this.orderUrl, {contract_ids: contractIds}, this.handleOrderOptions)
            })
        },
        handleOrderOptions: function (response) {
            if (response && response.data) {
                response.data.forEach(order => {
                    this.orderOptions.push({
                        value: order.id,
                        text: order.id + ' - ' + this.getOrderStringWithInfo(order)
                    })
                })
            }
            this.loading = false
        },
        setContracts: function (contracts) {
            this.contractIds = []
            contracts.map(contract => {
                this.contractIds.push(contract.id)
            })
        },
        cancelCopying() {
            this.$emit('cancelCopy')
        },
        doCopyOrderGeometry() {
            let orderIds = this.selectedExportOrders.map(item => {
                return item.value
            })
            this.$emit('copyGeometry', orderIds)
        }
    }
}
</script>
