<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="report">
        <!-- Basic info -->
        <h3 class="pl-1">{{ $t('area_report_details.title') }}</h3>
        <table class="table table-sm">
            <tbody>
            <tr v-if="reportDetails.status === completed">
                <td class="item-detail-title">
                    {{ $t('area_report.completed') }}
                </td>
                <td class="item-detail-text">
                    {{ toLocalTime(reportDetails.completed_time) }}
                </td>
            </tr>

            <tr>
                <td class="item-detail-title">
                    {{ $t('area_report_details.inspector') }}
                </td>
                <td class="item-detail-text">
                    {{ reportDetails.reporter.last_name }}, {{ reportDetails.reporter.first_name }}
                </td>
            </tr>

            <tr>
                <td colspan="2">
                    <span class="span-header">{{ $t('area_report_details.area_info') }}</span>
                </td>
            </tr>

            <tr>
                <td class="item-detail-title">
                    {{ $t('area_report_details.area_name') }}
                </td>
                <td class="item-detail-text">
                    <span>{{ reportDetails.area.name }}</span>
                </td>
            </tr>

            <tr>
                <td class="item-detail-title">
                    {{ $t('area_report_details.area_type') }}
                </td>
                <td class="item-detail-text">
                    <span>{{ reportDetails.area.type.name }}</span>
                </td>
            </tr>

            <tr>
                <td class="item-detail-title">
                    {{ $t('area_report_details.note') }}
                </td>
                <td
                    v-if="reportDetails.note"
                    class="item-detail-text"
                >
                    {{ reportDetails.note }}
                </td>
                <td
                    v-else
                    class="item-detail-text"
                >
                    -
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('area_report.status') }}
                </td>
                <td class="item-detail-text">
                    {{ reportDetails.status === 1 ? $t('area_report.status_open') : $t('area_report.completed') }}
                </td>
            </tr>
            </tbody>
        </table>

        <!--Inspection results-->
        <span class="span-header">{{ $t('area_report_details.equipment_inspection_results') }}</span>

        <b-table
            show-empty
            small
            :items="reportDetails.equipment_inspection_results"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
        >
            <template v-slot:cell(result)="row">
                  <span
                      :class="{ 'text-success': row.value === resultOk, 'text-warning': row.value === resultDefect, 'text-danger': row.value === resultBan }">
                    {{ getEquipmentResult(row.value) }}
                  </span>
            </template>
            <template v-slot:cell(photos)="row">
                <div class="text-right" style="height: 15em">
                        <ImageView
                            v-for="photo in row.value" :key="photo.id"
                            ref="image"
                            :contain="true"
                            class="flicking-panel"
                            :url="getReportPhotoUrl(row.item.id, photo)"
                            @click="openImage(row.item.id, photo)"
                            :controlSize="'sm'"
                        />
                </div>
            </template>
        </b-table>
        <transition name="fade">
            <fullscreen-image
                v-if="showPhoto"
                :url="getReportPhotoUrl(this.itemId, this.photo)"
                @close="closePhoto"/>

        </transition>
        <div class="print-only">
            <span class="span-header row"> {{ $t('area_report_details.signatures') }}</span>
            <div class="signature">
                <div class="signature-line"/>
                <span> {{ $t('area_report_details.inspector') }}</span>
            </div>
            <div class="signature">
                <div class="signature-line"/>
                <span> {{ $t('area_report_details.approver') }}</span>
            </div>
            <span class="item-detail-text">  {{ $t('common.printed') }} {{ $d(new Date()) }}</span>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
        <b-modal
            v-model="deletion"
            size="sm"
            centered
            ok-variant="danger"
            :cancel-title="$t('common.no')"
            :ok-title="$t('common.yes')"
            @ok="deleteReport()"
            @hide="deletion = false"
        >
            <div class="modal-body">
                <span>{{ $t('common.confirm_delete') }}</span>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {areaHelper} from '../../mixins/AreaMixin'
import {areaReportHelper} from '../../mixins/AreaReportMixin'
import {printHelper} from '../../mixins/PrintMixin'
import {Printd} from 'printd'
import {EventBus} from '@/event-bus'
import {restApi} from '../../mixins/RestApiMixin'
import FullscreenImage from "../../view/FullscreenImage";
import {timeUtils} from "@/components/mixins/TimeUtils";
import ImageView from "@/components/view/ImageView";

export default {
    name: 'AreaReportDetails',
    components: {ImageView, FullscreenImage},
    mixins: [areaReportHelper, areaHelper, printHelper, restApi, timeUtils],
    props: {
        reportDetails: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            sortBy: 'equipment.name',
            sortDesc: false,
            completed: 2,
            deletion: false,
            itemId: null,
            photo: null,
            showPhoto: false,
            fields: [
                {key: 'equipment.name', label: this.$t('area_report_details.inspected_equipment'), sortable: true},
                {
                    key: 'equipment.manufacturer',
                    label: this.$t('area_report_details.equipment_manufacturer'),
                    sortable: false
                },
                {key: 'value', label: this.$t('area_report_details.value'), sortable: false},
                {key: 'info', label: this.$t('area_report_details.info'), class: 'equipment-info', sortable: false},
                {key: 'result', label: this.$t('area_report_details.result'), sortable: true},
                {key: 'photos', label: '', class: 'text-end'}
            ],
            loading: false
        }
    },

    mounted() {
        this.d = new Printd()
    },

    methods: {
        print() {
            if (!this.loading) {
                this.d.print(this.$el, this.printStyle)
            }
        },

        confirmDeleteReport: function () {
            if (!this.loading) {
                this.deletion = true
            }
        },

        deleteReport: function () {
            this.deletion = false
            this.loading = true
            var url = this.areaReportUrl + '/' + this.reportDetails.id
            this.restDelete(url, this.success, this.fail)
        },
        success: function () {
            this.loading = false
            this.$emit('closeAndUpdate')
        },

        fail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
        },
        openImage: function (resultId, photo) {
            this.itemId = resultId
            this.photo = photo
            this.showPhoto = true
        },
        closePhoto() {
            this.showPhoto = false
        }
    }
}
</script>
