<template>
    <base-modal
        v-if="value !== null"
        @cancel="$emit('cancel')"
    >
        <b-form class="col-sm-12 editor-form"
                v-if="recordUpdate">
            <div>
                <h4 class="pl-3">{{ $t('material_station.scale') }}</h4>
                <span class="pl-3 pb-2">{{ recordUpdate.scale.name + ' (' + recordUpdate.scale_id + ')' }}</span>
                <b-row class="nopads">
                    <div class="col-sm-5">
                        <span class="span-title">{{ $t('delivery_note.contract') }}</span>
                        <b-form-select
                            v-model="contract"
                            size="sm"
                            @change="selectContract"
                            :disabled="!!loading"
                        >
                            <option :value="null">
                                {{ $t('delivery_note.form.select_contract') }}
                            </option>
                            <option
                                v-for="contract in contracts"
                                :key="contract.id"
                                :value="contract.id"
                            >
                                {{ contract.name }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-5">
                        <contract-order-filter
                            v-model="order"
                            :contract-id="contract"
                        />
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-5">
                        <span class="span-title">{{ $t('delivery_note.vehicle') }}</span>
                        <b-form-input
                            v-model="recordUpdate.vehicle_license_plate"
                            placeholder="ABC-123"
                            size="sm"
                            :disabled="!recordUpdate || !!loading"
                        />
                    </div>
                    <div class="col-sm-5">
                        <span class="span-title">{{ $t('delivery_note.material') }}</span>
                        <b-form-select
                            v-model="recordUpdate.material_id"
                            size="sm"
                            :disabled="!!loading"
                        >
                            <option :value="null">
                                {{ $t('delivery_note.form.select_material') }}
                            </option>
                            <option
                                v-for="material in recordUpdate.scale.station.station_materials"
                                :key="material.id"
                                :value="material.id"
                            >
                                {{ material.material.name + ' (' + material.type_test_code + ')' }}
                            </option>
                        </b-form-select>
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-5">
                        <span class="span-title">{{ $t('material_station.truck_net_weight') }}</span>
                        <b-form-input
                            v-model="recordUpdate.truck_net_weight"
                            type="number"
                            step="0.01"
                            size="sm"
                            :disabled=" !!loading"
                        />
                    </div>
                    <div class="col-sm-5">
                        <span class="span-title">{{ $t('material_station.trainer_net_weight') }}</span>
                        <b-form-input
                            v-model="recordUpdate.trailer_net_weight"
                            type="number"
                            step="0.01"
                            size="sm"
                            :disabled=" !!loading"
                        />
                    </div>
                </b-row>
            </div>
            <div class="button-container pt-3 mb-0">
                <b-button
                    variant="danger"
                    :disabled="!!loading"
                    class="form-button"
                    @click.stop="$emit('cancel')"
                >
                    <span>{{ $t('common.cancel') }}</span>
                </b-button>
                <b-button
                    variant="success"
                    :disabled="!!loading || !canSubmit"
                    class="form-button"
                    @click.stop="submitRecord"
                >
                    <span>{{ $t('common.save') }}</span>
                </b-button>
            </div>
        </b-form>
        <div
            v-if="!!loading"
            class="spinner"
        />
    </base-modal>
</template>

<script>
import BaseModal from "@/components/BaseModal";
import {EventBus} from "@/event-bus";
import {restApi} from "@/components/mixins/RestApiMixin";
import {timeUtils} from "@/components/mixins/TimeUtils";
import {orderMixin} from "@/components/mixins/OrderMixin";
import ContractOrderFilter from "../../order/ContractOrderFilter";

export default {
    name: "ExternalScaleRecordModal",
    mixins: [restApi, timeUtils, orderMixin],
    components: {ContractOrderFilter, BaseModal},
    props: {
        value: {
            type: Object
        }
    },
    computed: {
        canSubmit() {
            return this.recordUpdate && this.recordUpdate.vehicle_license_plate && this.recordUpdate.order_id && this.recordUpdate.material_id
        }
    },
    data() {
        return {
            loading: 0,
            recordUpdate: null,
            contracts: [],
            contract: null,
            order: null
        }
    },
    watch: {
        order: function () {
            this.recordUpdate.order_id = this.order ? this.order.id : -1
        }
    },
    created() {
        this.fetchValidatedRecord()
    },
    methods: {
        fetchValidatedRecord() {
            this.loading++
            this.restFetch(this.scaleRecordUrl + '/' + this.value.id, response => {
                this.recordUpdate = response.data
                this.order = this.recordUpdate.order
                this.loading--
                this.fetchContracts()
            }, () => {
                EventBus.$emit('show-alert', this.$t('material_station.error_editing_record'))
                this.$emit('cancel')
            })
        },
        fetchContracts: function () {
            let params = {}
            params.contractor = this.recordUpdate.scale.station.company.id
            params.startDate = this.getStartOfTheDay(new Date())
            params.endDate = this.getEndOfTheDay(new Date())
            this.loading++
            this.restFetchParams(this.contractOpenUrl, params, response => {
                this.loading--
                this.contracts = response.data
            }, () => {
                this.loading--
            })
        },
        selectContract: function () {
            this.order = null
        },
        submitRecord: function () {
            this.loading++
            let record = {}
            record.id = this.recordUpdate.id
            record.scale_id = this.recordUpdate.scale_id
            record.order_id = this.recordUpdate.order_id
            record.vehicle_license_plate = this.recordUpdate.vehicle_license_plate
            record.material_id = this.recordUpdate.material_id
            record.trailer_net_weight = this.recordUpdate.trailer_net_weight
            record.truck_net_weight = this.recordUpdate.truck_net_weight
            this.restUpdate(this.scaleRecordUrl, record, this.submitRecordSuccess, this.submitRecordFail)
        },
        submitRecordSuccess: function () {
            this.$emit('closeAndUpdate')
            this.loading--
        },
        submitRecordFail: function () {
            this.loading--
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
        }
    }
}
</script>
