<template>
    <b-form-group
        class="nopads"
        label-for="status"
    >
        <span v-if="showTitle" class="span-title">{{ $t('observations.order') }}</span>
        <multiselect
            v-model="selectedOrders"
            :options="orderOptions"
            :multiple="!singleMode"
            :close-on-select="singleMode"
            label="text"
            track-by="value"
            :searchable="true"
            :internal-search="true"
            :selected-label="$t('common.selected')"
            :select-label="$t('common.select')"
            :deselect-label="$t('common.remove_selection')"
            :placeholder="$t('orders.select_status')"
            :disabled="loading > 0 || !contractIds"
        >
            <span slot="noResult">{{ $t('common.not_found') }}</span>
            <span slot="noOptions">{{ $t('common.no_results') }}</span>
        </multiselect>
    </b-form-group>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {orderMixin} from "../mixins/OrderMixin"

export default {
    name: 'ContractOrderMultiselect',
    mixins: [restApi, orderMixin],
    props: {
        preSelectedOrder: {
            type: Number,
            default: function () {
                return null
            }
        },
        contractIds: {
            type: Array,
            default: () => [],
        },
        openOnly: {
            type: Boolean,
            default: false
        },
        singleMode: {
            type: Boolean,
            default: false
        },
        showTitle: {
            type: Boolean,
            default: true
        }
    },

    data: function () {
        return {
            loading: 0,
            results: [],
            orderOptions: [],
            selectedOrders: [],
        }
    },

    mounted() {
        this.fetchOrders()
    },

    watch: {
        contractIds: function () {
            this.fetchOrders()
        },
        selectedOrders: function () {
            this.handleInput()
        }
    },

    methods: {
        handleInput: function () {
            if (this.singleMode) {
                this.$emit('ordersSelected', this.selectedOrders ? this.results.find(order => order.id === this.selectedOrders.value) : null)
            } else {
                let selected = []
                this.selectedOrders.forEach(item => {
                    const order = this.results.find(o => o.id === item.value)
                    if (order) {
                        selected.push(order)
                    }
                })
                this.$emit('ordersSelected', selected)
            }
        },

        fetchOrders: function () {
            this.results = []
            this.selectedOrders = []
            if (this.contractIds && this.contractIds.length > 0) {
                this.$emit('ordersSelected', this.selectedOrders)
                if (this.contractIds && this.contractIds.length > 0) {
                    this.loading++
                    let params = {
                        contract_ids: this.contractIds
                    }
                    if (this.openOnly) {
                        params.open = 1
                    }
                    this.restFetchParams(this.orderUrl, params, this.handleOrders)
                }
            }
        },

        handleOrders: function (response) {
            this.loading--
            if (response && response.data) {
                this.results = response.data
                response.data.forEach(order => this.orderOptions.push(
                    {
                        value: order.id,
                        text: order.id + ' - ' + this.getOrderStringWithInfo(order)
                    }
                ))
            }
        },

        reset: function () {
            this.results = []
            this.orderOptions = []
            this.selectedOrders = []
        },

        getOrderInfo(order) {
            if (order.info) {
                return order.info.length > 40 ? (order.info.substr(0,35) + '...') : order.info
            }
            return ''
        },

        getOrderString(order) {
            let result = this.showExecutorText ? this.getOrderAndExecutorStr(order) : this.getOrderStr(order)
            result += (this.showInfo && order.info) ? ' - ' + this.getOrderInfo(order) : ''
            return result
        }

    }
}
</script>
<style scoped>
</style>
