<template>
    <div class="col-sm-12 nopads"
         v-bind:class="{ dynamic_width: dynamic}">
        <b-form
            class="col-sm-12 editor-form nopads"
            v-bind:class="{full_width: noMargin }">
            <div class="col-sm-12 form-title editor-title">
                <h2
                    v-if="assignmentUpdate.id > 0"
                    class="form-title editor-title"
                >
                    {{ $t('work_assignment.edit') }}
                </h2>
                <h2
                    v-else
                    class="form-title editor-title"
                >
                    {{ $t('work_assignment.add') }}
                </h2>
            </div>
            <draw-geometry-wizard
                v-if="showDrawGeometryWizard !== false"
                :savedGeometry="assignmentUpdate.geometry"
                :type="OTHER"
                @close="closeDrawGeometryWizard"
                @addDrawnGeometry="addGeometryToAssignment"
            />
            <div class="col-sm-12 form-data">
                <!-- Observation data -->
                <work-assignment-observation
                    v-if="observation"
                    :observation="observation"/>
                <!-- Area defect data -->
                <work-assignment-area-defect
                    v-if="assignmentUpdate.area_defect"
                    :defect="assignmentUpdate.area_defect"/>
                <!-- Vehicle defect data -->
                <work-assignment-vehicle-defect
                    v-if="vehicleDefect"
                    :defect="vehicleDefect"/>
                <!-- WorkPermission data -->
                <work-assignment-work-permission
                    v-if="workPermission"
                    :work-permission="workPermission"/>
                <hr v-if="workPermission || vehicleDefect || assignmentUpdate.area_defect || observation"/>
                <h4 class="form-header">{{ $t('work_assignment.basic_info')}}</h4>
                <div v-if="assignmentUpdate && assignmentUpdate.id > 0" class="col-12 nopads">
                    <b-form-group
                        class="title nopads"
                        label-for="contract"
                    >
                        <span class="span-title">{{ $t('work_assignment.id') }}</span>
                        {{ assignmentUpdate.id }}
                    </b-form-group>
                </div>
                <!-- Show open contracts  -->
                <div class="col-12 nopads">
                    <b-form-group
                        class="title nopads"
                        label-for="contract"
                    >
                        <span class="span-title">{{ $t('orders.contract') }}</span>
                        <b-form-select
                            id="contract"
                            v-model="assignmentUpdate.contract"
                            size="sm"
                            class="sm-3"
                        >
                            <template slot="first">
                                <option :value="null">
                                    {{ $t('orders.select_contract') }}
                                </option>
                            </template>
                            <option
                                v-for="option in contracts"
                                :key="option.id"
                                :value="option"
                            >
                                {{ option.name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>

                <div class="col-12 nopads">
                    <b-form-group
                        class="title nopads"
                        label-for="contract"
                    >
                        <contract-order-filter
                            v-model="assignmentUpdate.work_order"
                            :pre-selected-order="assignmentUpdate.work_order ? assignmentUpdate.work_order.id : null"
                            :contract-id="assignmentUpdate.contract ? assignmentUpdate.contract.id : null"
                            :open-or-planned-only="true"
                        />
                    </b-form-group>
                </div>
                <b-row class="nopads">
                    <div class="col-sm-6 nopads pr-1">
                        <span class="span-title">
                          {{ $t('orders.planned_start') }}
                        </span>
                        <b-form-input
                            id="start"
                            type="date"
                            :value="timestampToDateInput(assignmentUpdate.planned_start)"
                            size="sm"
                            @change="updateStartDate"
                        />
                    </div>
                    <div class="col-sm-6 nopads pr-1">
                        <span class="span-title">
                          {{ $t('orders.planned_ready') }}
                        </span>
                        <b-form-input
                            id="end"
                            type="date"
                            :value="timestampToDateInput(assignmentUpdate.planned_ready)"
                            size="sm"
                            @change="updateEndDate"
                        />
                    </div>
                </b-row>


                <b-row class="nopads" v-if="!assignmentUpdate.area_defect && !assignmentUpdate.geometry">
                    <div class="col-lg-6 nopads pr-1">
                        <span class="span-title">{{ $t('work_assignment.area_equipment') }}</span>
                        <area-equipment-selector
                            v-model="assignmentUpdate.area_equipment"
                            :area-id="assignmentUpdate.area_equipment && assignmentUpdate.area_equipment.area ? assignmentUpdate.area_equipment.area.id : undefined"
                        />
                    </div>
                </b-row>

                <b-row class="nopads">
                    <div class="col-sm-6 nopads pr-1">
                        <span class="span-title">{{ $t('work_assignment.street') }}</span>
                        <b-form-input
                            type="text"
                            id="street"
                            size="sm"
                            v-model="assignmentUpdate.street_address"
                        />
                    </div>
                    <div class="col-sm-6 nopads pr-1">
                        <span class="span-title">{{ $t('work_assignment.city') }}</span>
                        <b-form-input
                            type="text"
                            id="city"
                            size="sm"
                            v-model="assignmentUpdate.city"
                        />
                    </div>
                </b-row>

                <div class="pr-1">
                    <span class="span-title">{{ $t('work_assignment.select_status') }}</span>
                    <b-form-select size="sm"
                                   id="status"
                                   v-model="assignmentUpdate.status"
                                   :disabled="assignmentUpdate.id < 1">
                        <option v-bind:key="option.key"
                                v-for="option in getStatus(assignmentUpdate.id<1)"
                                :value="option.status">
                            {{ option.key }}
                        </option>
                    </b-form-select>
                </div>
                <div class="pr-1">
                    <span class="span-title">{{ $t('work_assignment.select_priority') }}</span>
                    <b-form-select size="sm"
                                   id="priority"
                                   v-model="assignmentUpdate.priority_value">
                        <option v-bind:key="option.key"
                                v-for="option in getPriority(assignmentUpdate.id<1)"
                                :value="option.priorityValue">
                            {{ option.key }}
                        </option>
                    </b-form-select>
                    <b-form-group
                        :label="$t('users.email')"
                        class="span-title"
                        label-for="email"
                    >
                        <b-form-input
                            type="text"
                            id="email"
                            size="sm"
                            v-model="assignmentUpdate.email"
                        />
                    </b-form-group>
                </div>

                <hr/>
                <div v-if="assignmentUpdate.contract && assignmentUpdate.contract.harja_contract_id" class="nopads">
                    <b-row class="nopads pb-2">
                        <h4 class="form-header">{{ $t('orders.external_section') }}</h4>
                    </b-row>
                    <b-row class="nopads pb-4">
                        <div class="col-sm-10 col-md-8 col-lg-5">
                            <harja-task-picker :contractId="assignmentUpdate.contract.id"
                                :assignedTasks="assignmentUpdate.external_tasks" />
                        </div>
                    </b-row>
                </div>

                <hr/>
                <h4 class="form-header">{{ $t('work_assignment.responsibility')}}</h4>
                <span class="tip-light">{{ $t('work_assignment.responsibility_tip')}}</span>
                <div v-if="canAssign">
                    <div v-if="canAssign()">
                        <span class="span-title">{{ $t('work_assignment.select_assignee_company') }}</span>
                        <b-form-select size="sm"
                                       id="assigned_company"
                                       v-model="assignmentUpdate.assigned_company.id">
                            <template slot="first">
                                <option :value="null">
                                    {{ $t('work_assignment.select_assignee_company') }}
                                </option>
                            </template>
                            <option v-bind:key="contractor.id" v-for="contractor in contractors" :value="contractor.id">{{ contractor.name }}</option>
                        </b-form-select>
                    </div>
                    <div v-if="canAssign()">
                        <span class="span-title">{{ $t('work_assignment.select_assignee') }}</span>
                        <span class="hint-text mb-4" v-if="!assigneeInsideOrganization && assignmentUpdate.assigned_to">{{ $t('work_assignment.outside_org_assignee') }} ({{assignmentUpdate.assigned_to.first_name }} {{assignmentUpdate.assigned_to.last_name }})</span>
                        <b-form-select size="sm"
                                       id="assigned_person"
                                       v-model="assignee">
                            <template slot="first">
                                <option :value="null">
                                    {{ $t('work_assignment.select_assignee') }}
                                </option>
                            </template>
                            <option v-bind:key="user.id" v-for="user in users" :value="user.id">{{ user.last_name }}, {{
                                    user.first_name
                                }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="pr-1 mt-1">
                        <label>{{ $t('work_assignment.work_progress') }}</label>
                        <label v-if="assignmentUpdate.progress_value!==undefined">
                            {{ ": " + assignmentUpdate.progress_value + " %" }}</label>
                        <b-form-input v-model="assignmentUpdate.progress_value"
                                      :disabled="assignmentUpdate.id < 0"
                                      id="progress"
                                      type="range"
                                      min="0"
                                      max="100">
                        </b-form-input>
                    </div>
                    <div class="pr-1 mt-1">
                        <b-form-group
                            :label="$t('work_assignment.work_time') + ' ' + '(h)'"
                            class="span-title"
                            label-for="worktime"
                        >
                            <b-form-input
                                type="number"
                                step="0.01"
                                id="worktime"
                                size="sm"
                                v-model="assignmentUpdate.work_time"
                            />
                        </b-form-group>
                    </div>
                </div>

                <!-- Workassignment geometry -->
                <div class="pt-3"
                     style="width:inherit;"
                >
                    <hr/>
                    <h4 class="form-header">{{ $t('work_assignment.geometry')}}</h4>
                    <span class="tip-light">{{ $t('work_assignment.geometry_hint')}}</span>
                    <b-row>
                        <b-button
                            id="removeGeomButton"
                            v-if="userDefinedGeometry"
                            variant="danger"
                            class="form-button"
                            :disabled="loading > 0"
                            @click.stop="removeDrawnGeometry"
                        >
                            {{ $t('orders.remove_geometry') }}
                        </b-button>
                        <b-button
                            id="updateGeomButton"
                            v-if="assignmentUpdate.geometry"
                            variant="primary"
                            class="form-button"
                            :disabled="assignmentUpdate.position != null || assignmentUpdate.area_defect != null || assignmentUpdate.area_equipment != null || assignmentUpdate.observation != null"
                            @click.stop="showDrawGeometryWizard = !showDrawGeometryWizard"
                        >
                            {{ $t('map.redefine_geometry') }}
                        </b-button>
                        <b-button
                            id="addGeomButton"
                            v-if="!assignmentUpdate.geometry"
                            variant="primary"
                            class="form-button"
                            :disabled="assignmentUpdate.position != null || assignmentUpdate.area_defect != null || assignmentUpdate.area_equipment != null || assignmentUpdate.observation != null"
                            @click.stop="showDrawGeometryWizard = !showDrawGeometryWizard"
                        >
                            {{ $t('map.define_geometry') }}
                        </b-button>

                    </b-row>


                </div>

                <!-- Attachments-->
                <hr/>
                <h4 class="form-header">{{ $t('orders.attachments_and_info')}}</h4>
                <!-- Attachment upload-->
                <div>
                    <span class="span-title">{{ $t('work_assignment.add_attachment') }}</span>
                    <div
                        v-for="(path, counter) in attachments"
                        :key="counter"
                        class="col-sm-12 nopads"
                    >
                        <b-form-group
                            class="title"
                            label-for="counter"
                            v-if="counter === 0 || isAttachmentSet(attachments, counter-1)"
                        >
                            <b-form-file
                                :id="'attachment'+counter"
                                v-model="attachments[counter]"
                                :accept="getValidAttachmentTypes()"
                                :placeholder="$t('common.attachment_placeholder')"
                            />
                        </b-form-group>
                    </div>
                </div>

                <!-- Workassignment copy confirmation -->
                <work-assignment-copy-modal
                    v-if="showCopyModal"
                    :assignment-update="assignmentUpdate"
                    @cancel="cancelCopying"
                    @updateSuccess="copySuccess"
                />

                <b-form-group
                    :label="$t('orders.info')"
                    class="span-title"
                    label-for="info"
                >
                    <b-form-textarea
                        id="info"
                        v-model="assignmentUpdate.info"
                        :rows="3"
                        :max-rows="6"
                    />
                </b-form-group>

                <div v-if="assignmentUpdate.attachments && assignmentUpdate.attachments.length">
                    <span class="span-title">{{ $t('common.attachments') }}</span>
                    <ol>
                        <li class="pointer"
                            v-for="item in assignmentUpdate.attachments"
                            :key="item.id">
                            <span v-on:click="downloadAttachment(item)">{{ item.file_original_name }}</span>
                        </li>
                    </ol>
                </div>


                <div class="col-sm-12 button-container">
                    <b-button
                        id="addButton"
                        variant="primary"
                        :disabled="!canCreateObservation || loading > 0 || assignmentUpdate.observation !== null ||
                            assignmentUpdate.geometry === null"
                        @click.stop="addObservation"
                    >
                        {{ $t('observations.add_observation') }}
                    </b-button>

                    <b-button
                        class="ml-1"
                        id="addButton"
                        variant="info"
                        @click.stop="copyWorkAssignment"
                    >
                        {{ $t('common.copy') }}
                    </b-button>

                    <b-button
                        id="closeButton"
                        variant="danger"
                        class="form-button"
                        @click.stop="$emit('close')"
                    >
                          {{ $t('common.cancel') }}
                    </b-button>
                    <b-button
                        id="saveButton"
                        variant="success"
                        class="form-button"
                        :disabled="loading  > 0"
                        @click.stop="preSubmit"
                    >
                          {{ $t('common.save') }}
                    </b-button>
                </div>
            </div>
        </b-form>

        <transition v-if="observationWizard" name="fade">
            <observation-wizard
                :coordinates="observationCoordinates"
                :skip-photos="true"
                @close="observationWizard = false"
                @closeAndUpdate="observationAdded"
            />
        </transition>


        <transition v-if="editObservation" name="fade">
            <observation-editor
                :observation="editableObservation"
                :is-admin="isAdmin"
                :is-observer="isObserver"
                :old-work-assignment="assignmentUpdate"
                id="editor"
                :allow-assign="false"
                :user="user"
                @scrollTop="scrollTop"
                @close="closeForm"
                @closeAndUpdate="closeAndUpdateObservations"
            />
        </transition>

        <div
            v-if="loading  > 0"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../mixins/RestApiMixin'
import {geomTypes} from '../mixins/ObservationMixin'
import {workAssignmentHelper} from '../mixins/WorkAssignmentMixin'
import WorkAssignmentObservation from './WorkAssignmentObservation'
import WorkAssignmentAreaDefect from './WorkAssignmentAreaDefect'
import WorkAssignmentVehicleDefect from './WorkAssignmentVehicleDefect'
import WorkAssignmentWorkPermission from './WorkAssignmentWorkPermission'
import {timeUtils} from '../mixins/TimeUtils'
import {attachmentHelper} from "@/components/mixins/AttachmentMixin";
import {hereService} from "@/components/mixins/HereServiceMixin";
import ContractOrderFilter from "../order/ContractOrderFilter";
import AreaEquipmentSelector from "../area/areaequipment/AreaEquipmentSelector";
import DrawGeometryWizard from "../shapeimport/DrawGeometryWizard";
import WorkAssignmentCopyModal from "./WorkAssignmentCopyModal.vue";
import ObservationWizard from "@/components/observation/ObservationWizard";
import HarjaTaskPicker from "@/components/harja/HarjaTaskPicker";
import {mapHelper} from "@/components/mixins/MapMixin";

export default {
    name: 'WorkAssignmentEditor',
    components: {
        ObservationWizard,
        DrawGeometryWizard,
        AreaEquipmentSelector,
        ContractOrderFilter,
        ObservationEditor: () => import('../observation/ObservationEditor.vue'),
        WorkAssignmentVehicleDefect,
        WorkAssignmentAreaDefect,
        WorkAssignmentObservation,
        WorkAssignmentWorkPermission,
        WorkAssignmentCopyModal,
        HarjaTaskPicker
    },
    mixins: [workAssignmentHelper, restApi, timeUtils, attachmentHelper, hereService, geomTypes, mapHelper],
    props: {
        workAssignment: {
            type: Object,
            default: null
        },
        coord: {
            type: Array,
            default() {
                return []
            }
        },
        noMargin: {
            type: Boolean,
            default: false
        },
        user: {
            type: Object,
            default: null
        },
        viaObservation: {
            type: Boolean,
            default: false
        },
        observation: null,
        areaDefect: null,
        areaEquipment: null,
        vehicleDefect: null,
        workPermission: null,
        isAdmin: Boolean,
        isObserver: Boolean,
        dynamic: Boolean
    },
    data: function () {
        return {
            loading: 0,
            company: null,
            contracts: [],
            assignmentUpdate: this.newWorkAssignment(this.workAssignment),
            editableObservation: null,
            users: [],
            contractors: [],
            observationWizard: false,
            editObservation: false,
            attachments: new Array(5),
            addressHasChanged: false,
            showDrawGeometryWizard: false,
            userDefinedGeometry: false,
            showCopyModal: false,
            assignee: -1
        }
    },
    mounted: function () {
        if (!this.assignmentUpdate.area_equipment && this.areaEquipment) {
            this.assignmentUpdate.area_equipment = this.areaEquipment;
            if (!this.assignmentUpdate.area_equipment.area && this.areaEquipment.area) {
                this.assignmentUpdate.area_equipment.area = this.areaEquipment.area.id
            }
        }
        if (this.areaDefect) {
            this.assignmentUpdate.area_defect = this.areaDefect
        }
        if (this.observation) {
            this.assignmentUpdate.observation = this.observationDetailsUrl
            if(this.observation.contract && this.observation.contract.id &&
                this.assignmentUpdate.contract &&
                this.assignmentUpdate.contract.id === null) {
                this.assignmentUpdate.contract = this.observation.contract
            }
            if(this.observation.work_order && this.observation.work_order.id &&
                (!this.assignmentUpdate.work_order || this.assignmentUpdate.work_order.id === null)) {
                this.assignmentUpdate.work_order = this.observation.work_order
            }
        }
        if (this.isAdmin || this.isObserver) {
            this.fetchUsers()
            this.fetchOpenContracts()
            this.fetchContractors()
        }
        if(this.coord && this.coord.length > 0) {
            let geometry = {'point': {'lat': this.coord[0].lat, 'lon': this.coord[0].lng}}
            this.addGeometryToAssignment(geometry)
        }
        if(this.assignmentUpdate.geometry){
            this.userDefinedGeometry = true
        }else{
            this.userDefinedGeometry = false
        }
        if(this.observation) {
            this.editableObservation = this.observation
        } else if(this.assignmentUpdate.observation){
            this.editableObservation = this.assignmentUpdate.observation
        }
    },
    watch: {
        contracts: function () {
            if (this.assignmentUpdate.contract) {
                let selected = this.contracts.find(item => item.id === this.assignmentUpdate.contract.id)
                if (selected) {
                    this.assignmentUpdate.contract = selected
                } else {
                    this.contracts.push(this.assignmentUpdate.contract)
                }
            }
        },
        'assignmentUpdate.status': {
            handler: function (value) {
                if (value === this.OPEN) {
                    this.assignee = null
                }
            },
            deep: true
        },
        assignee:  function (value) {
            if (value && this.assignmentUpdate.status === this.OPEN) {
                this.assignmentUpdate.assigned_to = this.users.find(user => user.id === value)
                this.assignmentUpdate.status = this.ASSIGNED
            } else if (value === null) {
                this.assignmentUpdate.assigned_to = null
            }
        },
        'assignmentUpdate.contract': {
            handler: function (value) {
                if (value && this.assignmentUpdate.id < 1) {
                    this.assignmentUpdate.status = 1
                }
                if (!value) {
                    // Contract reset -> reset also order
                    this.assignmentUpdate.work_order = null
                }
            },
            deep: true
        },
        'assignmentUpdate.street_address': {
            handler: function (value) {
                if (!this.addressHasChanged && value && this.assignmentUpdate.street_address === value) {
                    this.addressHasChanged = true
                }
            },
        },
        'assignmentUpdate.city': {
            handler: function (value) {
                if (!this.addressHasChanged && value && this.assignmentUpdate.city === value) {
                    this.addressHasChanged = true
                }
            },
        }
    },
    computed: {
        canCreateObservation() {
            return this.assignmentUpdate.geometry && (this.assignmentUpdate.geometry.line_string || this.assignmentUpdate.geometry.point)
        },

        assigneeInsideOrganization() {
            return this.assignmentUpdate.assigned_to && this.users.find(user => user.id === this.assignmentUpdate.assigned_to.id)
        },

        observationCoordinates() {
            if (this.assignmentUpdate.geometry) {
                if (this.assignmentUpdate.geometry.line_string) {
                    let results = []
                    this.assignmentUpdate.geometry.line_string.points.forEach(point => results.push( {lat: point[1], lng: point[0]}))
                    return results
                }
                if (this.assignmentUpdate.geometry.point) {
                    return [
                        {
                            lat: this.assignmentUpdate.geometry.point[1],
                            lng: this.assignmentUpdate.geometry.point[0]
                        },
                    ]
                }
            }
            return null
        }
    },
    methods: {
        getCompany() {
            if (this.isAdmin) {
                if (this.areaDefect) {
                    return this.areaDefect.area.company.id
                } else if (this.vehicleDefect) {
                    return this.vehicleDefect.vehicle.company.id
                } else if (this.observation) {
                    if (this.observation.contract) {
                        return this.observation.contract.contractor.id;
                    } else if (this.observation.work_order) {
                        return this.observation.work_order.contract_task_type.contract.contractor.id;
                    } else if (this.workAssignment && this.workAssignment.contract && this.workAssignment.contract.contractor) {
                        return this.workAssignment.contract.contractor.id;
                    } else if (this.observation.reporter) {
                        return this.observation.reporter.company.id
                    } else if (this.workPermission) {
                        return this.workPermission.company.id
                    }
                }
            }
        },
        removeDrawnGeometry(){
            this.assignmentUpdate.geometry = null
            this.userDefinedGeometry = false
        },
        addGeometryToAssignment(geometry) {
            this.assignmentUpdate.geometry = geometry
            this.closeDrawGeometryWizard()
            this.userDefinedGeometry = true
        },
        closeDrawGeometryWizard() {
            this.showDrawGeometryWizard = false
        },
        closeForm: function () {
            this.editObservation = false
        },
        copyWorkAssignment() {
            this.showCopyModal = true
        },
        copySuccess: function (data) {
            this.assignmentUpdate = this.newWorkAssignment(data)
            this.showCopyModal = false
            this.$emit('updateWorkAssignments')
            this.loading = false
            EventBus.$emit('show-alert', this.$t('work_assignment.copy_successful'), 'success')
        },
        cancelCopying() {
            this.showCopyModal = false
        },

        observationAdded(observation) {
            observation.can_edit = true
            observation.info = this.assignmentUpdate.info
            observation.contract = this.assignmentUpdate.contract
            observation.work_order = this.assignmentUpdate.work_order
            this.editableObservation = observation
            this.editObservation = true
        },

        addObservation() {
            if(!this.viaObservation) {
                this.observationWizard = true
            }
        },
        closeAndUpdateObservations() {
            this.editObservation = false
            EventBus.$emit('show-alert', this.$t('observations.observation_added'), 'success')
        },
        fetchUsers: function () {
            this.loading++
            this.users = []
            this.company = this.getCompany();
            this.restFetchParams(this.userCompanyUrl, {company: this.company}, this.handleResponse)
        },
        handleResponse: function (response) {
            if(response && response.data) {
                this.users = response.data
            }
            if (this.assigneeInsideOrganization) {
                this.assignee = this.assignmentUpdate.assigned_to.id
            }
            this.loading--
        },
        fetchContractors: function () {
            this.loading++
            this.contractors = []
            this.restFetch(this.contractorUrl, this.handleContractorsResponse)
        },
        handleContractorsResponse: function (response) {
            this.contractors = response.data
            this.handleAssignedCompany()
            this.loading--
        },
        getAddressCoordinates: function (address) {
            this.addressToCoordinates(address, this.handleAddressCoordinatesSuccess)
        },
        equipmentSelected: function (equipmentId) {
            this.assignmentUpdate.area_equipment = {id: equipmentId}
        },
        handleAddressCoordinatesSuccess: function (response) {
            this.assignmentUpdate.position = response
            this.submit();
        },
        handleAssignedCompany() {
            if(this.assignmentUpdate.assigned_company && this.assignmentUpdate.assigned_company.id) {
                if(this.contractors && this.contractors.length > 0) {
                    let foundContractor = this.contractors.some((contractor) => contractor.id === this.assignmentUpdate.assigned_company.id)
                    if(!foundContractor) {
                        this.contractors.push(this.assignmentUpdate.assigned_company)
                    }
                } else {
                    this.contractors.push(this.assignmentUpdate.assigned_company)
                }
            }
        },
        preSubmit: function () {
            if (this.addressHasChanged && this.assignmentUpdate.street_address && this.assignmentUpdate.city) {
                const address = this.assignmentUpdate.street_address + this.assignmentUpdate.city
                this.getAddressCoordinates(address)
            } else {
                this.submit()
            }
        },
        submit: function () {
            let jsonAssignment = this.setDefaultValues(this.assignmentUpdate)
            if (jsonAssignment) {
                this.loading++
                if (jsonAssignment.id < 1) {
                    this.restAdd(this.workAssignmentUrl, jsonAssignment, this.success, this.fail)
                } else {
                    this.restUpdate(this.workAssignmentUrl, jsonAssignment, this.success, this.fail)
                }
            }
        },
        setDefaultValues: function (assignment) {
            assignment = this.jsonToWorkAssignment(assignment)
            if (assignment.area_defect) {
                assignment.area_defect = {id: assignment.area_defect.id}
            } else if (this.vehicleDefect) {
                assignment.vehicle_defect = {id: this.vehicleDefect.id}
            } else if (this.observation) {
                assignment.observation = {id: this.observation.id}
            } else if (assignment.observation && assignment.observation.id) {
                assignment.observation = {id: assignment.observation.id}
            }else if (this.workPermission) {
                assignment.special_work_permission = {id: this.workPermission.id}
            }
            if (assignment.area_equipment && assignment.area_equipment.id) {
                assignment.area_equipment = {id: assignment.area_equipment.id}
            } else {
                assignment.area_equipment = undefined;
            }
            if (assignment.contract && assignment.contract.id) {
                assignment.contract = {id: assignment.contract.id}
            } else {
                assignment.contract = undefined
            }
            if (assignment.work_order && assignment.work_order.id) {
                assignment.work_order = {id: assignment.work_order.id}
            } else {
                assignment.work_order = undefined
            }
            if (!assignment.status) {
                EventBus.$emit('show-alert', this.$t('work_assignment.select_status'))
                return undefined
            }
            if (this.assignee !== -1 && this.assignee) {
                assignment.assigned_to = {id: this.assignee}
            }
            if (this.assignee === null) {
                assignment.assigned_to = null
            }
            if ((assignment.status === this.ASSIGNED || assignment.status === this.IN_PROGRESS) &&
                !assignment.assigned_to) {
                EventBus.$emit('show-alert', this.$t('work_assignment.error_state'))
                return undefined
            }
            if (assignment.assigned_company && assignment.assigned_company.id) {
                assignment.assigned_company = {id: assignment.assigned_company.id}
            } else {
                assignment.assigned_company = undefined
            }
            // Polygon geometry needs special handling if not edited: rings -> points
            if (assignment.geometry && assignment.geometry.polygon && assignment.geometry.polygon.rings) {
                assignment.geometry.polygon = this.convertPolygon(assignment.geometry.polygon)
            }
            return assignment
        },

        convertPolygon: function (polygon) {
            if (polygon.rings && polygon.rings.length > 0) {
                    let original = polygon.rings[0] // No support for many rings for now
                    let mapped = original.map(item => [item[0], item[1]])
                    mapped.push(mapped[0])
                    return {points:  mapped}
            }
            return polygon
        },


        success: function (workAssignment) {
            // Set id for new assignment
            if (!this.assignmentUpdate.id || this.assignmentUpdate.id < 0) {
                this.assignmentUpdate.id = workAssignment.id
            }
            let attachments = this.getValidAttachments(this.attachments)
            if (attachments.length > 0) {
                this.uploadAttachment(attachments)
            } else {
                this.loading--
                this.$emit('closeAndUpdate', this.assignmentUpdate)
            }
        },
        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading--
        },
        canAssign: function () {
            return this.isAdmin || this.isObserver
        },
        uploadAttachment: function (attachments) {
            if (attachments.length > 0) {
                this.loading++
                let formData = new FormData()
                attachments.forEach(file => {
                    if (file !== undefined && file !== null) {
                        formData.append('attachments[]', file)
                    }
                })
                this.restPostWithFile(this.workAssignmentAttachmentUrl + this.assignmentUpdate.id, {}, formData,
                    ()=>{
                        this.loading--
                        this.$emit('closeAndUpdate', this.assignmentUpdate)
                    }, this.fail
                )
            }
        },
        handleContractResults: function (response) {
            this.loading--
            if (response != null) {
                this.contracts = response.data
            }
        },
        downloadAttachment: function (item) {
            this.loading++
            this.restFetch(this.workAssignmentAttachmentUrl + this.assignmentUpdate.id + '/' + item.id,
                response => {
                    this.saveFile(item.file_original_name, response.data)
                    this.loading--
                }, () => {
                    this.loading--
                })
        },
        scrollTop: function () {
            this.$nextTick(function () {
                this.$refs.container.scrollTop = 0
            })
        },
        updateStartDate: function (event) {
            if (event) {
                this.assignmentUpdate.planned_start = new Date(event)
            } else {
                this.assignmentUpdate.planned_start = undefined
            }
        },

        updateEndDate: function (event) {
            if (event) {
                // We need to add 23 h 59 minutes to the date to cover the whole day
                var endDate = new Date(event)
                endDate.setHours(23)
                endDate.setMinutes(59)
                endDate.setSeconds(59)
                this.assignmentUpdate.planned_ready = endDate
            } else {
                this.assignmentUpdate.planned_ready = undefined
            }
        }
    }
}
</script>
