<template>
    <div class="search-form p-2">
        <!-- Material use -->
        <h4 class="ml-4 form-header" style="color: #70a901">{{ $t('spreader_data.report') }}</h4>
        <b-row class="nopads">
            <b-col>
                <table class="table mt-4">
                    <tr v-for="(data, index) in materialsData" v-bind:key="index">
                        <td style="color: #606062; text-transform: uppercase">{{ labels[index] }}</td>
                        <td>{{ data.value ? data.value.toFixed(1) : '' }} kg</td>
                    </tr>
                </table>
            </b-col>
            <b-col>
                <bar-chart
                    class="routa-bars dont-print"
                    ref="barMaterials"
                    v-if="this.results.length"
                    :labels="labels"
                    :colors="colors"
                    :data="materialsData"
                    :title="$t('spreader_data.amount')"
                ></bar-chart>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import BarChart from '../../chart/BarChart'
import {timeUtils} from "../../mixins/TimeUtils";
import {spreaderDataMixin} from "@/components/mixins/SpreaderDataMixin";

export default {
    name: 'spreader-material-charts',
    components: {BarChart},
    mixins: [timeUtils, spreaderDataMixin],
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data: function () {
        return {
            labels: [],
            colors: [],
            materialsData: []
        }
    },
    mounted: function () {
        this.formatData()
    },
    watch: {
        results() {
            this.formatData()
        },
    },
    methods: {
        formatData: function () {
            let materials = []
            let labels = []
            let colors = []
            this.results.forEach(item => {
                // Separate each materials to own column and sum amounts
                const dryMaterialName = this.getMaterialName(item.material_dry)
                const dryAmount = item.total_dry_amount
                const liquidMaterialName = this.getMaterialName(item.material_liquid)
                const liquidAmount = item.total_liquid_amount
                if (dryMaterialName) {
                    const key = 'm_' + item.material_dry
                    if (materials[key]) {
                        materials[key] += dryAmount
                    } else {
                        materials[key] = dryAmount
                        colors[key] = this.getMaterialColor(item.material_dry)
                        labels[key] = dryMaterialName
                    }
                }
                if (liquidMaterialName) {
                    const key = 'm_' + item.material_liquid
                    if (materials[key]) {
                        materials[key] += liquidAmount
                    } else {
                        materials[key] = liquidAmount
                        colors[key] = this.getMaterialColor(item.material_liquid)
                        labels[key] = liquidMaterialName
                    }
                }
            })
            this.materialsData = []
            this.labels = []
            this.colors = []
            for (let key in materials) {
                this.materialsData.push({value: materials[key]})
                this.labels.push(labels[key])
                this.colors.push(colors[key])
            }
        }
    }
}
</script>
<style>
</style>
