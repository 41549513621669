<template>
    <div style="user-select: none">
        <div class="order-text pl-2"  :style="{width: textColWidthPercentage + '%', marginTop: '-2px', marginBottom: '-2px'}">
            <!-- Data Row -->
            <b-row class="col-12 nopads">
                <div class="col-4 nopads single-line">
                    <font-awesome-icon
                        icon="caret-right"
                        class="pointer ml-1"
                        style="outline: none; width: 2.5em; height: 1.1em"
                        :class="{ 'fa-rotate-90': itemsVisible }"
                        @click.stop="toggleItems"/>
                    <span :id="'o_t'+orderObject.id" class="ml-2 pointer" @click.stop="$emit('showOrder', orderObject)">
                        {{ orderObject.id }} - {{ getOrderAndExecutorStr(orderObject) }}
                    </span>
                </div>
                <div class="col-3 nopads single-line text-center">
                    <span :id="'o_t'+orderObject.id" class="ml-2 pointer">
                        {{
                            (order.start_date ? timestampToDateInput(order.start_date) : $t('common.unknown'))
                            + " - " +
                            (order.end_date ? timestampToDateInput(order.end_date) : $t('common.unknown'))
                        }}
                    </span>
                </div>
                <div class="col-2 nopads single-line text-center">
                    <span :id="'o_t'+orderObject.id" class="ml-2 pointer">
                        {{ order.pricing_model ? pricingModelToText(order.pricing_model, null) : "-" }}
                    </span>
                </div>
                <div class="col-1 nopads single-line text-center">
                    <span :id="'o_t'+orderObject.id" class="ml-2 pointer">
                        {{ order.unit_price ? (order.unit_price / 100).toFixed(3) : null }}
                    </span>
                </div>
                <div class="col-2 nopads single-line text-center">
                    <span :id="'o_t'+orderObject.id" class="ml-2 pointer">
                        {{ parseFloat(order.cost).toFixed(2) }}
                    </span>
                </div>
            </b-row>
        </div>
        <b-collapse :id="'accordion-assignments'+this.orderObject.id" v-model="itemsVisible">
            <div class="col-sm-12 pl-3 ml-2">
                <contract-trip-summary-list
                    :summaries="summaries"
                    :invoice-state-options="invoiceStateOptions"
                    :user="user"
                    :invoicing-fields-default="true"
                    :hideExcelButton="true"
                    :hideDetailButton="true"
                    @updateRequest="showInvoiceSummary"
                    @rowSelected="emitTripDetails"
                    @deliveryTonsClicked="emitTripDeliveries"
                    @invoiceHistoryClicked="emitInvoiceHistories"
                    @contractClicked="emitContractDetails"
                    @orderClicked="emitTripOrder"
                />
            </div>
        </b-collapse>
    </div>
</template>


<script>
import {timeUtils} from "@/components/mixins/TimeUtils";
import {restApi} from "@/components/mixins/RestApiMixin";
import {orderMixin} from "@/components/mixins/OrderMixin";
import {priceHelper} from '@/components/mixins/PricingMixin'
import ContractTripSummaryList from "@/components/tripsummary/ContractTripSummaryList.vue";
import {EventBus} from "@/event-bus";
export default {
    name: 'InvoicingOrderItem',
    components: {
        ContractTripSummaryList
    },
    mixins: [timeUtils, restApi, orderMixin, priceHelper],
    data: function () {
        return {
            itemsVisible: false,
            loading: 0,
            orderObject: null,
            summaries: [],
            searchParams: null
        }
    },
    props: {
        order: {
            type: Object,
            default: null
        },
        textColWidthPercentage: {
            type: Number,
            default: 40
        },
        invoiceStateOptions: {
            type: Array,
            default() {
                return []
            }
        },
        user: {
            type: Object,
            default: null
        },
        showAll: {
            type: Boolean,
            default: false
        },
    },
    watch: {
        order: function () {
            this.orderObject = this.order.order
        },
        itemsVisible() {
            if(this.itemsVisible) {
                this.showInvoiceSummary(this.order)
            }
        },
    },
    created: function () {
        this.orderObject = this.order.order
        if(this.showAll) {
            this.toggleItems()
        }
    },
    methods: {
        toggleItems() {
            this.itemsVisible = !this.itemsVisible
        },
        orderUpdated() {
            this.$emit('orderUpdated')
        },
        fetchInvoiceRows() {
            this.loading = true
            this.restFetchParams(this.contractDailyTripSummaryUrl, this.searchParams, this.handleInvoiceRowsResponse, this.invoiceRowsFail)
        },
        handleInvoiceRowsResponse: function (response) {
            this.loading = false
            this.summaries = response.data
            if(this.summaries && this.summaries.length === 0) {
                EventBus.$emit('show-alert', this.$t('common.error'))
            }
        },
        invoiceRowsFail: function () {
            this.loading = false
            this.summaries = []
            EventBus.$emit('show-alert', this.$t('common.error'))
        },

        showInvoiceSummary(item) {
            if(item) {
                this.searchParams = {
                    to: this.getDateAsString(new Date(item.end_date)),
                    from: this.getDateAsString(new Date(item.start_date)),
                    pricingModel: item.pricing_model ? item.pricing_model.id : null,
                    contractIds: [item.order.contract.id],
                    orderDetails: 1,
                    orders: [item.order.id],
                    invoiceState: 2
                }
            }
            this.fetchInvoiceRows()
            this.$emit('updateSummaries')
        },
        emitTripDetails(row) {
            this.$emit('showTripDetails', row)
        },
        emitTripDeliveries(row) {
            this.$emit('showTripDeliveries', row)
        },
        emitTripOrder(row) {
            this.$emit('showTripOrder', row)
        },
        emitContractDetails(row) {
            this.$emit('showContractDetails', row)
        },
        emitInvoiceHistories(row) {
            this.$emit('showInvoiceHistories', row)
        },

    }
}
</script>
<style scoped>
 .order-text {
    display: inline-block;
    background: #ffffff;
    border-top: 1px #BCBCBC solid;
    margin: 0;
    padding: .2em;
    font-size: .9em;
    user-select: none;
    min-height: 2em;
}
.order-text {
    color: #404041;
    padding-left: 2.5em;
}
.single-line {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.order-text:hover, .single-line:hover {
    color: #007BFF;
    font-weight: bold;
}
</style>
