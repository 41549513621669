<template>
    <div v-if="imageContainerId" :class="`rotate-button-container ${controlSize}`">
        <div class="nopads" style="width: 1.7em !important;"> </div>
        <div
            class="rotate-button"
            @click.stop="rotateRight"
        >
            <i id="rotateBtn" class="fa fa-repeat"/>
        </div>
        <div
            v-if="rotationAngle !== 0 && !saveDisabled"
            class="rotate-button"
            @click.stop="saveRotation"
        >
            <i id="saveBtn" class="fa fa-save"/>
        </div>
        <div v-else class="nopads" style="width: 1.7em !important;"> </div>
        <b-tooltip target="rotateBtn" triggers="hover" placement="left">
            {{ $t('common.rotate') }}
        </b-tooltip>
        <b-tooltip v-if="rotationAngle !== 0" target="saveBtn" triggers="hover" placement="right">
            {{ $t('common.save') }}
        </b-tooltip>

    </div>
</template>

<script>

export default {
    name: 'RotateImageButtons',
    mixins: [],
    props: {
        imageContainerId: {
            type: String,
            default: null,
            required: false
        },
        controlSize: {
            type: String,
            default: 'md'
        },
        saveDisabled: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        rotationAngle() {
            this.rotateImage()
        }
    },
    data() {
        return {
            rotationAngle: 0
        }
    },
    methods: {
        rotateRight() {
            this.rotationAngle = (this.rotationAngle + 90) % 360
        },
        rotateLeft() {
            this.rotationAngle = (this.rotationAngle - 90 + 360) % 360
        },
        rotateImage() {
            const img = document.getElementById(this.imageContainerId);
            if (img) {
                img.style.transform = `rotate(${this.rotationAngle}deg)`;
                if (this.rotationAngle % 180 !== 0) {
                    const containerHeight = img.parentElement.clientHeight
                    const containerWidth = img.parentElement.clientWidth
                    const imgHeight = img.clientHeight
                    const imgWidth = img.clientWidth
                    const scale = Math.min(containerHeight / imgWidth, containerWidth / imgHeight)
                    img.style.transform += ` scale(${scale})`
                }
            }
        },
        saveRotation() {
            if (this.rotationAngle !== 0) {
                this.$emit('saveClicked', this.rotationAngle)
            }
        },
        resetRotation() {
            this.rotationAngle = 0
        }
    }
}
</script>
<style scoped>
.rotate-button-container {
    display: flex;
    width: 100%;
    gap: 5px;
    top: 5px;
    z-index: 2;
    position: absolute;
    justify-content: center;
}
.sm {
    gap: 0;
}
.sm button {
    transform: scale(0.7);
}
.rotate-button {
    background: rgba(49, 49, 49, 0.75);
    border: 1px solid rgba(49,49,49,0.75);
    height: 1.6em;
    width: 1.6em;
    border-radius: .8em;
    text-align: center;
    color: #BEFF41;
    line-height: 1.6em;
    vertical-align: center;
    cursor: pointer;
    margin-left: .2em;
    margin-right: .2em;
}
</style>
