<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
            @click="showList">
            <div v-if="!editMode"
                 class="header">
                <span class="title">{{ $t('work_assignment.title') }}</span>
            </div>
            <div v-else-if="!isMobile"
                 class="header col-sm-12 nopads"
                 @click="showList">
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer">{{ $t('common.back') }}</span>
            </div>
        </b-navbar>

        <keep-alive>
            <work-assignment-search
                v-if="!editMode"
                :is-admin="isAdmin"
                :is-observer="isObserver"
                :user="user"
                @search="fetchWorkAssignments"
            />
        </keep-alive>

        <div
            v-if="!editMode"
            class="button-container col-12 trip-buttons pb-0 mb-1"
        >
            <b-button
                variant="success"
                class="result-button"
                @click.stop="addWorkAssignment()"
            >
                {{ $t('common.add_new') }}
            </b-button>
            <b-button
                variant="primary"
                class="result-button"
                @click="toggleMapView"
            >
                {{ mapVisible ? this.$t('trip.show_table') : this.$t('trip.show_map') }}
            </b-button>
        </div>
        <div v-if="mapVisible && !editMode" class="col-sm-12 pb-2">
            <div class="map-container mr-3 ml-3 mb-2" style="height: 40em; border-radius: .75em; border: 1px solid #BCBCBC" >
                <map-container
                    :find-user="false"
                    ref="mapContainer"
                    @onMarkerTap="onMarkerTap"
                />
                <work-assignment-map
                    v-if="map"
                    :is-admin="isAdmin"
                    :is-observer="isObserver"
                    :user="user"
                    :map="map"
                    :work-assignment-results="results"
                />
            </div>
        </div>

        <transition name="fade">
            <keep-alive>
                <work-assignment-list
                    v-if="!editMode && !mapVisible"
                    :results=results
                    @edit="editDetails"
                />
            </keep-alive>
        </transition>

        <transition name="fade">
            <work-assignment-editor
                class="m-2"
                v-if="editMode"
                :area-defect="areaDefect"
                :vehicle-defect="vehicleDefect"
                :observation="observation"
                :work-permission="workPermission"
                :is-admin="isAdmin"
                :user="user"
                :is-observer="isObserver"
                :work-assignment="workAssignment"
                @close="close"
                @closeAndUpdate="closeAndUpdate"
                @updateWorkAssignments="updateWorkAssignments"
            />
        </transition>
        <div v-if="loading"
             id="loader"
             class="spinner">
        </div>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import WorkAssignmentList from './WorkAssignmentList'
import WorkAssignmentEditor from './WorkAssignmentEditor'
import WorkAssignmentSearch from './WorkAssignmentSearch'
import WorkAssignmentMap from "./WorkAssignmentMap.vue";
import MapContainer from "@/components/map/MapContainer";
import {mapHelper} from "@/components/mixins/MapMixin";

export default {
    name: 'WorkAssignments',
    components: {
        MapContainer,
        WorkAssignmentMap,
        WorkAssignmentList,
        WorkAssignmentEditor, WorkAssignmentSearch},
    mixins: [restApi, mapHelper],
    props: {
        isAdmin: Boolean,
        isObserver: Boolean,
        user: null,
        isMobile: Boolean
    },
    data: function () {
        return {
            loading: false,
            searchParams: null,
            results: [],
            editMode: false,
            map: null,
            workAssignment: null,
            observation: null,
            areaDefect: null,
            vehicleDefect: null,
            workPermission: null,
            mapVisible: false
        }
    },
    methods: {
        fetchWorkAssignments: function (params) {
            this.results = []
            this.searchParams = params
            this.loading = true
            this.restFetchParams(this.workAssignmentUrl, params, this.handleResponse, this.onError)
        },
        toggleMapView: function () {
            this.mapVisible = !this.mapVisible
            if (this.mapVisible) {
                this.$nextTick(() => this.map = this.$refs.mapContainer.getMap());
            } else {
                this.map = null
            }
        },
        handleResponse: function (response) {
            this.results = response.data;
            this.loading = false;
            // Check if the map is visible
            if (this.mapVisible) {
                // Use $nextTick to ensure DOM updates and reactivity are completed
                this.$nextTick(() => {
                    // Execute getMap only after results are updated and the DOM is ready
                    if (this.results.length > 0) {// Ensure results are loaded
                        this.map = this.$refs.mapContainer.getMap();
                    }
                });
            }
        },
        onError: function () {
            this.loading = false
        },
        showList: function () {
            this.editMode = false
            if (this.mapVisible) {
                this.$nextTick(() => this.map = this.$refs.mapContainer.getMap());
            }
        },
        editDetails: function (id) {
            this.workAssignment = this.results.find(item => item.id === id)
            if (this.workAssignment) {
                this.observation = this.workAssignment.observation
                this.areaDefect = this.workAssignment.area_defect
                this.vehicleDefect = this.workAssignment.vehicle_defect
                this.workPermission = this.workAssignment.special_work_permission
                this.editMode = true
                this.map = null
            }
        },
        close: function () {
            this.editMode = false
            if (this.mapVisible) {
                this.$nextTick(() => this.map = this.$refs.mapContainer.getMap());
            }
        },
        updateWorkAssignments() {
            this.fetchWorkAssignments(this.searchParams)
        },
        closeAndUpdate: function () {
            this.editMode = false
            this.fetchWorkAssignments(this.searchParams)
        },
        addWorkAssignment: function () {
            this.workAssignment = null
            this.observation = null
            this.areaDefect = null
            this.vehicleDefect = null
            this.workPermission = null
            this.editMode = true
            this.map = null
        },
        onMarkerTap: function (data) {
            if (data.type === this.WORK_ASSIGNMENT) {
                this.editDetails(data.id)
            }
        },
    }
}
</script>
