<template>
    <div class="col-sm-12 nopads">
        <div class="ml-3 mr-3 white-bg">
            <div class="col-sm-12 form-title editor-title">
                {{ editOld ? $t('weighing_notes.edit') : $t('weighing_notes.add_new') }}
            </div>
            <b-form
                @submit.prevent="onSubmit"
                @reset="$emit('close')"
            >
                <b-row class="nopads mt-3">
                    <div class="col-sm-4">
                        <span class="search-title">{{ $t('delivery_note.date') }} - {{
                                $t('trip_list.road_sec_from')
                            }}</span>
                            <b-form-input
                                id="start"
                                type="date"
                                :value="timestampToDateInput(dateFrom)"
                                size="sm"
                                @change="updateFromDate"
                            />
                    </div>
                    <div class="col-sm-4">
                        <span class="search-title">{{ $t('delivery_note.date') }} - {{
                                $t('trip_list.road_sec_to')
                            }}</span>
                        <b-form-input
                            id="start"
                            type="date"
                            :value="timestampToDateInput(dateTo)"
                            size="sm"
                            @change="updateToDate"
                        />
                    </div>
                </b-row>

                <b-row class="nopads">
                    <div class="col-sm-5">
                        <span class="span-title">{{ $t('delivery_note.contract') }}</span>
                        <b-form-select
                            v-model="contract"
                            size="sm"
                            @change="selectContract"
                            :disabled="!!loading"
                        >
                            <option :value="null">
                                {{ $t('delivery_note.form.select_contract') }}
                            </option>
                            <option
                                v-for="contract in contracts"
                                :key="contract.id"
                                :value="contract.id"
                            >
                                {{ contract.name }}
                            </option>
                        </b-form-select>
                        <b-form-group v-if="showOnlyMyContractsOption">
                            <b-form-checkbox
                                v-model="onlyUserOwnedContracts"
                                size="md"
                                type="text"
                                value=1
                                unchecked-value=0>
                                {{ $t('contracts.only_user_owned_contracts') }}
                            </b-form-checkbox>
                        </b-form-group>
                    </div>
                    <div class="col-sm-5">
                        <contract-order-filter
                            v-model="order"
                            :pre-selected-order="noteUpdate && noteUpdate.order ? noteUpdate.order.id : null"
                            :contract-id="contract"/>
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-5">
                        <span class="span-title">{{ $t('delivery_note.amount') }}</span>
                        <b-row class="nopads">
                            <div class="col-7 nopads pr-1">
                                <b-form-input
                                    :value="noteUpdate && noteUpdate.amount"
                                    @input="i => noteUpdate.amount = Number(i)"
                                    type="number"
                                    step="0.01"
                                    size="sm"
                                    :disabled="!!loading"
                                />
                            </div>
                            <div class="col-5 nopads">
                                <b-form-select
                                    v-model="unit"
                                    size="sm"
                                    :disabled="!!loading"
                                >
                                    <option :value="-1">
                                        {{ $t('delivery_note.form.select_unit') }}
                                    </option>
                                    <option
                                        v-for="unit in units"
                                        :key="unit.value"
                                        :value="unit.value"
                                        :disabled="!!loading"
                                    >
                                        {{ unit.value }}
                                    </option>
                                </b-form-select>
                            </div>
                        </b-row>
                    </div>
                </b-row>

                <!--Attachment upload -->
                <div class="ml-3 mr-1">
                    <span class="span-title">{{ $t('work_assignment.add_attachment') }}</span>
                    <div
                        v-for="(path, counter) in attachments"
                        :key="counter"
                        class="col-sm-12 nopads"
                    >
                        <b-form-group
                            class="title"
                            label-for="counter"
                            v-if="counter === 0 || isAttachmentSet(attachments, counter-1)"
                        >
                            <b-form-file
                                v-model="attachments[counter]"
                                :accept="getValidAttachmentTypes()"
                                :placeholder="$t('common.attachment_placeholder')"
                            />
                        </b-form-group>
                    </div>
                </div>

                <!-- Attachments-->
                <div v-if="noteUpdate && noteUpdate.attachments && noteUpdate.attachments.length" class="ml-3 mr-1">
                    <hr/>
                    <h4 class="form-header">{{ $t('orders.attachments_and_info')}}</h4>
                    <div>
                        <span class="span-title">{{ $t('common.attachments') }}</span>
                        <ol>
                            <li class="pointer"
                                v-for="item in noteUpdate.attachments"
                                :key="item.id">
                                <span v-on:click="downloadAttachment(item)">{{ item.file_original_name }}</span>
                                <b-button
                                    variant="danger"
                                    size="sm"
                                    class="ml-3"
                                    @click.stop="removeAttachment(item)"
                                >
                                    <i class="fa fa-trash"/>
                                </b-button>
                            </li>
                        </ol>
                    </div>
                </div>
               <div class="col-sm-12 button-container pb-2">
                   <b-button
                       type="reset"
                       variant="primary"
                       class="result-button"
                       size="sm"
                   >
                       {{ editOld ? $t('common.back') : $t('delivery_note.clear') }}
                   </b-button>
                   <b-button
                       type="submit"
                       variant="success"
                       class="result-button"
                       size="sm"
                       :disabled="!!loading"
                   >
                       {{ editOld ? $t('common.edit') : $t('delivery_note.add') }}
                   </b-button>
               </div>
           </b-form>
       </div>
       <div
           v-if="!!loading"
           id="loader"
           class="spinner"
       />
   </div>
</template>

<script>
import {weighingNoteHelper, units} from "@/components/mixins/WeighingNoteMixin";
import {restApi} from "@/components/mixins/RestApiMixin";
import {EventBus} from "@/event-bus";
import {orderMixin} from "@/components/mixins/OrderMixin";
import {attachmentHelper} from "@/components/mixins/AttachmentMixin";
import {vehicleTypes} from "@/components/mixins/VehicleMixin";
import ContractOrderFilter from "../../order/ContractOrderFilter";

export default {
   name: "WeighingNoteEditor",
   components: {ContractOrderFilter},
   mixins: [weighingNoteHelper, restApi, orderMixin, attachmentHelper, vehicleTypes],
   props: {
       note: {
           type: Object,
           default: null
       },
       contracts: {
           type: Array,
           default() {
               return []
           }
       },
       showOnlyMyContractsOption: {
           type: Boolean,
           default: false
       }
   },
   data() {
       return {
           noteUpdate: this.note,
           contract: null,
           order: null,
           units: [
               {value: units.TONS},
               {value: units.CUBIC_METERS}
           ],
           attachments: new Array(100),
           unit: null,
           loading: 0,
           createdTime: null,
           dateFrom: null,
           dateTo: null,
           onlyUserOwnedContracts: 0
       }
   },
   mounted() {
       this.initValues();
   },
   computed: {
       editOld: function () {
           return this.noteUpdate && this.noteUpdate.id > 0
       }
   },
   watch: {
       note: function () {
           this.noteUpdate = this.jsonToItem(this.note)
           this.initValues()
       },
       onlyUserOwnedContracts: function() {
           this.$emit('onlyUserOwnedContractSelected', this.onlyUserOwnedContracts, "editor")
       },
       unit: function () {
           this.noteUpdate.unit = this.unit ? this.unit : null
       },
       order: function () {
           this.noteUpdate.order = this.order ? {id: this.order.id} : {id: -1}
       },
       contract: function () {
           this.noteUpdate.contract = this.contract ? {id: this.contract} : {id: -1}
       },
       dateFrom: function () {
           this.noteUpdate.date_from = this.dateFrom ? this.dateFrom : null
       },
       dateTo: function () {
           this.noteUpdate.date_to = this.dateTo ? this.dateTo : null
       }
   },
   methods: {

       initValues() {
           if (this.noteUpdate && this.noteUpdate.unit) {
               this.unit = this.noteUpdate.unit
           }
           if (this.noteUpdate && this.noteUpdate.order) {
               this.order = this.noteUpdate.order
           }
           if (this.noteUpdate && this.noteUpdate.contract) {
               this.contract = this.noteUpdate.contract.id
           }
           if (this.noteUpdate && this.noteUpdate.date_from) {
               this.dateFrom = this.noteUpdate.date_from
           }
           if (this.noteUpdate && this.noteUpdate.date_to) {
               this.dateTo = this.noteUpdate.date_to
           }
           if (this.editOld) {
               this.contract = this.note.contract.id
               this.selectContract(this.contract, false)
           } else {
               if (this.contract) {
                   this.noteUpdate.contract = {id: this.contract}
               }
               if (this.order) {
                   this.noteUpdate.order = {id: this.order}
               }
           }
       },

       onReset: function () {
           this.noteUpdate = this.note
           this.contract = null
           this.onlyUserOwnedContracts = 0
           this.order = null
       },
       onSubmit: function () {
           this.noteUpdate.unit = this.unit
           let jsonNote = this.validateInput(this.noteUpdate)
           if (jsonNote !== undefined) {
               this.loading++
               if (!this.editOld) {
                   this.restAdd(this.weighingNoteUrl, jsonNote, this.addSuccess, this.fail)
               } else {
                   this.restUpdate(this.weighingNoteUrl, jsonNote, this.addSuccess, this.fail)
               }
           }
       },
       validateInput: function (inputNote) {
           let note = JSON.parse(JSON.stringify(inputNote))
           if (note.contract.id < 0) {
               EventBus.$emit('show-alert', this.$t('weighing_notes.form_validation.select_contract'))
               return undefined
           }
           if (!note.amount || !note.unit) {
               EventBus.$emit('show-alert', this.$t('delivery_note.form_validation.amount_unit'))
               return undefined
           }
           if(!this.dateFrom) {
               EventBus.$emit('show-alert', this.$t('weighing_notes.form_validation.select_date_from'))
               return undefined
           }
           if(!this.dateTo) {
               EventBus.$emit('show-alert', this.$t('weighing_notes.form_validation.select_date_to'))
               return undefined
           }
           if (this.dateFrom && (this.dateFrom instanceof Date)) {
               note.date_from = this.dateFrom.toISOString()
           }
           if (this.dateTo && (this.dateTo instanceof Date)) {
               note.date_to = this.dateTo.toISOString()
           }
           return note
       },
       uploadAttachment: function (response) {
           let attachments = this.getValidAttachments(this.attachments)
           if (attachments.length > 0) {
               let formData = new FormData()
               attachments.forEach(file => {
                   if (file !== undefined && file !== null) {
                       formData.append('attachments[]', file)
                   }
               })
               this.restPostWithFile(this.weighingNoteAttachmentUrl + response.id, {}, formData,
                   () => {
                       this.loading = false
                       this.$emit('closeAndUpdate')
                   }, this.fail
               )
           } else {
               this.loading = false
               this.$emit('closeAndUpdate')
           }
       },
       downloadAttachment: function (item) {
           this.loading = true
           this.restFetch(this.weighingNoteAttachmentUrl + this.noteUpdate.id + '/' + item.id,
               response => {
                   this.saveFile(item.file_original_name, response.data)
                   this.loading = false
               }, () => {
                   this.loading = false
               })
       },
       removeAttachment: function (item) {
           if(this.noteUpdate.attachments && this.noteUpdate.attachments.length > 0) {
               var index = this.noteUpdate.attachments.findIndex(attachment => attachment.id === item.id)
               if(index >= 0) {
                   this.noteUpdate.attachments.splice(index, 1)
               }
           }
       },
       addSuccess: function (response) {
           this.loading--
           EventBus.$emit('show-alert', this.$t('common.alert_update_success'), 'success')
           this.uploadAttachment(response)
           this.onReset()
       },
       updateFromDate: function (event) {
           if (event) {
               this.dateFrom = new Date(event)
           } else {
               this.dateFrom = undefined
           }
       },

       updateToDate: function (event) {
           if (event) {
               this.dateTo = new Date(event)
           } else {
               this.dateTo = undefined
           }
       },
       fail: function () {
           if (!this.editOld) {
               EventBus.$emit('show-alert', this.$t('common.alert_add_failed'))
           } else {
               EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
           }
           this.loading--
       },
       selectContract: function (id, update = true) {
           if (!this.editOld || (this.editOld && update)) {
               if(this.noteUpdate && this.noteUpdate.order) {
                   this.noteUpdate.order.id = -1
               }
           }
       }
   }
}
</script>
