import { render, staticRenderFns } from "./TripWithObservationsReport.vue?vue&type=template&id=578bf73e&scoped=true"
import script from "./TripWithObservationsReport.vue?vue&type=script&lang=js"
export * from "./TripWithObservationsReport.vue?vue&type=script&lang=js"
import style0 from "./TripWithObservationsReport.vue?vue&type=style&index=0&id=578bf73e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "578bf73e",
  null
  
)

export default component.exports