<template>
    <div class="col-sm-12 p-1">
        <div class="col-sm-12 nopads">
            <b-row class="nopads">
                <div class="col-sm-6 nopads">
                    <span v-if="isDateToday" class="tip-light">{{ $t('contracts.external.diary_date_hint')}}</span>
                </div>
                <div class="col-sm-6 nopads pt-2 pb-2 text-right">
                    <b-button
                        variant="info"
                        class="result-button"
                        v-on:click.stop="fetchDiary"
                        :disabled="isDateToday"
                    >
                        {{ $t('contracts.external.create_report') }}
                    </b-button>
                    <b-button
                        v-if="hasIntegrationSystem(user) && data && !!contract.work_site_key"
                        class="form-button"
                        :disabled="!data"
                        v-on:click.stop="sendToBuildie"
                    >
                        {{ $t('city_contract_diary.send_to_integration_service') }}
                    </b-button>
                </div>
            </b-row>
        </div>

        <div v-if="data" class="col-sm-12 editor-form nopads nopads p-2 pb-4 w-100 diary">
            <b-row>
                <div class="col-sm-12 nopads">
                    <b-row class="nopads">
                        <h4 class="form-header pt-0">{{ $t('city_contract_diary.city_contract_diary_title') }}</h4>
                        <font-awesome-icon
                            icon="print"
                            class="print-icon ml-auto"
                            @click.stop="downloadPdf"
                        />
                    </b-row>
                    <b-row class="nopads">
                        <span class="span-title">{{ getDate(data.date, true) }}</span>
                    </b-row>
                    <b-row class="nopads">
                        <span class="span-title">{{ data.contract_name }}</span>
                    </b-row>
                    <b-row class="nopads">
                        <span class="span-title">{{ data.contractor }}</span>
                    </b-row>
                </div>
            </b-row>
            <b-row>
                <div class="col-sm-12 nopads">
                    <b-row class="nopads">
                        <h4 class="form-header pt-0">{{ $t('contract_diary.events') }}</h4>
                    </b-row>
                    <b-row class="nopads">
                        <span class="span-title">{{ data.events }}</span>
                    </b-row>
                </div>
            </b-row>
            <b-row>
                <div class="col-sm-12 nopads">
                    <b-row class="nopads">
                        <h4 class="form-header pt-0">{{ $t('contract_diary.deviations') }}</h4>
                    </b-row>
                    <b-row class="nopads">
                        <span class="span-title">{{ data.deviations }}</span>
                    </b-row>
                </div>
            </b-row>
            <div v-if="data.photos && data.photos.length > 0" class="mt-4 pl-1">
                <!-- PHOTOS -->
                <h4 class="form-header">{{ $t('area_report_details.photos') }}</h4>
                <b-row v-if="data.photos.length > 1" class="nopads text-center mt-2">
                    <div class="col-sm-4 nopads pl-1 multi-img-row" v-for="(photo, index) in data.photos" v-bind:key="index">
                        <div class="image-container">
                            <ImageView
                                :img-size="'sm'"
                                :url="getPhotoUrl(contractDiaryPhotoUrl, photo.diary, photo.photo)"
                                :contain="true"
                                :height="'300px'"
                                :controlSize="'sm'"
                            />
                        </div>
                    </div>
                </b-row>
                <b-row v-else-if="data.photos.length > 0" class="nopads text-center">
                    <div class="col-12 nopads">
                        <div class="image-container">
                            <ImageView
                                :img-size="'sm'"
                                :url="getPhotoUrl(contractDiaryPhotoUrl, data.photos[0].diary, data.photos[0].photo)"
                                :contain="true"
                                :height="'300px'"
                                :controlSize="'sm'"
                            />
                        </div>
                    </div>
                </b-row>
            </div>

            <b-row class="mt-4">
                <div class="col-sm-12 nopads">
                    <b-row class="nopads">
                        <h4 class="form-header">{{ $t('city_contract_diary.compelted_tasks') }}</h4>
                    </b-row>
                    <b-row class="nopads">
                        <table class="table table-sm">
                            <tbody>
                                <tr v-for="(item, index) in data.tasks" v-bind:key="index">
                                    <td>{{ item.task }}</td>
                                    <td>{{ toLocalTime(item.start_time) }} - {{ toLocalTime(item.end_time)}}</td>
                                    <td>{{ getWorkTime(Math.round(item.work_duration / 60)) }} {{ $t('contracts.unit_hours') }}</td>
                                    <td>{{ Number(item.work_distance).toFixed(2) }} {{ $t('contracts.unit_km') }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </b-row>
                </div>
            </b-row>
            <b-row>
                <div class="col-sm-12 nopads">
                    <b-row class="nopads">
                        <h4 class="form-header">{{ $t('city_contract_diary.resources') }}</h4>
                    </b-row>
                    <b-row class="nopads">
                        <table class="table table-sm">
                            <tbody>
                                <tr class="space-cells" v-for="(item, index) in data.resources" v-bind:key="index">
                                    <td>{{ item.resource === -1 ? $t('city_contract_diary.resources_persons') : getVehicleTypeName(item.resource) }}</td>
                                    <td>{{ item.count }} {{ $t('common.pcs') }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </b-row>
                </div>
            </b-row>
            <b-row>
                <div class="col-sm-12 nopads">
                    <b-row class="nopads">
                        <h4 class="form-header">{{ $t('city_contract_diary.materials') }}</h4>
                    </b-row>
                    <b-row class="nopads">
                        <table v-if="data.materials.length > 0" class="table table-sm">
                            <tbody>
                                <tr class="space-cells" v-for="(item, index) in data.materials" v-bind:key="index">
                                    <td>{{ item.material }}</td>
                                    <td>{{ item.amount }} {{ item.unit }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <span v-else class="span-title">-</span>
                    </b-row>
                </div>
            </b-row>
            <b-row>
                <div class="col-sm-12 nopads">
                    <b-row class="nopads">
                        <h4 class="form-header">{{ $t('city_contract_diary.danger_reports') }}</h4>
                    </b-row>
                    <b-row class="nopads">
                        <table v-if="data.danger_reports.length > 0" class="table table-sm">
                            <tbody>
                                <tr v-for="(item, index) in data.danger_reports" v-bind:key="index">
                                    <td>{{ item.incidence }}</td>
                                    <td>{{ toLocalTime(item.time) }}</td>
                                    <td>{{ item.description }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <span v-else class="span-title">-</span>
                    </b-row>
                </div>
            </b-row>
            <b-row>
                <div class="col-sm-12 nopads">
                    <b-row class="nopads">
                        <h4 class="form-header">{{ $t('weather_station.data_title')}}</h4>
                    </b-row>
                    <b-row class="nopads">
                        <table class="table table-sm">
                            <tr>
                                <th>{{ $t('contracts.external.time') }}</th>
                                <th>{{ $t('contracts.external.station') }}</th>
                                <th>{{ $t('contracts.external.air_temp') }}</th>
                                <th>{{ $t('contracts.external.road_temp') }}</th>
                                <th>{{ $t('contracts.external.avg_wind') }}</th>
                                <th>{{ $t('contracts.external.rain_type') }}</th>
                                <th>{{ $t('contracts.external.rain_amount') }}</th>
                            </tr>
                            <tbody>
                                <tr v-for="(item, index) in data.weather" v-bind:key="index">
                                    <td>{{ toLocalTime(item.saatieto.havaintoaika) }}</td>
                                    <td>{{ item.saatieto['aseman-tunniste'] }}</td>
                                    <td>{{ item.saatieto['ilman-lampotila'] }}</td>
                                    <td>{{ item.saatieto['tien-lampotila'] }}</td>
                                    <td>{{ item.saatieto['keskituuli'] }}</td>
                                    <td>{{ item.saatieto['sateen-olomuoto'] }}</td>
                                    <td>{{ item.saatieto['sadesumma'] }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </b-row>
                </div>
            </b-row>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        ></div>
    </div>
</template>

<script>
import {restApi} from "@/components/mixins/RestApiMixin";
import {timeUtils} from "@/components/mixins/TimeUtils";
import {vehicleHelper} from '@/components/mixins/VehicleMixin'
import {PdfGeneratorManager} from "@/modules/PdfGeneratorManager";
import {PdfGeneratorService} from "@/modules/PdfGeneratorService";
import {StandardFonts} from 'pdf-lib';
import {EventBus} from "@/event-bus";
import ImageView from "@/components/view/ImageView";
import {userHelper} from "@/components/mixins/UserMixin";

export default {
    name: 'CityContractDiary',
    components: {ImageView},
    mixins: [timeUtils, vehicleHelper, restApi, userHelper],
    props: {
        contract: {
            type: Object,
            default: null
        },
        date: {
            type: Date,
            default: new Date()
        },
        user: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            data: null
        }
    },
    computed: {
        isDateToday() {
            if (!this.date) {
                return true
            }
            const today = new Date()
            return (today.getFullYear() === this.date.getFullYear() && today.getMonth() === this.date.getMonth() && today.getDate() === this.date.getDate())
        },
        isContractSelected() {
            return this.contract
        }
    },
    methods: {
        fetchDiary() {
            if (!this.isContractSelected) {
                EventBus.$emit('show-alert', this.$t('ins_wizard.select_contract'))
                return
            }
            this.loading = true
            this.data = null

            let params = {
                contract: this.contract.id,
                from: this.date.toISOString()
            }
            this.restFetchParams(this.contractDiaryCityUrl, params, this.handleResult, this.handleError)
        },
        handleResult(response) {
            this.loading = false
            if (response && response.data) {
                this.data = response.data
            }
        },
        handleError() {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('city_contract_diary.failed_to_fetch_diary'))
        },
        getPhotoUrl: function (baseUrl, diary, photo) {
            return baseUrl + '/' + diary + '/' + photo
        },
        async downloadPdf() {
            this.loading = true
            const pdfBytes = await this.generatePdf()
            PdfGeneratorManager.downloadPdf(pdfBytes, this.$t('city_contract_diary.city_contract_diary_title'))
            this.loading = false
        },
        async generatePdf() {
            const pdfGeneratorManager = new PdfGeneratorManager()
            const pdfDoc = await pdfGeneratorManager.createStandardRoutaReportPdf()
            await PdfGeneratorService.cacheRoutaFooterLogo()
            const footerLogo = pdfGeneratorManager.embeddedImages.footer
            const pdfService = pdfGeneratorManager.pdfGeneratorService

            const font = await pdfService.embedFontToDoc(pdfDoc, StandardFonts.Helvetica)
            const fontBold = await pdfService.embedFontToDoc(pdfDoc, StandardFonts.HelveticaBold)
            // This is used but for some reason lint does not see it
            // eslint-disable-next-line no-unused-vars
            let nextYPosition = undefined

            // Document title
            nextYPosition = pdfService.addText(pdfDoc, this.$t('city_contract_diary.city_contract_diary_title'), fontBold, 14, nextYPosition, undefined, undefined, undefined, footerLogo)
            nextYPosition = pdfService.addText(pdfDoc, this.getDate(this.data.date, true), font, 12, nextYPosition, undefined, undefined, undefined, footerLogo)
            nextYPosition = pdfService.addText(pdfDoc, `${this.data.contract_name}`, font, 12, nextYPosition, undefined, undefined, undefined, footerLogo)
            nextYPosition = pdfService.addText(pdfDoc, `${this.data.contractor}`, font, 12, nextYPosition, undefined, undefined, undefined, footerLogo)
            nextYPosition -= 20
            // Events
            nextYPosition = pdfService.addText(pdfDoc, `${this.$t('contract_diary.events')}`, fontBold, 14, nextYPosition, undefined, undefined, undefined, footerLogo)
            nextYPosition = pdfService.addText(pdfDoc, `${this.data.events}`, font, 12, nextYPosition, undefined, undefined, undefined, footerLogo)
            nextYPosition -= 20
            // Deviations
            nextYPosition = pdfService.addText(pdfDoc, `${this.$t('contract_diary.deviations')}`, fontBold, 14, nextYPosition, undefined, undefined, undefined, footerLogo)
            nextYPosition = pdfService.addText(pdfDoc, `${this.data.deviations}`, font, 12, nextYPosition, undefined, undefined, undefined, footerLogo)
            nextYPosition -= 20


            //Fetch photos

            const photos = await this.fetchPhotos()

            const images = []

            for (let photo of photos) {
                let image
                if (this.isPng(photo)) {
                    image = await pdfDoc.embedPng(photo)
                } else {
                    image = await pdfDoc.embedJpg(photo)
                }
                images.push(image)
            }

            //Add photos

            if (images.length > 1) {
                nextYPosition = pdfService.addImages(pdfDoc, undefined, images, undefined, nextYPosition, footerLogo, 1, 1, 3)
                nextYPosition -= 55
            } else if (images.length >= 1) {
                nextYPosition = pdfService.addImage(pdfDoc, undefined, images[0], undefined, nextYPosition, footerLogo, 140, 1, 1)
                nextYPosition -= 55
            }



            // Tasks section
            nextYPosition = pdfService.addText(pdfDoc, this.$t('city_contract_diary.compelted_tasks'), fontBold, 12, nextYPosition, undefined, undefined, undefined, footerLogo)
            const tasksData = [
                [], // Header
                ...this.data.tasks.map(item => [ // Data
                    item.task,
                    `${this.toLocalTime(item.start_time)} - ${this.toLocalTime(item.end_time)}`,
                    `${this.getWorkTime(Math.round(item.work_duration / 60))} ${this.$t('contracts.unit_hours')}`,
                    `${Number(item.work_distance).toFixed(2)} ${this.$t('contracts.unit_km')}`,
                ])
            ]
            nextYPosition = pdfService.addTable(tasksData, pdfDoc, {textFont: font, headerFont: fontBold, textFontSize: 9, headerFontSize: 9}, nextYPosition, undefined, undefined, footerLogo)
            // Substracting will move the text down
            nextYPosition -= 40

            // Resources section
            nextYPosition = pdfService.addText(pdfDoc, this.$t('city_contract_diary.resources'), fontBold, 12, nextYPosition, undefined, undefined, undefined, footerLogo)
            const resourcesData = this.data.resources.map(item => [
                `${item.resource === -1 ? this.$t('city_contract_diary.resources_persons') : this.getVehicleTypeName(item.resource)}`,
                `${item.count} ${this.$t('common.pcs')}`,
            ])
            if(resourcesData.length === 0) {
                nextYPosition = pdfService.addText(pdfDoc, `-`, font, 12, nextYPosition, undefined, undefined, 25, footerLogo)
            } else {
                nextYPosition = pdfService.addVerticalTable(resourcesData, pdfDoc, {textFont: font, headerFont: fontBold, textFontSize: 9, headerFontSize: 9}, nextYPosition, undefined, undefined, footerLogo)
            }

            // Substracting will move the text down
            nextYPosition -= 40

            // Material section
            nextYPosition = pdfService.addText(pdfDoc, this.$t('city_contract_diary.materials'), fontBold, 12, nextYPosition, undefined, undefined, undefined, footerLogo)
            const materialsData = this.data.materials.map(item => [
                `${item.material}`,
                `${item.amount} ${item.unit}`,
            ])
            if(materialsData.length === 0) {
                nextYPosition = pdfService.addText(pdfDoc, `-`, font, 12, nextYPosition, undefined, undefined, 25, footerLogo)
            } else {
                nextYPosition = pdfService.addVerticalTable(materialsData, pdfDoc, {textFont: font, headerFont: fontBold, textFontSize: 9, headerFontSize: 9}, nextYPosition, undefined, undefined, footerLogo)
            }
            // Substracting will move the text down
            nextYPosition -= 40

            // Danger reports section
            nextYPosition = pdfService.addText(pdfDoc, this.$t('city_contract_diary.danger_reports'), fontBold, 12, nextYPosition, undefined, undefined, undefined, footerLogo)
            const dangerReportsData = [
                [], // Header
                ...this.data.danger_reports.map(item => [
                    `${item.incidence}`,
                    `${this.toLocalTime(item.time)}`,
                    `${item.description}`,
                ])
            ]
            if(dangerReportsData.length < 2) {
                nextYPosition = pdfService.addText(pdfDoc, `-`, font, 12, nextYPosition, undefined, undefined, 25, footerLogo)
            } else {
                nextYPosition = pdfService.addTable(dangerReportsData, pdfDoc, {textFont: font, headerFont: fontBold, textFontSize: 9, headerFontSize: 9}, nextYPosition, undefined, undefined, footerLogo)
            }
            // Substracting will move the text down
            nextYPosition -= 40

            // Weather section
            nextYPosition = pdfService.addText(pdfDoc, this.$t('weather_station.data_title'), fontBold, 12, nextYPosition, undefined, undefined, 100, footerLogo)
            nextYPosition -= 20
            const weatherData = [
                [ // Header
                    this.$t('contracts.external.time'),
                    this.$t('contracts.external.station'),
                    this.$t('contracts.external.air_temp'),
                    this.$t('contracts.external.road_temp'),
                    this.$t('contracts.external.avg_wind'),
                    this.$t('contracts.external.rain_type'),
                    this.$t('contracts.external.rain_amount')
                ],
                // Data
                ...this.data.weather.map(item => {
                    const station = item.saatieto['aseman-tunniste']
                    return [
                        this.toLocalTime(item.saatieto.havaintoaika),
                        station ? station.replaceAll("_", " ") : "",
                        item.saatieto['ilman-lampotila'],
                        item.saatieto['tien-lampotila'],
                        item.saatieto['keskituuli'],
                        item.saatieto['sateen-olomuoto'],
                        item.saatieto['sadesumma']
                    ]
                })
            ]
            if(weatherData.length < 2) {
                nextYPosition = pdfService.addText(pdfDoc, `-`, font, 12, nextYPosition, undefined, undefined, 25, footerLogo)
            } else {
                nextYPosition = pdfService.addTable(weatherData, pdfDoc, {textFont: font, headerFont: fontBold, textFontSize: 9, headerFontSize: 9}, nextYPosition, undefined, undefined, footerLogo)
            }

            const pdfBytes = await pdfGeneratorManager.generateBytesFromPdfDoc(pdfDoc)

            return pdfBytes
        },
        isPng(photo) {
            return photo.startsWith('data:image/png;');
        },
        async fetchPhotos() {
            const results = []
            for (const photo of this.data.photos) {
                let url = this.getPhotoUrl(this.contractDiaryPhotoUrl, photo.diary, photo.photo)
                results.push((await new Promise(resolve => this.restFetch(url, resolve))).data)
            }
            return results
        },
        async sendToBuildie() {
            try {
                this.loading = true
                const pdfBytes = await this.generatePdf()
                const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' })
                const formData = new FormData()
                formData.append('file', pdfBlob, `${this.$t('city_contract_diary.city_contract_diary_title')}.pdf`)
                formData.append('date', this.getDateIsoString(this.date))
                formData.append('contract', this.contract.id)
                
                this.restPostWithFile(this.extBuildieUrl, {}, formData, () => {
                    this.loading = false
                    EventBus.$emit('show-alert', this.$t('city_contract_diary.success_to_send_diary_to_integration'), 'success')
                }, () => {
                    this.loading = false
                    EventBus.$emit('show-alert', this.$t('city_contract_diary.failed_to_send_diary_to_integration'))
                })
            } catch (error) {
                this.loading = false
                EventBus.$emit('show-alert', this.$t('city_contract_diary.failed_to_send_diary_to_integration'))
            }
        }
    }
}
</script>
<style scoped>
.diary {
    border: 1px #bcbcbc solid; border-radius: .5em;
}
.space-cells td {
    width: 50%;
}
.image-container {
    width: fit-content;
}
</style>
