<template>
    <div class="col-sm-12 editor-form">
        <b-form
            class="no-margin"
            @submit.prevent="setImportedContractData"
        >
            <b-row>
                <div class="col-sm-12">
                    <b-form-group
                        class="nopads"
                        label-for="importedDataDescription"
                    >
                        <span class="span-title text-bold">{{ $t('contracts.imported_contract_data_fetch_editor_decription') + ":" }}</span>
                    </b-form-group>
                </div>
            </b-row>
            <b-row v-if="importedContractData.name">
                <b-row>
                    <div class="col-sm-1 mt-4">
                        <b-form-group
                            class="nopads"
                            label-for="fromDate"
                        >
                            <b-form-checkbox
                                v-model="selectedCheckboxes['name']"
                            />
                        </b-form-group>
                    </div>
                    <div class="col-sm-6">
                        <b-form-group
                            class="nopads  pl-1"
                        >
                            <span class="span-title">{{ $t('contracts.name') }}</span>
                            <b-form-input
                                id="name"
                                :value="importedContractData.name"
                                type="text"
                                size="sm"
                                readonly
                            />
                        </b-form-group>
                    </div>
                </b-row>
            </b-row>
            <b-row v-if="importedContractData.start_date || importedContractData.end_date">
                <b-row>
                    <div class="col-sm-1 mt-4">
                        <b-form-group
                            class="nopads"
                            label-for="fromDate"
                        >
                            <b-form-checkbox
                                v-model="selectedCheckboxes['scheduledPeriods']"
                            />
                        </b-form-group>
                    </div>
                    <div class="col-sm-6">
                        <b-form-group
                            class="nopads"
                            label-for="startDate"
                        >
                            <span class="span-title">{{ $t('trip_list.start_time') }}</span>
                            <b-form-input
                                id="startDate"
                                :value="importedContractData.start_date"
                                type="text"
                                size="sm"
                                readonly
                            />
                        </b-form-group>
                    </div>
                    <div class="col-sm-5">
                        <b-form-group
                            class="nopads"
                            label-for="endDate"
                        >
                            <span class="span-title">{{ $t('trip_list.end_time') }}</span>
                            <b-form-input
                                id="endDate"
                                :value="importedContractData.end_date"
                                type="text"
                                size="sm"
                                readonly
                            />
                        </b-form-group>
                    </div>
                </b-row>
            </b-row>
            <b-row v-if="importedContractData.task_types">
                <b-row>
                    <div class="col-sm-1 mt-1">
                        <b-form-group
                            class="nopads"
                            label-for="fromDate"
                        >
                            <b-form-checkbox
                                v-model="selectedCheckboxes['task_types']"
                            />
                        </b-form-group>
                    </div>
                    <div class="col-sm-5 ml-2">
                        <span class="span-title">{{ $t('menu.task_types') }}</span>
                    </div>
                </b-row>
                <b-row class="pl-3">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-11 row inspectable-container nopads">
                        <div
                            v-for="(item, index) in importedContractData.task_types"
                            :key="index"
                            class="col-sm-4 col-md-4 inline task-types nopads"
                            :class="{ 'task-types-disabled' : !isTaskTypeSelected(item.id) }"
                            @click="addToSelection(item.id)"
                        >
                            {{ item.name }}
                        </div>
                    </div>
                </b-row>
            </b-row>
            <b-row v-if="importedContractData.orders">
                <b-row>
                    <div class="col-sm-1 mt-1">
                        <b-form-group
                            class="nopads"
                            label-for="fromDate"
                        >
                            <b-form-checkbox
                                v-model="selectedCheckboxes['open_orders']"
                            />
                        </b-form-group>
                    </div>
                    <div class="col-12 ml-2">
                        <span class="span-title">{{ $t('contracts.open_orders_from_imported_contract_data') }}</span>
                    </div>
                </b-row>
                <b-row class="pl-3">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-11 row inspectable-container nopads">
                        <div v-for="(item, index) in importedContractData.orders" :key="index" class="col-sm-4 col-md-4 inline task-types nopads" :class="{ 'task-types-disabled': !isOrderSelected(item.contract_task_type.task_type.id) }" @click="addToOrderSelection(item.contract_task_type.task_type.id)">
                            {{ item.contract_task_type.task_type.name }}
                            <hr class="order-import-hr">
                            {{ $t('orders.unit_price') }} - {{ item.unit_price }}
                            <hr class="order-import-hr" v-if="item.price_list">
                            <div v-if="item.hasOwnProperty('price_list')" class="nopads">
                                <span class="span-title">{{ $t('orders.pricelist') }}</span>
                                <!-- Price list -->
                                <b-form-select id="pricingModel" v-model="item.price_list" size="sm" class="mb-3">
                                    <template slot="first">
                                        <option :value="null">{{ $t('orders.select_pricelist') }}</option>
                                    </template>
                                    <!-- Loop price lists -->
                                    <option v-for="option in priceLists" :key="option.id" :value="option.id">{{ option.name }}</option>
                                </b-form-select>
                                <div class="col-sm-12 button-container nopads">
                                    <b-button style="margin-right: 10px;" variant="primary" size="sm" class="form-button" :disabled="loading" @click.stop="showPriceListEditor()">
                                        {{ $t('orders.add_pricelist') }}
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-row>
            </b-row>
            <b-row v-if="!importedContractData || importedContractData.length === 0">
                    <em>{{ $t('contracts.imported_contract_data_not_found') }}</em>
            </b-row>
            <div class="col-sm-12 button-container">
                <b-button
                    type="submit"
                    variant="primary"
                    class="result-button"
                    :disabled="!selectionValidated(selectedCheckboxes)"
                >
                    {{ $t('contracts.save_data_to_contract') }}
                </b-button>
            </div>
        </b-form>

        <!-- Price list addition -->
        <b-modal
            ref="pricelistModal"
            centered
            no-close-on-esc
            no-close-on-backdrop
            hide-header-close
            hide-footer
            class="nopads"
            size="lg"
            body-class="p-0"
            :title="$t('pricelist.add')">
            <price-list-editor
                :price-list="newList"
                :hideTitle="true"
                @close="$refs.pricelistModal.hide()"
                @closeAndUpdate="priceListAdded"/>
        </b-modal>

        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '@/components/mixins/RestApiMixin'
import {timeUtils} from '@/components/mixins/TimeUtils'
import {priceHelper} from "@/components/mixins/PricingMixin";
import PriceListEditor from "@/components/pricingmodels/pricelists/PriceListEditor";
import {EventBus} from '@/event-bus'

export default {
    name: 'contract-data-importer',
    components: {PriceListEditor},
    mixins: [restApi, timeUtils, priceHelper],
    props: {
        contractObject: {
            type: Object,
            default: null
        },
    },
    data: function () {
        return {
            loading: false,
            contract: null,
            importedContractData: [],
            selectedOrders: [],
            priceLists: [],
            newList: null,
            selectedCheckboxes: {},
            selectedTaskTypes: []
        }
    },
    watch: {
        selectedTaskTypes: function (newVal) {
            // Filter out any IDs in selectedOrders that are not present in selectedTaskTypes
            this.selectedOrders = this.selectedOrders.filter(orderId => newVal.includes(orderId));
        }
    },

    mounted() {
        this.fetchImportedContractData()
        this.fetchPriceLists()
        this.newList = this.newPriceList()
    },

    methods: {
        fetchPriceLists: function () {
            this.loading = true
            this.restFetch(this.priceListUrl,this.handlePriceListResponse)
        },
        handlePriceListResponse: function (response) {
            this.loading = false
            this.priceLists = response.data
        },
        priceListAdded: function (newPriceList) {
            this.priceList = newPriceList.id
            this.fetchPriceLists()
            this.showPriceListEditor = false
            this.newList = this.newPriceList()
            this.$refs.pricelistModal.hide()
        },
        showPriceListEditor: function () {
            this.$refs.pricelistModal.show()
        },
        selectionValidated(object) {
            for (const key in object) {
                if (object[key] === true) {
                    if (key === 'open_orders' && object['task_types'] !== true) {
                        return false;
                    }
                    return true;
                }
            }
            return false;
        },
        isTaskTypeSelected: function (id) {
            return this.selectedTaskTypes.find(item => item === id) !== undefined
        },
        isOrderSelected: function (id) {
            return this.selectedOrders.find(item => item === id) !== undefined
        },
        addToSelection(item) {
            let index = this.selectedTaskTypes.findIndex(selectedItem => selectedItem === item);
            if (index < 0) {
                this.selectedTaskTypes.push(item);
            } else {
                this.selectedTaskTypes.splice(index, 1);
            }
        },
        addToOrderSelection(item) {
            if(this.selectedTaskTypes.findIndex(selectedItem => selectedItem === item) < 0) {
                return
            }
            let index = this.selectedOrders.findIndex(selectedItem => selectedItem === item);
            if (index < 0) {
                this.selectedOrders.push(item);
            } else {
                this.selectedOrders.splice(index, 1);
            }
        },
        fetchImportedContractData() {
            this.loading = true
            this.restFetchParams(this.contractImportUrl, {contract: this.contractObject.id}, this.handleResponse, this.fail)
        },
        handleResponse(response) {
            this.loading = false
            if(response.data) {
                if(response.data.orders && response.data.orders.length > 0) {
                    response.data.orders.map(order => {
                        if(order.hasOwnProperty('price_list')) {
                            order.price_list = null
                        }
                    })
                }
                this.importedContractData = response.data
            }

        },
        setImportedContractData() {
            this.loading = true
            let json = this.setValues()
            this.restUpdate(this.contractImportUrl, json , this.handleSetImportedContractData, this.setImportedContractDataFail)
        },
        handleSetImportedContractData() {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.alert_update_success'), 'success')
            this.$emit('closeSuccessModal')
        },
        setValues() {
            let contractChanges = {}
            if(this.importedContractData.name && this.selectedCheckboxes['name']) {
                contractChanges.name = this.importedContractData.name
            }
            if(this.importedContractData.start_date && this.selectedCheckboxes['scheduledPeriods']) {
                contractChanges.start_date = this.importedContractData.start_date
            }
            if(this.importedContractData.end_date && this.selectedCheckboxes['scheduledPeriods']) {
                contractChanges.end_date = this.importedContractData.end_date
            }
            if(this.selectedCheckboxes['task_types']) {
                contractChanges.task_types = this.selectedTaskTypes
            }
            if(this.selectedCheckboxes['open_orders']) {
                contractChanges.orders = this.importedContractData.orders
                    .filter(order => this.selectedOrders.includes(order.contract_task_type.task_type.id));
            }
            return {
                contract: this.contractObject.id,
                contract_changes: contractChanges
            }
        },
        setImportedContractDataFail() {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('contracts.imported_contract_data_update_failed'))
        },
        fail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('contracts.imported_contract_data_fetching_failed'))
        },
    }
}
</script>
