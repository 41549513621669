<template>
    <div>
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
        >
            <div class="header">
                <span class="title">
                  {{ $t('spreader_data.generation_title') }}
                </span>
            </div>
        </b-navbar>
        <b-form
            class="no-margin"
            @submit.prevent="generateSpreaderData"
            @reset.prevent="onReset"
        >
            <b-row>
                <div class="col-sm-6 pl-2 pr-2">
                    <span class="span-title">
                      {{ $t('ins_report.from_date') }}
                    </span>
                    <datetime
                        v-model="fromDate"
                        type="datetime"
                        format="dd.MM.yyyy HH:mm"
                        class="routa-input"
                        :placeholder="$t('observations_videos.search_input_format')"
                    />
                </div>

                <div class="col-sm-6 pl-2 pr-2">
                    <span class="span-title">
                      {{ $t('ins_report.to_date') }}
                    </span>
                    <datetime
                        v-model="toDate"
                        type="datetime"
                        format="dd.MM.yyyy HH:mm"
                        class="routa-input"
                        :placeholder="$t('observations_videos.search_input_format')"
                    />
                </div>
            </b-row>
            <b-row>
                <div class="col-sm-6 nopads pl-1 pr-2">
                    <span class="span-title">
                      {{ $t('users.company') }}
                    </span>
                    <b-form-select
                        id="customer"
                        v-model="company"
                        size="sm"
                        class="mb-3"
                    >
                        <template slot="first">
                            <option
                                :value="null"
                                disabled
                            >
                                {{ $t('users.select_company') }}
                            </option>
                        </template>
                        <option
                            v-for="c in companies"
                            :key="c.id"
                            :value="c.id"
                        >
                            {{ c.name }}
                        </option>
                    </b-form-select>
                </div>
            </b-row>
            <div class="col-sm-12 button-container">
                <b-button
                    variant="danger"
                    class="result-button"
                    :disabled="!fromDate||!toDate||loading||!company"
                    @click.stop="removerVisible = true"
                >
                    {{ $t('spreader_data.remove_data') }}
                </b-button>
                <b-button
                    type="reset"
                    variant="primary"
                    class="result-button"
                >
                    {{ $t('ins_report.clear') }}
                </b-button>
                <b-button
                    type="submit"
                    variant="success"
                    class="result-button"
                    :disabled="!fromDate||!toDate||loading"
                >
                    {{ $t('daily_summary.generate') }}
                </b-button>
            </div>
        </b-form>

        <!-- Harja removal modal -->
        <b-modal
            v-model="removerVisible"
            :hide-footer="true"
            :hide-header-close="true"
            :title="$t('spreader_data.remove_external')"
        >
            <b-row class="col-12 mb-2">
                <span>{{ $t('spreader_data.remove_all_text')}}</span>
            </b-row>
            <div class="col-12 text-right">
                <b-button
                    variant="secondary"
                    class="form-button"
                    @click="removerVisible = false">
                    {{ $t('common.cancel')}}
                </b-button>
                <b-button
                    variant="danger"
                    class="form-button"
                    @click="submitRemoval">
                    {{ $t('spreader_data.remove_data')}}
                </b-button>
            </div>

        </b-modal>


        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import {timeUtils} from '../../mixins/TimeUtils'
import {EventBus} from '@/event-bus'

export default {
    name: 'spreader-data-generator',
    mixins: [restApi, timeUtils],
    data: function () {
        return {
            loading: false,
            fromDate: null,
            toDate: null,
            company: null,
            removerVisible: false,
            companies: []
        }
    },

    mounted() {
        this.fetchCompanies()
    },

    methods: {
        async fetchCompanies() {
            this.loading = true
            const { err, ...response } = await this.axios.get(this.companyUrl).catch((err) => ({err}));
            this.loading = false
            if (err) {
                // Do something
                return;
            }
            this.companies = response.data;
        },
        generateSpreaderData: function () {
            let params = {}
            if (this.fromDate) {
                params.from = this.fromDate
            }
            if (this.toDate) {
                params.to = this.toDate
            }
            if (this.fromDate > this.toDate) {
                EventBus.$emit('show-alert', this.$t('daily_summary.invalid_date'))
                return undefined
            }
            if (this.company) {
                params.company = this.company
            }
            this.loading = true
            this.restFetchParams(this.generateSpreaderDataUrl, params, this.success, this.fail)
        },
        success: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('spreader_data.generation_success'), 'success')
            this.$emit("closeModal")
        },
        fail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('spreader_data.generation_fail'))
        },
        onReset: function () {
            this.fromDate = null
            this.toDate = null
            this.company = null
        },
        submitRemoval() {
            let params = {}
            if (this.fromDate) {
                params.from = this.fromDate
            }
            if (this.toDate) {
                params.to = this.toDate
            }
            if (this.fromDate > this.toDate) {
                EventBus.$emit('show-alert', this.$t('daily_summary.invalid_date'))
                return undefined
            }
            if (this.company) {
                params.company = this.company
            }
            this.loading = true
            this.removerVisible = false
            this.restAdd(this.removeSpreaderDataUrl, params, this.removeSuccess, this.removeFail)
        },
        removeSuccess: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('spreader_data.removal_success'), 'success')
            this.$emit("closeModal")
        },
        removeFail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('spreader_data.removal_error'))
        }
    }
}
</script>
