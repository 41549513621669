import { render, staticRenderFns } from "./DutyOfficerScheduleCalendarItem.vue?vue&type=template&id=3794faf6&scoped=true"
import script from "./DutyOfficerScheduleCalendarItem.vue?vue&type=script&lang=js"
export * from "./DutyOfficerScheduleCalendarItem.vue?vue&type=script&lang=js"
import style0 from "./DutyOfficerScheduleCalendarItem.vue?vue&type=style&index=0&id=3794faf6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3794faf6",
  null
  
)

export default component.exports