<template>
    <div class="w-100">
    <div class="map-item-filter">
        <div
            class="col-sm-12 nopads vehicle-list"
        >
            <span
            class="vehicle-list-title"
            @click.stop="toggleList">
            {{ $t('vehicle_list.title') }} ({{ logEntries ? logEntries.length : 0 }})
        </span>
            <div
                class="vehicle-list-caret"
                v-bind:class="{ caret_open: listOpen }"
                @click.stop="toggleList">
                <font-awesome-icon icon="caret-down"/>
            </div>
            <!-- Accordion content -->
            <div
                class="col-sm-12 nopads map-accordion-content"
                v-bind:class="{'map-accordion-open': listOpen}"
            >
                <div v-bind:class="{ 'm-2': listOpen }" class="nopads">
                    <b-form-checkbox
                        v-if="!isMapViewer"
                        v-model="showLicensePlate"
                        size="sm"
                        class="ml-1 mr-1 mb-2 text-light">
                        {{ $t('map.show_license_plate') }}
                    </b-form-checkbox>
                    <hr class="nopads pl-2 pr-2 mb-2" style="border-color: #5d6754">
                    <div class="col-12 nopads text-center">
                        <div class="badge-filters__wrapper">
                            <div class="badge-filters" id="badge-filters">
                                <span id="disabled-wrapper" class="d-inline-block" tabindex="0" v-for="item in timeFilterOptions" :key="item.value" v-b-tooltip :title="isDisabled(item) ? $t('vehicle_list.select_contract_filter') : ''">
                                    <b-badge
                                        :key="item.value"
                                        @click="!isDisabled(item) && (historyMode = item.value)"
                                        :disabled="isDisabled(item)"
                                        :style="isDisabled(item) ? 'pointer-events: none;' : ' '"
                                        :variant="isDisabled(item) ? 'secondary' : (historyMode === item.value ? 'primary' : 'light')"
                                        class="badge-item"
                                        id="badge-filter"
                                        :class="{ 'disabled-badge': isDisabled(item) }"
                                    >
                                        {{ item.text }}
                                    </b-badge>
                                </span>

                            </div>
                        </div>
                    </div>

                    <span v-if="historyMode === historyModes.SLIDER"
                          class="vehicle-list-no-results nopads">{{ historyValue + ' ' + 'h'}}</span>

                    <!-- History Slider -->
                    <b-form-input
                        v-if="historyMode === historyModes.SLIDER"
                        id="range"
                        v-model="historyValue"
                        type="range"
                        class="mb-2"
                        :min="minHours"
                        :max="maxHours"
                        @change="updateHistoryValues"
                    />
                    <!-- Date Inputs for fromDate and toDate -->
                    <b-row v-if="historyMode === historyModes.USER_INPUT" class="nopads calendar-container pb-2" v-bind:class="{ 'calendar-open': calendarOpen }">
                        <div class="col-sm-5 nopads pr-1">
                            <span class="search-title white-text">{{ $t('trip_list.road_sec_from') }}</span>
                            <div class="nopads" @click="openCalendar">
                                <datetime
                                    v-model="fromDate"
                                    type="datetime"
                                    format="dd.MM.yyyy HH:mm"
                                    class="routa-input"
                                    :placeholder="$t('observations_videos.search_input_format')"
                                />
                            </div>
                        </div>
                        <div class="col-sm-5 nopads pr-1">
                            <span class="search-title white-text">{{ $t('trip_list.road_sec_to') }}</span>
                            <div class="nopads" @click="openCalendar">
                                <datetime
                                    v-model="toDate"
                                    type="datetime"
                                    format="dd.MM.yyyy HH:mm"
                                    class="routa-input"
                                    :placeholder="$t('observations_videos.search_input_format')"
                                />
                            </div>
                        </div>
                        <div class="col-sm-2 nopads mt-4 text-center">
                            <b-button
                                variant="success"
                                size="md"
                                :disabled="!this.fromDate || !this.toDate"
                                @click.stop="updateHistoryValues"
                            >
                                <span class="map-button-text">{{ $t('ins_report.search') }}</span>
                            </b-button>
                        </div>
                    </b-row>
                    <hr class="nopads pl-2 pr-2 mb-2 " style="border-color: #5d6754">
                    <div class="col-12 nopads text-center">
                        <div class="badge-filters__wrapper">
                            <div class="badge-filters">
                                <b-badge
                                    v-for="item in roadOwnerOptions"
                                    :key="item.value"
                                    @click="roadOwnership = item.value"
                                    :variant="roadOwnership === item.value ? 'primary' : 'light'"
                                    class="badge-item"
                                >
                                {{ item.text }}
                                </b-badge>
                            </div>
                        </div>
                    </div>
                    <hr class="nopads pl-2 pr-2 mb-2" style="border-color: #5d6754">

                    <!-- vehicle list -->
                <div class="col-sm-12 nopads vehicle-list-container vehicle-list-smaller-max-height"
                    v-if="logEntries && logEntries.length > 0 && listOpen">
                    <div
                        v-for="item of logEntries"
                        :key="item.id"
                        class="vehicle-list-item"
                        @click.stop="showVehicleInfo(item.vehicle.id)"
                    >
                        <b-row class="nopads">
                            <div class="vehicle-list-icon">
                                <font-awesome-icon
                                    :icon="getIcon(item.vehicle.vehicle_type)"
                                    v-bind:class="{ fresh: isActive(item) }"/>
                            </div>
                            <div v-if="!isMapViewer" class="vehicle-list-header">{{ item.vehicle.license_plate }}</div>
                        </b-row>
                        <div v-if="!isMapViewer">{{ item.vehicle.make }} {{ item.vehicle.vehicle_model }}</div>
                        <div v-else>{{ getVehicleTypeName(item.vehicle.vehicle_type) }}</div>
                        <div v-if="isObserver" class="vehicle-list-company">{{ item.vehicle.company.name }}</div>
                        <b-row v-if="item.routes && item.routes.length > 0" class="nopads">
                            <div
                                v-if="item.routes[0].contract"
                                class="col-6 vehicle-list-task word-break"
                            >
                                {{ item.routes[0].contract }}
                            </div>
                            <div
                                v-if="item.routes[0].order"
                                class="col-6 vehicle-list-task"
                            >
                                <div class="task-type-circle"
                                        v-bind:style="{ backgroundColor: getTraceColor(item.routes[0].mode, item.routes[0].trace_color) }"/>
                                {{ item.routes[0].order }}
                            </div>
                        </b-row>
                        <div v-else class="vehicle-list-task">{{ $t('vehicle_list.no_contract') }}</div>
                    </div>
                </div>
                <span v-else-if="!logEntries || logEntries.length < 1" class="vehicle-list-no-results">{{ $t('vehicle_list.no_results') }}</span>
            </div>
        </div>
    </div>

    </div>
    <div
        v-if="loading"
        id="loader"
        class="spinner"
    />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {logModes, vehicleHelper} from '../mixins/VehicleMixin'
import {timeUtils} from '../mixins/TimeUtils'
import {mapHelper} from '../mixins/MapMixin'
import {mapActions, mapState} from "vuex";

export const historyModes = {
    NO_HISTORY: null,
    SLIDER: 1,
    USER_INPUT: 2,
    NO_LIMIT: 3,
}

export default {
    name: 'MapVehicles',
    mixins: [restApi, vehicleHelper, timeUtils, mapHelper],
    props: {
        map: {
            type: Object,
            default: null
        },
        isAdmin: Boolean,
        isObserver: Boolean,
        isMapViewer: Boolean,
        showHistory: {
            type: Boolean,
            default: false
        },
        hours: {
            type: Number,
            default: 6
        },
        roadOwner: {
            type: Number,
            default: null
        }
    },
    data: function () {
        return {
            minHours: 1,
            maxHours: 24,
            historyValue: 2,
            fromDate: null,
            toDate: null,
            loading: false,
            listOpen: false,
            calendarOpen: false,
            infoVehicle: -1,
            logEntries: [],
            polyLines: [],
            contracts: [],
            historyModes: historyModes,
            logModes: logModes,
            timer: null,
            destroyed: false,
            fetching: false,
            selectedTaskTypeValue: null,
            historyMode: null,
            showHistoryValue: false,
            showLicensePlate: false,
            roadOwnership: null,
        }
    },
    computed: {
        ...mapState({
            selectedContractId: (state) => state.contracts.selectedContractId,
            selectedContractIds: (state) => state.contracts.selectedContractIds,
            selectedContract: (state) => state.contracts.selectedContract,
            selectedTaskType: (state) => state.contracts.selectedTaskType,
            selectedTaskTypes: (state) => state.contracts.selectedTaskTypes,
            selectedOrder: (state) => state.contracts.selectedOrder,
            isLoadingContracts: (state) => state.contracts.isLoading,
            selectedHistoryValue: (state)=> state.contracts.historyHours,
            selectedHistoryMode: (state) => state.contracts.historyMode
        }),
        tableFields() {
            if (this.isObserver) {
                return [
                    {
                        vehicle: {
                            label: this.$t('vehicle_position.vehicle'),
                            sortable: false
                        }
                    }
                    ,
                    {
                        time: {
                            label: this.$t('vehicle_position.time'),
                            sortable: false
                        }
                    },
                    {
                        mode: {
                            label: this.$t('vehicle_position.mode'),
                            sortable: false
                        }
                    },
                    {
                        company: {
                            label: this.$t('vehicle_position.company'),
                            sortable: false
                        }
                    },
                    {
                        customer: {
                            label: this.$t('vehicle_position.customer'),
                            sortable: false
                        }
                    },
                    {
                        contract: {
                            label: this.$t('vehicle_position.contract'),
                            sortable: false
                        }
                    },
                    {
                        order: {
                            label: this.$t('vehicle_position.order'),
                            sortable: false
                        }
                    }
                ]
            }
            return [
                {
                    vehicle: {
                        label: this.$t('vehicle_position.vehicle'),
                        sortable: false
                    }
                }
                ,
                {
                    time: {
                        label: this.$t('vehicle_position.time'),
                        sortable: false
                    }
                },
                {
                    mode: {
                        label: this.$t('vehicle_position.mode'),
                        sortable: false
                    }
                },
                {
                    contract: {
                        label: this.$t('vehicle_position.contract'),
                        sortable: false
                    }
                },
                {
                    order: {
                        label: this.$t('vehicle_position.order'),
                        sortable: false
                    }
                }
            ]
        },
        timeFilterOptions() {
            return [
                {text: this.$t('common.no_history'), value: historyModes.NO_HISTORY},
                {text: this.$t('common.hour_range'), value: historyModes.SLIDER},
                {text: this.$t('common.time_range'), value: historyModes.USER_INPUT},
                {text: this.$t('common.no_limit'), value: historyModes.NO_LIMIT}
            ]
        },
        roadOwnerOptions() {
            return [
                { text: this.$t('common.all'), value: null },
                { text: this.$t('common.municipality'), value: 2 },
                { text: this.$t('common.government'), value: 1 },
                { text: this.$t('common.private'), value: 3 }
                /*{ text: this.$t('common.unknown'), value: 99 }*/
            ]
        },
        isDisabled() {
            return (item) => {
                return (
                    !this.selectedContractId &&
                    (!this.selectedContractIds || this.selectedContractIds.length < 1) &&
                    (item.value === historyModes.NO_LIMIT || item.value === historyModes.USER_INPUT)
                )
            }
        }
    },
    watch: {
        map() {
            this.$nextTick(function () {
            this.fetchVehicles()
          })
        },
        showLicensePlate(show) {
            this.toggleLicensePlates(show)
        },
        selectedContractIds() {
            this.hideVehicles()
            if (this.selectedContractIds.length < 1 && (this.historyMode === historyModes.NO_LIMIT || this.historyMode === historyModes.USER_INPUT)) {
                this.historyMode = historyModes.NO_HISTORY
            }
            this.updateHistoryValues()
            this.fetchVehicles()
        },
        selectedContractId() {
            this.hideVehicles()
            this.updateHistoryValues()
            this.fetchVehicles()
        },
        selectedTaskType() {
            this.hideVehicles()
            this.updateHistoryValues()
            this.fetchVehicles()
        },
        selectedTaskTypes() {
            this.hideVehicles()
            this.updateHistoryValues()
            this.fetchVehicles()
        },
        selectedOrder() {
            this.hideVehicles()
            this.updateHistoryValues()
            this.fetchVehicles()
        },
        roadOwnership() {
            this.updateHistoryValues()
        },
        historyMode() {
            if (this.historyMode !== historyModes.USER_INPUT) {
                this.updateHistoryValues()
            }
            // Save to map prefs, if mode is SLIDER
            if (this.historyMode === historyModes.SLIDER) {
                this.setHistoryMode(this.historyMode)
                this.setHistoryHours(this.historyValue)
            } else {
                this.setHistoryMode(historyModes.NO_HISTORY)
            }
        },
        fromDate() {
            this.setFromDate(this.fromDate)
            setTimeout(() => this.calendarOpen = false, 300);
        },
        toDate() {
            this.setToDate(this.toDate)
            setTimeout(() => this.calendarOpen = false, 300);
        }
    },
    mounted: function () {
        this.showHistoryValue = this.showHistory
        this.historyValue = this.hours;
        this.roadOwnership = this.roadOwner;
        this.historyMode = this.selectedHistoryMode
        this.historyValue = this.selectedHistoryValue
        this.fetchVehicles()
        this.fetchContracts()
    },
    beforeDestroy: function () {
        this.destroyed = true
        if (this.timer) {
            clearTimeout(this.timer)
        }
        this.hideVehicles()
    },
    methods: {
        ...mapActions('contracts', ['selectTaskType', 'setFromDate', 'setToDate', 'setHistoryMode', 'setHistoryHours']),

        openCalendar() {
            this.calendarOpen = true
        },

        fetchVehicles: function () {
            if (!this.fetching) {
                let params = {}
                this.fetching = true
                if (this.timer) {
                    clearTimeout(this.timer)
                } else {
                    this.loading = true
                }
                if(this.selectedContractIds) {
                    params.contract_id = this.selectedContractIds
                }else if(this.selectedContractId) {
                    params.contract_id = this.selectedContractId
                }
                if(this.selectedOrder) {
                    params.order = this.selectedOrder
                } else if (this.selectedTaskType) {
                    params.taskType = this.selectedTaskType
                } else if (this.selectedTaskTypes && this.selectedTaskTypes.length > 0) {
                    params.taskTypes = this.selectedTaskTypes.map(taskType => taskType.value);
                }
                this.restFetchParams(this.lastPositionUrl, params, this.handleResponse)
            }
        },

        fetchContracts: function() {
            this.loading = true
            this.restFetch(this.contractUrl, this.handleContracts)
        },

        handleContracts: function (response) {
            this.contracts = response.data
            this.loading = false
        },

        handleResponse: function (response) {
            this.loading = false
            this.fetching = false
            if (response) {
                this.logEntries = response.data
                this.showVehicles()
                if (this.infoVehicle > 0) {
                    this.showVehicleInfo(this.infoVehicle)
                }
            }
            if (!this.destroyed) {
                this.timer = setTimeout(this.fetchVehicles, 30000)
            }
        },

        updateHistoryValues() {
            let from, to
            if(this.historyMode === historyModes.SLIDER) {
                let startTime = (new Date()).getTime() - (this.historyValue * 60 * 60 * 1000)
                from = (new Date(startTime)).toISOString()
            } else if (this.historyMode === historyModes.USER_INPUT) {
                from = this.fromDate
                to = this.toDate
            }
            this.$emit('historyChange', this.historyMode, from, to, this.roadOwnership)
        },

        toggleLicensePlates(show = false) {
            if (this.map) {
                this.logEntries.forEach(item => {
                    if (show) {
                        if (item.routes && item.routes.length > 0) {
                            const vehicle = item.vehicle;
                            const latest = item.routes[0];
                            this.map.addVehicleLabel(vehicle.id, vehicle.license_plate, latest.points[0].y, latest.points[0].x)
                        }
                    }
                    else {
                        this.map.hideVehicleLabelIfExists(item.vehicle.id)
                    }
                });
            }
        },


      hideVehicles: function () {
        if (this.timer) {
          clearTimeout(this.timer)
        }
        if (this.map) {
          this.logEntries.forEach(item => {
            this.map.removeMapMarker(item.vehicle.id, this.VEHICLE);
            this.map.hideVehicleLabelIfExists(item.vehicle.id)
          })
          this.polyLines.forEach(line => {
            this.map.removePolyline(line, this.TRACE)
          })
          this.hideVehicleInfo()
          this.hideTraceInfo()
        }
      },

        getFreshLimit: function () {
            return Date.now() - 1000 * 60 * 5 // 5 minute old vehicle entries shown as active, older as passive
        },

        showVehicles: function () {
            var freshLimit = this.getFreshLimit()
            // One 'logEntry' is an object containing information about the vehicle and it's routes
            this.logEntries.forEach(item => {
                if (item.routes && item.routes.length > 0) {
                    // Draw marker for latest point
                    var vehicle = item.vehicle;
                    var latest = item.routes[0]
                    latest.endTime = this.setTime(latest.endTime)
                    var fresh = Date.parse(latest.endTime) > freshLimit
                    var marker = this.getVehicleMarker(vehicle.vehicle_type, fresh, this.map.getMarkerStore())
                    this.map.showMapMarker(vehicle.id, this.VEHICLE, latest.points[0].y, latest.points[0].x, marker)
                    if (this.showLicensePlate) {
                        this.map.addVehicleLabel(vehicle.id, vehicle.license_plate, latest.points[0].y, latest.points[0].x)
                    }
                    item.routes.forEach(log => {
                        if (log.points.length > 1) {
                            this.drawVehicleTrace(log.id, log.points, log.mode, log.trace_color)
                            this.polyLines.push(log.id)
                        }
                    })
                }
            })
        },

        drawVehicleTrace: function (id, coords, mode, color) {
            var dash = false
            switch (mode) {
                case logModes.WORK:
                    if (!color) {
                        color = '#00FF21'
                    }
                    break
                case logModes.PROGRAM:
                    color = '#2121FF'
                    break
                case logModes.RESETTLE:
                    color = '#89898a'
                    dash = true
                    break
            }
            this.map.drawPolyLine(id, this.TRACE, coords, color, dash)
        },

        onMarkerTap: function (data) {
            if (data.id === this.infoVehicle) {
                this.hideVehicleInfo()
            } else {
                this.showVehicleInfo(data.id)
            }
        },

        onPolylineTap: function (data) {
            if (data.type && data.type === this.TRACE) {
                if (data.id === this.infoTrace) {
                    this.hideTraceInfo()
                } else {
                    this.showTraceInfo(data.id)
                }
            }
        },

        showTraceInfo: function (id) {
            this.hideVehicleInfo()
            var trace = null
            let vehicle
            for (let i = 0; i < this.logEntries.length; i++) {
                let item = this.logEntries[i]
                trace = item.routes.find(route => route.id === Number(id))
                if (trace) {
                    vehicle = item.vehicle
                    break
                }
            }
            if (!trace) {
                return
            }
            this.infoTrace = id
            this.map.showMapInfo(this.tableFields, this.getInfoContent(vehicle, trace), false)
        },

        hideTraceInfo: function () {
            if (this.infoTrace) {
                this.map.hideMapInfo()
                this.infoTrace = -1
            }
        },

        onMapInfoClosed: function () {
            this.infoVehicle = -1
            this.infoTrace = -1
        },

        showVehicleInfo: function (id) {
            this.hideTraceInfo()
            var item = this.logEntries.find(item => item.vehicle.id === id)
            if (!item || !item.routes) {
                return
            }
            this.infoVehicle = id
            this.map.showMapInfo(this.tableFields, this.getInfoContent(item.vehicle, item.routes[0]), false)
            this.map.zoomToPosition(item.routes[0].points[0].y, item.routes[0].points[0].x)
        },

        hideVehicleInfo: function () {
            if (this.infoVehicle > 0) {
                this.map.hideMapInfo()
                this.infoVehicle = -1
            }
        },

        getVehicleStr: function (vehicle) {
            var result = ''
            if (vehicle.make) {
                result += vehicle.make + ' '
            }
            if (vehicle.vehicle_model) {
                result += vehicle.vehicle_model + ' '
            }
            if (vehicle.license_plate) {
                result += vehicle.license_plate
            }
            return result
        },

        getInfoContent: function (vehicle, route) {
            if (this.isObserver) {
                return [{
                    vehicle: this.getVehicleStr(vehicle),
                    time: route.startTime && route.endTime ? this.$d(new Date(this.setTime(route.startTime)), 'short') + ' - ' +
                        this.$d(new Date(this.setTime(route.endTime)), 'short') : '-',
                    mode: route.mode ? this.getTripModeString(route.mode) : '-',
                    company: vehicle.company.name ? vehicle.company.name : '-',
                    customer: route.customer ? route.customer : '-',
                    contract: route.contract ? route.contract : '-',
                    order: route.order ? route.order : '-'
                }]
            }
            // Show less for map viewer
            return [{
                vehicle: this.getVehicleTypeName(vehicle.vehicle_type),
                time: route.startTime && route.endTime ? this.$d(new Date(this.setTime(route.startTime)), 'short') + ' - ' +
                    this.$d(new Date(this.setTime(route.endTime)), 'short') : '-',
                mode: route.mode ? this.getTripModeString(route.mode) : '-',
                contract: route.contract ? route.contract : '-',
                order: route.order ? route.order : '-'
            }]

        },

        getLocationStr: function (entry) {
            var result = ''
            if (entry.road_num) {
                result += entry.road_num + ' '
            }
            if (entry.sec_num) {
                result += '/ ' + entry.sec_num + ' '
            }
            if (entry.road_name) {
                result += entry.road_name + ' '
            }
            if (entry.city) {
                result += entry.city.name
            }
            return result
        },

        isActive: function (item) {
            if (item && item.routes && item.routes.length > 0) {
                return Date.parse(item.routes[0].endTime) > this.getFreshLimit()
            }
            return false
        },

        toggleList: function () {
            this.listOpen = !this.listOpen
        },

        getTraceColor: function (mode, color) {
            if (mode === logModes.WORK) {
                return color
            }
            return '#bcbcbc'
        },
    }
}
</script>
<style>
.badge-item {
    min-width: 90px;
    max-width: 110px;
    padding: .5em 1em;
    text-align: center;
    display: inline-block;
}
.disabled-badge {
    pointer-events: none;
    opacity: 0.6;
    cursor: not-allowed;
}

.calendar-container {
    transition: margin 0.3s ease;
}

.calendar-open {
    margin-bottom: 15em;
    z-index: 999;
}
</style>
