<template>
    <div class="col-sm-12">
        <div
            class="data-list-item add"
            @click.stop="$emit('add')"
        >
            <div class="item-img-cont add-title">
                <i class="fa fa-plus"/>
            </div>
            <div class="item-text-cont">
        <span class="item-title-add">
          {{ $t('common.add_new') }}
        </span>
                <font-awesome-icon
                    icon="hands-helping"
                    class="card-body-icon"
                />
            </div>
        </div>

        <transition name="fade">
            <div
                class="col-sm-12 pb-2 result-table"
            >
                <b-table
                    :small="true"
                    :responsive="true"
                    :items="results"
                    :fields="fields"
                    :bordered="true"
                    :sort-compare="sortCompare"
                    :sort-compare-locale="$i18n.locale"
                >
                    <template slot="empty">
                        <div class="text-center my-2">
                            {{ $t('area_report.no_results') }}
                        </div>
                    </template>
                    <template v-slot:cell(customer)="row">
                       {{ row.item.customer.name }}
                    </template>
                    <template v-slot:cell(contractor)="row">
                        {{ row.item.contractor.name }}
                    </template>
                    <template v-slot:cell(remove)="row">
                        <div class="col-12 nopads text-center">
                            <b-tooltip :target="row.item.customer.id + '_' + row.item.contractor.id" triggers="hover" placement="top">
                                {{ $t('common.remove') }}
                            </b-tooltip>
                            <font-awesome-icon
                                :id="row.item.customer.id + '_' + row.item.contractor.id"
                                icon="trash"
                                class="ml-1 mr-1 mt-1 pointer"
                                style="color:#9e0c0c;"
                                @click="deleteRelationship(row.item)"
                            />
                        </div>
                    </template>
                </b-table>
            </div>
        </transition>
    </div>
</template>

<script>
import {workManagementHelper} from '../../mixins/WorkManagementMixin'
import {restApi} from '../../mixins/RestApiMixin'
import {EventBus} from '@/event-bus'

export default {
    name: 'CompanyRelationshipList',
    mixins: [workManagementHelper, restApi],
    props: {
        results: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data: function () {
        return {
            loading: false,
            fields: [
                {key: 'customer', label: 'Id', sortable: true},
                {key: 'contractor', label: this.$t('companies.name'), sortable: true},
                {key: 'remove', label: '', sortable: false},
            ]
        }
    },
    methods: {
        deleteRelationship: function (relationship) {
            // TODO - Confirmation dialog!
            if (relationship) {
                var url = this.companyRelationshipUrl + '?customer=' + relationship.customer.id + '&contractor=' + relationship.contractor.id
                this.restDelete(url, this.success, this.fail)
            }
        },

        success: function () {
            this.loading = true
            this.$emit('update')
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        },
        sortCompare(aRow, bRow, key) {
            if (key === 'customer') {
                const a = aRow['customer']
                const b = bRow['customer']
                return a.name < b.name ? -1 : b.name < a.name ? 1 : 0
            }
            if (key === 'contractor') {
                const a = aRow['contractor']
                const b = bRow['contractor']
                return a.name < b.name ? -1 : b.name < a.name ? 1 : 0
            }
            return undefined;
        }

    }
}
</script>
