<template>
    <div class="col-sm-12 nopads">
        <div class="search-form">
            <div class="col-sm-12 search-form-title">
                <font-awesome-icon icon="search"/>
                {{ $t('common.search') }}
            </div>
            <b-form
                class="no-margin"
                @submit.prevent="onSubmit"
                @reset.prevent="onReset"
            >
                <b-row class="nopads">
                    <div class="col-sm-6 pl-2 pr-2">
                        <span class="search-title">
                          {{ $t('ins_report.from_date') }}
                        </span>
                        <datetime
                            v-model="fromDate"
                            type="datetime"
                            format="dd.MM.yyyy HH:mm"
                            class="routa-input"
                            :placeholder="$t('observations_videos.search_input_format')"
                        />
                    </div>

                    <div class="col-sm-6 pl-2 pr-2">
                        <span class="search-title">
                          {{ $t('ins_report.to_date') }}
                        </span>
                        <datetime
                            v-model="toDate"
                            type="datetime"
                            format="dd.MM.yyyy HH:mm"
                            class="routa-input"
                            :placeholder="$t('observations_videos.search_input_format')"
                        />
                    </div>
                </b-row>
                <b-row class="nopads p-2 mr-3">
                    <area-filter
                        ref="areaFilter"
                        :is-admin="false"
                        :no-geom="true"
                        :multiple="true"
                        @areaSet="setArea"
                        @areasSet="setAreas"
                    />
                </b-row>
                <div class="nopads ml-1 mr-1">
                    <div class="col-sm-6 nopads">
                        <open-contract-filter
                            :to="toDate"
                            :from="fromDate"
                            :show-company="false"
                            :showOnlyMyContractsOption="true"
                            @loading="loading=true"
                            @loadingCompleted="loading=false"
                            @contractSelected="setContract"
                        />
                    </div>
                </div>
                <div class="col-sm-12 button-container">
                    <b-button
                        type="submit"
                        variant="primary"
                        class="result-button"
                    >
                        {{ $t('ins_report.search') }}
                    </b-button>
                </div>
            </b-form>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import AreaFilter from '../AreaFilter'
import {timeUtils} from "../../mixins/TimeUtils";
import OpenContractFilter from "@/components/contract/OpenContractFilter";

export default {
    name: 'AreaWorkFilter',
    components: {
        OpenContractFilter,
        AreaFilter,
    },
    mixins: [restApi, timeUtils],
    props: {
        user: {
            type: Object,
            default: null
        },
        isAdmin: Boolean
    },
    data: function () {
        return {
            loading: false,
            name: null,
            area: null,
            areas: [],
            contract: null,
            fromDate: null,
            toDate: null
        }
    },

    mounted: function () {
        if (!this.fromDate) {
            this.fromDate = this.getMonthStart()
        }
    },

    methods: {
        onReset: function () {
            this.name = null
            this.area = null
            this.areas = []
            this.company = null
            this.contract = null
            this.$refs.areaFilter.resetData()
        },
        onSubmit: function () {
            var params = {}
            if (this.area) {
                params.area = this.area.id
            }
            if(this.areas && this.areas.length > 0) {
                params.areas = this.areas.map(item => item.id)
            }
            params.from = this.fromDate
            if (this.toDate) {
                params.to = this.toDate
            }
            if(this.contract) {
                params.contract = this.contract
            }
            this.$emit('search', params)
        },

        setArea: function (area) {
            this.area = area
        },

        setAreas: function (areas) {
            this.areas = areas
        },

        setContract: function (contract) {
            this.contract = contract ? contract.id : null
        },

    }
}
</script>

<style scoped>

</style>
