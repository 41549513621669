<template>
<div>
    <b-row>
        <span class="span-header">{{ $t('comments.title') }}</span>
        <b-col class="nopads">
            <font-awesome-icon
                icon="arrow-down"
                class="pointer right no-focus arrow-icon dont-print"
                v-bind:class="{rotated : commentsVisible}"
                v-b-toggle.commentDetails
                @click.stop="commentsVisible = !commentsVisible"/>
        </b-col>
    </b-row>
    <b-collapse
        id="commentDetails"
        class="mt-2 mb-2 item-details"
    >
        <span class="p-1" v-if="comments.length < 1">{{ $t('comments.no_comments')}}</span>
        <comment
            v-for="comment in comments"
            v-bind:key="comment.id"
            :comment="comment"
            :user="user"/>
        <comment-editor
            :user="user"
            :topic="topic"
            :id="id"
            :type="type"
            @commentAdded="fetchComments"/>
        </b-collapse>
    <div
        v-if="loading"
        id="loader"
        class="spinner"
    />
</div>

</template>


<script>
import {restApi} from '../mixins/RestApiMixin'
import Comment from "@/components/comments/Comment";
import CommentEditor from "@/components/comments/CommentEditor";

export default {
    name: 'CommentContainer',
    components: {CommentEditor, Comment},
    mixins: [restApi],
    data: function () {
        return {
            commentsVisible: false,
            commentsFetched: false,
            comments: [],
            loading: false
        }
    },
    props: {
        user: {
            type: Object,
            required: true
        },
        topic: {
            type: Number,
            required: true
        },
        id: {
            type: Number,
            required: true
        },
        type: {
            type: Number,
            required: false
        }
    },
    watch: {
        commentsVisible(val) {
            if (val && !this.commentsFetched) {
                this.fetchComments()
            }
        }
    },

    methods: {

        fetchComments() {
            this.loading = true
            this.restFetchParams(this.commentUrl, {topic: this.topic, type: this.type, record_id: this.id},
                this.fetchSuccess, this.fetchFail)
        },

        fetchSuccess(response){
            this.loading = false
            this.commentsFetched = true
            if (response && response.data) {
                this.comments = response.data
            }
        },

        fetchFail() {
            this.loading = false
        }

    }
}
</script>
