import { PDFDocument } from 'pdf-lib';
import { PdfGeneratorService } from './PdfGeneratorService';

export class PdfGeneratorManager {
  constructor() {
    this.pdfGeneratorService = new PdfGeneratorService()
    this.embeddedImages = {
      footer: null
    }
  }

  /**
   * @returns {Promise<PDFDocument>} PDF document object
   */
  async createStandardRoutaReportPdf(addFooterLogo = true, landscape = false) {
    const portraitPage = [595, 842]
    const landscapePage = [842, 595]
    const pdfDoc = await PDFDocument.create()
    const page = pdfDoc.addPage(landscape ? landscapePage : portraitPage)
    if (addFooterLogo) {
      await PdfGeneratorService.cacheRoutaFooterLogo();
      const image = await PdfGeneratorService.embedImageToDoc(pdfDoc, PdfGeneratorService.routaLogoBytes);
      this.embeddedImages.footer = image
      this.pdfGeneratorService.addRoutaLogoToFooter(pdfDoc, page, image);
    }
    return pdfDoc
  }

  /**
   *
   * @param {PDFDocument} pdfDoc
   * @returns {Promise<ArrayBuffer>} PDF bytes
   */
  async generateBytesFromPdfDoc(pdfDoc) {
    const pdfBytes = await pdfDoc.save()
    return pdfBytes
  }

  /**
   *
   * @param {ArrayBuffer} pdfBytes PDF bytes data
   * @param {string} fileName Name of the pdf file
   */
  static downloadPdf(pdfBytes, fileName) {
    try {
      // Create a Blob from the PDF bytes
      const blob = new Blob([pdfBytes], { type: 'application/pdf' })

      // Create a Blob URL and open it in a new tab
      const blobUrl = URL.createObjectURL(blob)

      // To open the pdf in a new tab instead of downloading, use global window open method
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `${fileName}.pdf`;
      document.body.appendChild(link);
      link.click();

      setTimeout(() => {
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
      }, 200)
    } catch (e) {
      return
    }
  }
}
