import L from 'leaflet';
import 'protomaps-leaflet';
import {exp} from "protomaps-leaflet";

export const leafletVectorUtils = {

    methods: {
        createCustomProtomapsLayer(url, authHeader, options) {
            // Extend L.protomaps to create a custom layer
            const CustomProtomapsLayer =  L.protomaps.extend({
                createTile: function (coords, done) {
                    const tile = document.createElement('canvas');
                    tile.width = this.getTileSize().x;
                    tile.height = this.getTileSize().y;

                    const tileUrl = L.Util.template(url, coords);

                    // Custom XMLHttpRequest to add headers
                    const xhr = new XMLHttpRequest();
                    xhr.open('GET', tileUrl, true);
                    xhr.responseType = 'arraybuffer';
                    xhr.setRequestHeader('Authorization', authHeader);

                    xhr.onload = function () {
                        if (xhr.status >= 200 && xhr.status < 300) {
                             done(null, tile);
                        } else {
                            // eslint-disable-next-line
                            console.error(`Tile request failed: ${xhr.status}`);
                            done(new Error(`Tile request failed: ${xhr.statusText}`), tile);
                        }
                    }.bind(this);

                    xhr.onerror = function () {
                        // eslint-disable-next-line
                        console.error('Error loading tile');
                        done(new Error('Error loading tile'), tile);
                    };

                    xhr.send();

                    return tile;
                },
            })
            // Return an instance of the custom layer with the passed options
            return new CustomProtomapsLayer({
                ...options, // Merge provided options
            });
        },

        /* eslint-disable*/
        getPaintRules() {
            return [
                // WATER
                {
                    dataLayer: "water",
                    symbolizer: new protomapsL.PolygonSymbolizer({
                        fill: "#BEE4FD",
                        stroke: "#A6D8E8",
                        opacity: 0.8,
                        width: 2
                    })
                },
                // BUILDING
                {
                    dataLayer: "building",
                    symbolizer: new protomapsL.PolygonSymbolizer({
                        fill: "#eeebdb",
                        stroke: "#c9c6ba",
                        opacity: 1,
                        width: 1
                    })
                },
                // LANDUSE
                {
                    dataLayer: "landuse",
                    symbolizer: new protomapsL.PolygonSymbolizer({
                        fill: "#fcf5e1",
                        opacity: 0.0
                    }),
                    filter: (z, f) => {
                        const kind = f.props.class ? f.props.class : "";
                        return ["residential"].includes(kind);
                    },
                },
                {
                    dataLayer: "landuse",
                    symbolizer: new protomapsL.PolygonSymbolizer({
                        fill: "#f3fca2",
                        opacity: 0.6
                    }),
                    filter: (z, f) => {
                        const kind = f.props.class ? f.props.class : "";
                        return ["farmland"].includes(kind);
                    },
                },
                {
                    dataLayer: "landuse",
                    symbolizer: new protomapsL.PolygonSymbolizer({
                        fill: "#ebf8d1",
                        opacity: 0.6
                    }),
                    filter: (z, f) => {
                        const kind = f.props.class ? f.props.class : "";
                        return ["park", "pitch"].includes(kind);
                    },
                },
                {
                    dataLayer: "landuse",
                    symbolizer: new protomapsL.PolygonSymbolizer({
                        fill: "#EAF2F2",
                        opacity: 0.3
                    }),
                    filter: (z, f) => {
                        const kind = f.props.class ? f.props.class : "";
                        return ["school"].includes(kind);
                    },
                },
                {
                    dataLayer: "landuse",
                    symbolizer: new protomapsL.PolygonSymbolizer({
                        fill: "#f3f2f1",
                        opacity: 0.6
                    }),
                    filter: (z, f) => {
                        const kind = f.props.class ? f.props.class : "";
                        return ["industrial", "commercial", "retail"].includes(kind);
                    },
                },
                // LANDCOVER
                {
                    dataLayer: "landcover",
                    symbolizer: new protomapsL.PolygonSymbolizer({
                        fill: "#dff1cc",
                        opacity: 0.6
                    }),
                    filter: (z, f) => {
                        const kind = f.props.class ? f.props.class : "";
                        return ["grass", "scrubb"].includes(kind);
                    },
                },
                {
                    dataLayer: "landcover",
                    symbolizer: new protomapsL.PolygonSymbolizer({
                        fill: "#c8e9ff",
                        opacity: 0.6
                    }),
                    filter: (z, f) => {
                        const kind = f.props.class ? f.props.class : "";
                        return ["wetland"].includes(kind);
                    },
                },
                {
                    dataLayer: "landcover",
                    symbolizer: new protomapsL.PolygonSymbolizer({
                        fill: "#dceebd",
                        opacity: 0.6
                    }),
                    filter: (z, f) => {
                        const kind = f.props.class ? f.props.class : "";
                        return ["forest", "wood"].includes(kind);
                    },
                },
                // ROADS
                {
                    dataLayer: "transportation",
                    symbolizer: new protomapsL.LineSymbolizer({
                        fill: "#000000",
                        opacity: 0.3,
                        dash: [2, 2],
                        dashColor: "#000000",
                        dashWidth: 2
                    }),
                    filter: (z, f) => {
                        const kind = f.props.class ? f.props.class : "";
                        return ["rail"].includes(kind);
                    },
                },

                // Outline for motorways
                {
                    dataLayer: "transportation",
                    symbolizer: new protomapsL.LineSymbolizer({
                        color: "#c50505",
                        opacity: 1,
                        width: (z, f) => {
                            return exp(2.2, [
                                [13, 4.2],
                                [19, 6.2],
                            ])(z);
                        }
                    }),
                    filter: (z, f) => {
                        const kind = f.props.class ? f.props.class : ""
                        return ["motorway"].includes(kind)
                    },
                },

                {
                    dataLayer: "transportation",
                    symbolizer: new protomapsL.LineSymbolizer({
                        color: "#e38a5a",
                        opacity: 1,
                        lineCap: 'round',
                        lineJoin: 'round',
                        width: (z, f) => {
                            return exp(1.6, [
                                [13, 3],
                                [19, 5],
                            ])(z);
                        }
                    }),
                    filter: (z, f) => {
                        const kind = f.props.class ? f.props.class : ""
                        return ["motorway"].includes(kind)
                    },
                },

                // Outline for trunks - same width as upper class
                {
                    dataLayer: "transportation",
                    symbolizer: new protomapsL.LineSymbolizer({
                        color: "#e38a5a",
                        opacity: 1,
                        width: (z, f) => {
                            return exp(1.3, [
                                [13, 4.2],
                                [19, 6.2],
                            ])(z);
                        }
                    }),
                    filter: (z, f) => {
                        const kind = f.props.class ? f.props.class : ""
                        return ["trunk", "primary"].includes(kind)
                    },
                },
                {
                    dataLayer: "transportation",
                    symbolizer: new protomapsL.LineSymbolizer({
                        color: "#f3da20",
                        opacity: 1,
                        lineCap: 'round',
                        lineJoin: 'round',
                        width: (z, f) => {
                            return exp(1.6, [
                                [11, 1],
                                [13, 3],
                                [19, 5],
                            ])(z)
                        }
                    }),
                    filter: (z, f) => {
                        const kind = f.props.class ? f.props.class : ""
                        return ["trunk", "primary"].includes(kind)
                    },
                },

                // Outline for secondary
                {
                    dataLayer: "transportation",
                    symbolizer: new protomapsL.LineSymbolizer({
                        color: "#9f9d9d",
                        opacity: 1,
                        width: (z, f) => {
                            return exp(2.2, [
                                [14, 3.2],
                                [16, 4.2],
                                [18, 5.2]
                            ])(z)
                        }
                    }),
                    filter: (z, f) => {
                        const kind = f.props.class ? f.props.class : ""
                        return ["secondary", "tertiary"].includes(kind)
                    },
                },
                {
                    dataLayer: "transportation",
                    symbolizer: new protomapsL.LineSymbolizer({
                        color: "#ffffff",
                        opacity: 1,
                        lineCap: 'round',
                        lineJoin: 'round',
                        width: (z, f) => {
                            return exp(1, [
                                [14, 2],
                                [16, 3],
                                [18, 4],
                            ])(z)
                        }
                    }),
                    filter: (z, f) => {
                        const kind = f.props.class ? f.props.class : ""
                        return ["secondary", "tertiary"].includes(kind)
                    },
                },
                {
                    dataLayer: "transportation",
                    symbolizer: new protomapsL.LineSymbolizer({
                        color: "#c7c6c6",
                        opacity: 1,
                        width: (z, f) => {
                            return exp(1, [
                                [14, 1],
                                [18, 3],
                            ])(z)
                        }
                    }),
                    filter: (z, f) => {
                        const kind = f.props.class ? f.props.class : ""
                        return ["minor", "cycleway", "service"].includes(kind)
                    },
                },
                {
                    dataLayer: "transportation",
                    symbolizer: new protomapsL.LineSymbolizer({
                        color: "#b6977e",
                        opacity: 1,
                        dash: [2, 2],
                        dashColor: "#b6977e",
                        dashWidth: 2,
                        width: 1
                    }),
                    filter: (z, f) => {
                        const kind = f.props.class ? f.props.class : "";
                        return ["path"].includes(kind);
                    },
                },
                {
                    dataLayer: "boundary",
                    symbolizer: new protomapsL.LineSymbolizer({
                        color: "#dc5454",
                        opacity: 0.8,
                        dash: [2, 2],
                        dashColor: "#dc5454",
                        dashWidth: 1,
                        width: 1
                    }),
                    filter: (z, f) => {
                        return z < 14
                    }
                },
                {
                    dataLayer: "boundary",
                    symbolizer: new protomapsL.LineSymbolizer({
                        color: "#c0bfbf",
                        opacity: 0.8,
                        dashColor: "#c0bfbf",
                        dashWidth: 1,
                        dash: [2,2],
                        width: 1
                    }),
                    filter: (z, f) => {
                        return z >= 14
                    }
                },
            ]
        },

        getLabelRules() {
            return [
                // PLACES
                {
                    dataLayer: "place",
                    symbolizer: new protomapsL.CenteredTextSymbolizer({
                        labelProps: ['name:latin', 'name:nonlatin'],
                        fill: "white",
                        width: 1,
                        letterSpacing: 4,
                        stroke: "#a2a2a2",
                        font: "400 16px sans-serif"
                    }),
                    filter: (z, f) => {
                        const kind = f.props.class ? f.props.class : "";
                        return ["country"].includes(kind);
                    }
                },
                {
                    dataLayer: "place",
                    symbolizer: new protomapsL.CenteredTextSymbolizer({
                        labelProps: ['name:latin', 'name:nonlatin'],
                        fill: "#404041",
                        width: 1,
                        letterSpacing: 2,
                        stroke: "#ffffffff",
                        font: "600 16px sans-serif"
                    }),
                    filter: (z, f) => {
                        const kind = f.props.class ? f.props.class : "";
                        return ["city"].includes(kind);
                    }
                },
                {
                    dataLayer: "place",
                    symbolizer: new protomapsL.CenteredTextSymbolizer({
                        labelProps: ['name:latin', 'name:nonlatin'],
                        fill: "#404041",
                        stroke: "#ffffff",
                        width: 1,
                        font: "600 12px sans-serif"
                    }),
                    filter: (z, f) => {
                        const kind = f.props.class ? f.props.class : "";
                        return ["town"].includes(kind);
                    }
                },
                {
                    dataLayer: "place",
                    symbolizer: new protomapsL.CenteredTextSymbolizer({
                        labelProps: ['name:latin', 'name:nonlatin'],
                        textTransform: 'uppercase',
                        fill: "#404041",
                        stroke: "#ffffff",
                        width: 1,
                        font: "400 12px sans-serif"
                    }),
                    filter: (z, f) => {
                        const kind = z < 12 && f.props.class ? f.props.class : ""
                        return ["suburb", "village", "quarter", "hamlet", "neighbourhood"].includes(kind)
                    }
                },
                {
                    dataLayer: "place",
                    symbolizer: new protomapsL.CenteredTextSymbolizer({
                        labelProps: ['name:latin', 'name:nonlatin'],
                        textTransform: 'uppercase',
                        fill: "#404041",
                        stroke: "#ffffff",
                        width: 1,
                        font: "400 14px sans-serif"
                    }),
                    filter: (z, f) => {
                        const kind = z > 11 && f.props.class ? f.props.class : ""
                        return ["suburb", "village", "quarter", "hamlet", "neighbourhood"].includes(kind)
                    }
                },
                {
                    dataLayer: "place",
                    symbolizer: new protomapsL.CenteredTextSymbolizer({
                        labelProps: ['name:latin', 'name:nonlatin'],
                        fill: "#7b7b80",
                        stroke: "#ffffff",
                        width: 1,
                        font: "400 11px sans-serif"
                    }),
                    filter: (z, f) => {
                        const kind = f.props.class ? f.props.class : "";
                        return ["peak"].includes(kind);
                    }
                },
                {
                    dataLayer: "water_name",
                    symbolizer: new protomapsL.CenteredTextSymbolizer({
                        labelProps: ['name:latin', 'name:nonlatin'],
                        fill: "#0571ce",
                        stroke: "#ffffff",
                        width: 1,
                        font: "400 10px sans-serif"
                    }),
                    filter: (z, f) => {
                        const kind = f.props.class ? f.props.class : "";
                        return ["bay", "river"].includes(kind);
                    }
                },
                {
                    dataLayer: "water_name",
                    symbolizer: new protomapsL.CenteredTextSymbolizer({
                        labelProps: ['name:latin', 'name:nonlatin'],
                        fill: "#0571ce",
                        stroke: "#ffffff",
                        width: 1,
                        font: "400 12px sans-serif"
                    }),
                    filter: (z, f) => {
                        const kind = f.props.class ? f.props.class : "";
                        return ["lake", "ocean", "sea"].includes(kind);
                    }
                },
                {
                    dataLayer: "transportation_name",
                    symbolizer: new protomapsL.LineLabelSymbolizer({
                        labelProps: ['name:latin', 'name:nonlatin'],
                        fill: "#000000",
                        stroke: "#ffffff",
                        width: 1,
                        position: 2,
                        font: "400 12px sans-serif"
                    })
                },
            ]
        }
    }
}

