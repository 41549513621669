<template>
    <div>
        <b-navbar
            v-if="showTitle"
            variant="faded"
            class="header-background"
            type="dark"
        >
            <div class="header">
        <span class="title">
          {{ $t('contract_reports.title') }}
        </span>
            </div>
        </b-navbar>
        <contract-report-filter
            :hide-contract-selection="this.contract !== null"
            @search="fetchReports"
            :report-sections="reportSections"
            @update:report-sections="reportSections = $event"
            :show-titles="showTitle"
            :from-date="from"
            :to-date="to"
        />
        <hr/>
        <div class="header col-sm-12 button-container">
            <font-awesome-icon
                v-if="searchParams"
                icon="print"
                class="print-icon"
                @click.stop="print"
            />
        </div>
        <div
            id="printContainer"
            class="report"
            :class="{borderless: borderless}"
            v-if="searchParams"
        >
            <!-- Basic info -->
            <div class="pt-2 pb-3">
                <span class="title nopads report-heading">{{ $t('contract_reports.report_title') }}</span>
                <span class="title nopads">{{
                        getShortDateWithoutTimezone(searchParams.dateFrom)
                    }} - {{ getShortDateWithoutTimezone(searchParams.dateTo) }}</span>
            </div>
            <table class="table table-sm">
                <tbody>
                <tr>
                    <td class="item-detail-title">
                        {{ $t('contract_reports.contract') }}
                    </td>
                    <td class="item-detail-text">
                        {{ contractName }}
                    </td>
                </tr>
                <tr>
                    <td class="item-detail-title">
                        {{ $t('contract_reports.customer') }}
                    </td>
                    <td class="item-detail-text">
                        {{ customerName }}
                    </td>
                </tr>
                <tr>
                    <td class="item-detail-title">
                        {{ $t('contract_reports.contractor') }}
                    </td>
                    <td class="item-detail-text">
                        {{ contractorName }}
                    </td>
                </tr>
                </tbody>
            </table>

            <contract-costs
                ref="costs"
                v-if="contract && contract.id > 0"
                :searchParams="searchParams"/>

            <weather-tracking-position-details
                v-if="reportSections.includes('weatherData')"
                :search-params="searchParams"
            />
            <!-- Contract Diaries -->
            <contract-diaries-report
                ref="contractDiary"
                :ely-fields="this.contract.ely_contract"
                :contract="contract"
                :search-params="searchParams"
                @loading="handleLoading"
            />
            <!-- Delivery notes -->
            <contract-report-delivery-notes
                v-if="reports && reports.length > 0 && reportSections.includes('deliveryNotes')"
                :delivery-notes="reports"
                :search-params="searchParams"
            />
            <!-- Paving methods -->
            <contract-report-paving-methods
                v-if="reports && reports.length > 0 && reportSections.includes('pavingMethods')"
                :delivery-notes="reports"
                ref="pavingMethods"
            />
            <!-- Price list deliveries -->
            <contract-report-order-trip-deliveries
                v-if="reportSections.includes('tripDeliveries')"
                :trips="trips"
                :search-params="searchParams"
            />
            <!-- Work reports (manual material consumption and team) -->
            <contract-work-reports
                v-if="reportSections.includes('workReports')"
                :search-params="searchParams"
                ref="workReports"
                @loading="handleLoading"
            />
            <!-- Work Assignments -->
            <contract-report-work-assignments
                v-if="reportSections.includes('workAssignments')"
                :search-params="searchParams"
                :is-admin="isAdmin"
                :is-observer="isObserver"
                ref="workAssignments"
                @loading="handleLoading"
            />
            <!-- Trips -->
            <contract-report-trips
                v-if="reportSections.includes('trips')"
                :searchParams="searchParams"
                ref="trips"
                @loading="handleLoading"
                @tripsSuccess="handleTrips"
            />
            <!-- Material Reports -->
            <contract-material-reports
                v-if="reportSections.includes('materials')"
                :searchParams="searchParams"
                :contract="contract"
                ref="materials"
                @loading="handleLoading"
            />
            <!-- Danger Reports -->
            <contract-danger-reports
                v-if="reportSections.includes('dangerReports')"
                :search-params="searchParams"
                :is-observer="isObserver"
                ref="dangerReports"
                @loading="handleLoading"
            />
            <!-- Road signs -->
            <contract-report-road-signs
                v-if="reportSections.includes('roadSigns')"
                :searchParams="searchParams"
                :is-admin="isAdmin"
                :user="user"
                @loading="handleLoading"
            />
            <!-- Area work reports -->
            <contract-area-work-reports
                v-if="reportSections.includes('areaWorkReports')"
                :searchParams="searchParams"
                @loading="handleLoading"
            />
            <sport-work-list
                v-if="reportSections.includes('skiTrackReports')"
                :show-title="true"
                :params="searchParams"
                />
            <contract-report-observations
                v-if="reportSections.includes('observationReport')"
                :is-observer="isObserver"
                :search-params="searchParams"/>
            <contract-report-area-inspections
                v-if="reportSections.includes('areaInspections')"
                :is-observer="isObserver"
                :search-params="searchParams"/>
            <contract-report-inspections
                v-if="reportSections.includes('inspections')"
                :is-observer="isObserver"
                :user="user"
                :search-params="searchParams"/>
            <traffic-control-reports
                v-if="reportSections.includes('trafficControl')"
                :is-observer="isObserver"
                :search-params="searchParams"/>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import ContractReportFilter from "./ContractReportFilter";
import ContractReportDeliveryNotes from "./ContractReportDeliveryNotes";
import {restApi} from "@/components/mixins/RestApiMixin";
import {timeUtils} from "@/components/mixins/TimeUtils";
import {printHelper} from "@/components/mixins/PrintMixin";
import {Printd} from 'printd';
import ContractReportPavingMethods from "./ContractReportPavingMethods";
import ContractReportTrips from "./ContractReportTrips";
import ContractDangerReports from "./ContractDangerReports";
import ContractReportRoadSigns from "./ContractReportRoadSigns";
import ContractWorkReports from "./ContractWorkReports";
import ContractMaterialReports from "./ContractMaterialReports";
import ContractAreaWorkReports from "./ContractAreaWorkReports";
import SportWorkList from "@/components/sports/sportwork/SportWorkList";
import ContractReportOrderTripDeliveries from "@/components/contractreport/ContractReportOrderTripDeliveries";
import ContractReportObservations from "@/components/contractreport/ContractReportObservations";
import ContractReportInspections from "@/components/contractreport/ContractReportInspections";
import ContractReportAreaInspections from "@/components/contractreport/ContractReportAreaInspections";
import ContractDiariesReport from "@/components/contractreport/ContractDiariesReport.vue";
import TrafficControlReports from "@/components/trafficcontrol/TrafficControlReports";
import WeatherTrackingPositionDetails from "../weather/WeatherTrackingPositionDetails";
import ContractReportWorkAssignments from "@/components/contractreport/ContractReportWorkAssignments.vue";
import ContractCosts from "@/components/contractcosts/ContractCosts";

export default {
    name: "contract-reports",
    components: {
        ContractCosts,
        ContractReportWorkAssignments,
        WeatherTrackingPositionDetails,
        ContractDiariesReport,
        ContractReportAreaInspections,
        ContractReportInspections,
        ContractReportObservations,
        SportWorkList,
        ContractAreaWorkReports,
        ContractMaterialReports,
        ContractWorkReports,
        ContractReportRoadSigns,
        ContractDangerReports,
        TrafficControlReports,
        ContractReportTrips,
        ContractReportOrderTripDeliveries,
        ContractReportPavingMethods,
        ContractReportDeliveryNotes,
        ContractReportFilter
    },
    mixins: [restApi, timeUtils, printHelper],
    props: {
        isAdmin: Boolean,
        isObserver: Boolean,
        user: {
            type: Object,
            default: null
        },
        showTitle: {
            Type: Boolean,
            default: true
        },
        borderless: {
            Type: Boolean,
            default: true
        },
        contract: {
            Type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: 0,
            reports: [],
            trips: [],
            tripParams: null,
            tripsVisible: false,
            searchParams: null,
            contractName: null,
            customerName: null,
            contractorName: null,
            from: null,
            to: null,
            reportSections: [
                "contractCosts",
                "deliveryNotes",
                "trips",
                "dangerReports",
                "roadSigns",
                "materials",
                "workAssignments",
                "areaWorkReports",
                "observationReport",
                "areaInspections",
                "inspections",
                "weatherData",
                "trafficControl"
            ]
        }
    },
    mounted() {
        this.d = new Printd()
        this.from = this.timestampToDateInput(this.getMonthStart())
        this.to = this.timestampToDateInput(this.getMonthEnd())
    },
    methods: {
        fetchReports: function (params, contract) {
            this.reports = []
            this.tableItems = []
            this.searchParams = params
            this.contractName = this.contract ? this.contract.name :contract.name
            this.customerName = this.contract ? this.contract.customer.name : contract.customer.name
            this.contractorName = this.contract ? this.contract.contractor.name : contract.contractor.name
            this.loading++
            if (this.contract) {
                params['contract'] = this.contract.id
            }
            this.restFetchParams(this.materialDeliveryUrl, params, this.handleSuccess, this.fail)
        },
        handleTrips(trips) {
            this.trips = trips
        },
        handleSuccess: function (response) {
            this.loading--
            this.reports = response.data
        },
        fail: function () {
            this.loading--
        },
        print: function () {
            if (!this.loading) {
                if (this.reportSections.includes('trips') && this.$refs.trips.$refs.charts) {
                    this.$refs.trips.$refs.charts.generateImages()
                }
                if (this.reportSections.includes('pavingMethods') && this.$refs.pavingMethods) {
                    this.$refs.pavingMethods.generateImages()
                }
                if (this.reportSections.includes('dangerReports') && this.$refs.dangerReports) {
                    this.$refs.dangerReports.generateImages()
                }
                this.d.print(document.getElementById('printContainer'), this.printStyle)
            }
        },
        handleLoading: function (loading) {
            if (loading) {
                this.loading++
            } else {
                this.loading--
            }
        },
        updateDiaryRecords() {
            if (this.$refs.contractDiary) {
                this.$refs.contractDiary.fetchDiaries()
            }
        }
    }
}
</script>
<style scoped>
.borderless {
    border-color: #FFFFFF !important;
}
</style>
