<template>
    <div class="col-sm-12 nopads">
        <div class="search-form">
            <div class="col-sm-12 search-form-title">
                <font-awesome-icon icon="search"/>
                {{ $t('common.search') }}
            </div>
            <b-form
                id="delivery-note-search"
                class="mt-2"
                @submit.prevent="onSubmit"
                @reset="onReset"
            >
                <b-row class="nopads">
                    <div class="col-sm-4" style="margin-top: -.4em">
                        <open-contract-filter
                            :from="fromDate"
                            :to="toDate"
                            :contract="contract"
                            :apply-padding="false"
                            :row-view="false"
                            :showOnlyMyContractsOption="true"
                            :show-title="true"
                            :show-company="false"
                            @contractSelected="setContract"/>
                        <b-form-group v-if="showOnlyMyContractsOption">
                            <b-form-checkbox
                                v-model="onlyUserOwnedContracts"
                                size="md"
                                type="text"
                                value=1
                                unchecked-value=0>
                                {{ $t('contracts.only_user_owned_contracts') }}
                            </b-form-checkbox>
                        </b-form-group>
                    </div>
                    <div class="col-sm-4"  style="margin-top: -.4em">
                        <contract-order-filter
                            v-model="order"
                            :contract-id="contract ? contract.id : null"
                        />
                    </div>
                    <div v-if="materials && materials.length > 0" class="col-sm-4">
                        <span class="search-title">{{ $t('delivery_note.material') }}</span>
                        <b-form-select
                            v-model="material"
                            size="sm"
                            :disabled="!materials || !!loading"
                        >
                            <option :value="null">
                                {{ $t('delivery_note.form.select_material') }}
                            </option>
                            <option
                                v-for="material in materials"
                                :key="material.id"
                                :value="material.id"
                            >
                                {{ material.material.name + ' (' + material.type_test_code + ')' }}
                            </option>
                        </b-form-select>
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-4">
                        <span class="search-title">{{ $t('trip_list.search_with_license') }}</span>
                        <b-form-input
                            v-model="licensePlate"
                            placeholder="ABC-123"
                            size="sm"
                            :disabled="!!loading"
                        />
                    </div>
                    <div class="col-sm-4">
                        <span class="search-title">{{ $t('delivery_note.date') }} - {{
                                $t('trip_list.road_sec_from')
                            }}</span>
                        <datetime
                            v-model="fromDate"
                            type="datetime"
                            format="dd.MM.yyyy HH:mm"
                            class="routa-input"
                            :placeholder="$t('observations_videos.search_input_format')"
                        />
                    </div>
                    <div class="col-sm-4">
                        <span class="search-title">{{ $t('delivery_note.date') }} - {{
                                $t('trip_list.road_sec_to')
                            }}</span>
                        <datetime
                            v-model="toDate"
                            type="datetime"
                            format="dd.MM.yyyy HH:mm"
                            class="routa-input"
                            :placeholder="$t('observations_videos.search_input_format')"
                        />
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-4">
                        <span class="search-title">{{ $t('trip_list.vehicle') }}</span>
                        <multiselect
                            v-model="vehicle"
                            class="multiselect_routa"
                            :placeholder="$t('trip_list.select_vehicle')"
                            :deselect-label="$t('common.remove_selection')"
                            :select-label="$t('common.select')"
                            :close-on-select="true"
                            label="name"
                            track-by="name"
                            :searchable="true"
                            :internal-search="true"
                            :selected-label="$t('common.selected')"
                            :options="vehicleOptions"
                        >
                            <template
                                slot="singleLabel"
                                slot-scope="props"
                            >
                                  <span class="option__desc">
                                    <span class="option__title">{{ props.option.name }}</span>
                                  </span>
                            </template>
                            <template
                                slot="option"
                                slot-scope="props"
                            >
                                <div class="option__desc">
                                        <span class="option__title multiselect_detail_block">{{
                                                props.option.name
                                            }}</span>
                                </div>
                            </template>
                            <span slot="noResult">{{ $t('common.not_found') }}</span>
                            <span slot="noOptions">{{ $t('common.no_results') }}</span>
                        </multiselect>
                    </div>
                    <div class="col-sm-4" v-if="showStorages">
                        <material-storage-filter
                            v-model="storage"
                            :search-title-style="true"
                        />
                    </div>
                    <div class="col-sm-4" v-if="showStorages">
                        <b-form-checkbox
                            class="pt-4"
                            id="checkbox-1"
                            v-model="includeTrips"
                        >
                            {{ $t('delivery_note.include_trips') }}
                        </b-form-checkbox>
                    </div>
                </b-row>
                <div class="col-sm-12 button-container">
                    <b-button
                        type="submit"
                        variant="primary"
                        class="result-button"
                        size="sm"
                    >
                        {{ $t('common.search') }}
                    </b-button>
                    <b-button
                        type="reset"
                        variant="danger"
                        class="result-button"
                        size="sm"
                    >
                        {{ $t('delivery_note.clear') }}
                    </b-button>
                </div>
            </b-form>
        </div>
        <div
            v-if="!!loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from "@/components/mixins/RestApiMixin";
import {timeUtils} from "@/components/mixins/TimeUtils";
import {orderMixin} from "@/components/mixins/OrderMixin";
import ContractOrderFilter from "../order/ContractOrderFilter";
import MaterialStorageFilter from "@/components/materialstorage/MaterialStorageFilter";
import OpenContractFilter from "@/components/contract/OpenContractFilter";

export default {
    name: "DeliveryNoteSearch",
    components: {OpenContractFilter, MaterialStorageFilter, ContractOrderFilter},
    mixins: [restApi, timeUtils, orderMixin],
    data: function () {
        return {
            fromDate: this.loginTime ? this.loginTime.toISOString() : null,
            toDate: null,
            contract: null,
            order: null,
            licensePlate: null,
            material: null,
            loading: 0,
            vehicleOptions: [],
            vehicle: null,
            storage: null,
            includeTrips: false,
            onlyUserOwnedContracts: 0
        }
    },
    props: {
        station: {
            type: Number
        },
        showStorages: {
            type: Boolean,
            default: false
        },
        contracts: {
            type: Array,
            default() {
                return []
            }
        },
        loginTime: {
            type: Date,
            default: null
        },
        materials: {
            type: Array,
            default() {
                return []
            }
        },
        showOnlyMyContractsOption: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        station: function () {
            this.selectStation()
        },
        onlyUserOwnedContracts: function() {
            this.$emit('onlyUserOwnedContractSelected', this.onlyUserOwnedContracts, "search")
        },
    },
    mounted() {
        if (!this.fromDate) {
            this.fromDate = this.getMonthStart()
        }
        this.fetchVehicles();
    },
    methods: {
        fetchVehicles: function () {
            this.loading++
            this.vehicleOptions = []
            this.restFetch(this.vehicleUrl, this.handleVehicles)
        },

        handleVehicles: function (response) {
            if (response && response.data) {
                this.vehicleOptions = response.data.map(function (item) {
                    return {
                        value: item.id,
                        name: item.make + ' ' + item.vehicle_model + ' - ' + item.license_plate
                    }
                })
            }
            this.vehiclesLoaded = true
            this.loading--
        },

        setContract: function (contract) {
            this.contract = contract ? contract : null
        },

        refreshParams: function() {
            this.onSubmit()
        },

        onSubmit: function () {
            let params = {}
            if (this.fromDate !== null && this.fromDate.trim()) {
                params.timeFrom = new Date(this.fromDate).getTime()
            }
            if (this.toDate !== null && this.toDate.trim()) {
                params.timeTo = new Date(this.toDate).getTime()
            }
            if (this.contract !== null) {
                params.contract = this.contract.id
            }
            if (this.order) {
                params.order = this.order.id
            }
            if (this.licensePlate !== null && this.licensePlate.trim()) {
                params.licensePlate = this.licensePlate
            }
            if (this.material !== null) {
                params.material = this.material
            }
            if (this.vehicle !== null) {
                params.vehicle = this.vehicle.value
            }
            if (this.storage) {
                params.storage = this.storage
            }
            if (this.includeTrips) {
                params.includeTrips = 1
            }
            this.$emit('search', params)
        },
        onReset: function () {
            this.orders = []
            this.fromDate = null
            this.toDate = null
            this.contract = null
            this.vehicle = null
            this.storage = null
            this.onlyUserOwnedContracts = 0
            this.order = null
            this.licensePlate = null
            this.includeTrips = false
            this.material = null
        },
        selectStation: function () {
            this.contract = null
            this.order = null
            this.material = null
            this.onlyUserOwnedContracts = 0
        }
    }
}
</script>
