<template>
    <div
        class="details-container" @click.stop="">
        <div style="width: 90%; height: 90%; margin: auto;" class="mt-3 mb-2 text-center">
            <b-img
                id="imageCont"
                :src="base64String"
                :fluid="true"
                center
                class="m-auto large-image"
                style="object-fit: contain"
            />
            <rotate-image-buttons v-if="controls" imageContainerId="imageCont" style="z-index: 10;" :save-disabled="true" @saveClicked="saveRotation"/>
        </div>
        <font-awesome-icon icon="times-circle" class="close-dialog-icon colored-border" @click.stop="$emit('close')"></font-awesome-icon>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import RotateImageButtons from '@/components/view/RotateImageButtons'

export default {
    name: 'FullscreenBase64Image',
    components: {RotateImageButtons},
    mixins: [restApi],
    props: {
        base64String: null,
        controls: {
            type: Boolean,
            default() {
                return true
            }
        }
    },
    methods: {
        saveRotation(rotation) {
            this.$emit('rotationChanged', rotation)
        }
    }
}
</script>

<style>

.large-image {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transition: transform 0.3s ease;
}

.colored-border {
    border: 0.07em solid black;
    background-color: black;
    border-radius: 50%;
}

</style>
