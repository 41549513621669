<template>
    <div class="nopads">
        <b-row v-if="showCompany && rowView" class="nopads">
            <div class="col-sm-6 nopads pr-1" :class="{'pl-1': applyPadding}">
                <b-form-group label-for="companies">
                    <span class="span-title">{{ $t('contracts.customer') }}</span>
                    <b-form-select
                        id="companies"
                        v-model="selectedCompany"
                        size="sm"
                        class="sm"
                        :disabled="fetchingContracts > 0 || companies.length < 1"
                    >
                        <template slot="first">
                            <option :value="null">
                                {{ $t('observation_filter.select_company') }}
                            </option>
                        </template>
                        <option
                            v-for="option in companies"
                            :key="option.id"
                            :value="option.id"
                        >
                            {{ option.name }}
                        </option>
                    </b-form-select>
                </b-form-group>
            </div>
            <div class="col-sm-6 nopads pl-1 pr-1">
                <b-row
                    class="nopads"
                >
                    <span v-if="showTitle" class="span-title">{{ $t('orders.contract') }}</span>
                    <multiselect
                        v-model="selectedContract"
                        :options="filteredContracts"
                        :close-on-select="true"
                        label="name"
                        track-by="name"
                        :searchable="true"
                        :internal-search="true"
                        :custom-label="customLabel"
                        :selected-label="$t('common.selected')"
                        :select-label="$t('common.select')"
                        :deselect-label="$t('common.remove_selection')"
                        :placeholder="$t('orders.select_contract')"
                        :disabled="fetchingContracts > 0"
                        :filter-results="customFilter"
                    >
                        <template
                            slot="singleLabel"
                            slot-scope="props"
                        >
                          <span class="option__desc">
                            <span class="option__title">{{ props.option.customer.name }} - {{ props.option.name }}</span>
                          </span>
                        </template>
                        <template
                            slot="option"
                            slot-scope="props"
                        >
                            <div class="option__desc">
                                <span class="option__title multiselect_detail_block">{{ props.option.customer.name }} - {{ props.option.name }}</span>
                            </div>
                        </template>
                        <span slot="noResult">{{ $t('common.not_found') }}</span>
                        <span slot="noOptions">{{ $t('common.no_results') }}</span>
                    </multiselect>
                </b-row>
                <div class="ml-1" v-if="showOnlyMyContractsOption">
                    <b-form-group label-for="companies">
                        <b-form-checkbox
                            v-model="onlyUserOwnedContracts"
                            size="sm"
                            type="text"
                            value=1
                            unchecked-value=0>
                            {{ $t('contracts.only_user_owned_contracts') }}
                        </b-form-checkbox>
                    </b-form-group>
                </div>
            </div>
        </b-row>
        <b-row v-else-if="rowView">
            <div class="col-sm-12 nopads pr-1">
                <b-row
                    class="nopads"
                >
                    <span v-if="showTitle" class="span-title">{{ $t('orders.contract') }}</span>
                    <multiselect
                        v-model="selectedContract"
                        :options="filteredContracts"
                        :close-on-select="true"
                        label="name"
                        track-by="name"
                        :custom-label="customLabel"
                        :searchable="true"
                        :internal-search="true"
                        :selected-label="$t('common.selected')"
                        :select-label="$t('common.select')"
                        :deselect-label="$t('common.remove_selection')"
                        :placeholder="$t('orders.select_contract')"
                        :disabled="fetchingContracts > 0"
                        :filter-results="customFilter"
                    >
                        <template
                            slot="singleLabel"
                            slot-scope="props"
                        >
                          <span class="option__desc">
                            <span class="option__title">{{ props.option.customer.name }} - {{ props.option.name }}</span>
                          </span>
                        </template>
                        <template
                            slot="option"
                            slot-scope="props"
                        >
                            <div class="option__desc">
                                <span class="option__title multiselect_detail_block">{{ props.option.customer.name }} - {{ props.option.name }}</span>
                            </div>
                        </template>
                        <span slot="noResult">{{ $t('common.not_found') }}</span>
                        <span slot="noOptions">{{ $t('common.no_results') }}</span>
                    </multiselect>
                </b-row>
                <div class="ml-1" v-if="showOnlyMyContractsOption">
                    <b-form-group label-for="companies">
                        <b-form-checkbox
                            v-model="onlyUserOwnedContracts"
                            size="md"
                            type="text"
                            value=1
                            unchecked-value=0>
                            {{ $t('contracts.only_user_owned_contracts') }}
                        </b-form-checkbox>
                    </b-form-group>
                </div>
            </div>
        </b-row>
        <b-form-group
            v-if="!rowView"
            class="nopads"
            label-for="contract"
        >
            <span v-if="showTitle" class="span-title">{{ $t('orders.contract') }}</span>
            <multiselect
                v-model="selectedContract"
                :options="filteredContracts"
                :close-on-select="true"
                label="name"
                track-by="name"
                :custom-label="customLabel"
                :searchable="true"
                :internal-search="true"
                :selected-label="$t('common.selected')"
                :select-label="$t('common.select')"
                :deselect-label="$t('common.remove_selection')"
                :placeholder="$t('orders.select_contract')"
                :disabled="fetchingContracts > 0"
                :filter-results="customFilter"
            >
                <template
                    slot="singleLabel"
                    slot-scope="props"
                >
                          <span class="option__desc">
                            <span class="option__title">{{ props.option.customer.name }} - {{ props.option.name }}</span>
                          </span>
                </template>
                <template
                    slot="option"
                    slot-scope="props"
                >
                    <div class="option__desc">
                        <span class="option__title multiselect_detail_block">{{ props.option.customer.name }} - {{ props.option.name }}</span>
                    </div>
                </template>
                <span slot="noResult">{{ $t('common.not_found') }}</span>
                <span slot="noOptions">{{ $t('common.no_results') }}</span>
            </multiselect>
        </b-form-group>
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {restApi} from '../mixins/RestApiMixin'
import {contractHelper} from "@/components/mixins/ContractMixin";

export default {
    name: 'OpenContractFilter',
    mixins: [timeUtils, restApi, contractHelper],
    props: {
        from: {
            type: String,
            default: null
        },
        to: {
            type: String,
            default: null
        },
        applyPadding: {
            type: Boolean,
            default() {
                return true
            }
        },
        contract: {
            type: Object,
            default: null
        },
        company: {
            type: Object,
            default: null
        },
        contractCollection: {
            type: Object,
            default: null
        },
        showCompany: {
            type: Boolean,
            default() {
                return true
            }
        },
        showTitle: {
            type: Boolean,
            default: true
        },
        rowView: {
            type: Boolean,
            default: true
        },
        showOnlyMyContractsOption: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            selectedContract: null,
            contracts: [],
            companies: [],
            selectedCompany: null,
            filteredContracts: [],
            fetchingContracts: 0,
            onlyUserOwnedContracts: 0
        }
    },
    watch: {
        from: function () {
            this.fetchContracts()
        },
        to: function () {
            this.fetchContracts()
        },
        company: function (value) {
            if (value) {
                if (value.id !== this.selectedCompany) {
                    this.selectedCompany = value.id
                }
            } else {
                this.selectedCompany = null
            }
        },
        contractCollection() {
            this.filterContracts()
        },
        selectedCompany: function (value) {
            this.filterContracts()
            if (this.companies && this.companies.length > 0) {
                let company = this.companies.find(item => item.id === value)
                this.$emit('companySelected', company)
            }
        },
        contract: function () {
            this.updateContract()
        },
        selectedContract: function (value) {
            if (this.contracts && this.contracts.length > 0) {
                let contract = value ? this.contracts.find(item => item.id === value.id) : undefined
                this.$emit('contractSelected', contract)
            }
        },
        onlyUserOwnedContracts: function() {
            this.fetchContracts()
        }
    },
    mounted: function () {
        this.updateContract()
        this.fetchContracts()
    },
    methods: {
        updateContract() {
            if (this.contract) {
                if(this.selectedContract) {
                    if (this.contract.id !== this.selectedContract.id) {
                        this.selectedContract = this.contract
                    }
                } else {
                    this.selectedContract = this.contract
                }
            } else {
                this.selectedContract = null
            }
        },
        customLabel(option) {
            // This controls how the options are displayed
            return `${option.customer.name} - ${option.name}`;
        },
        customFilter(options, search) {
            // Custom search logic to include customer name
            const lowerSearch = search.toLowerCase();
            return options.filter(option =>
                option.name.toLowerCase().includes(lowerSearch) ||
                option.customer.name.toLowerCase().includes(lowerSearch)
            );
        },
        fetchContracts: function () {
            if (this.from) {
                var params = {}
                this.contracts = []
                this.companies = []
                this.filteredContracts = []
                this.$emit('loading')
                this.fetchingContracts++
                params.startDate = this.localTimeToUtcTime(this.from).toISOString()
                params.endDate = this.to ? this.getEndOfTheDay(this.to).toISOString() : null
                if(parseInt(this.onlyUserOwnedContracts) === 1) {
                    params.onlyUserOwnedContracts = 1
                }
                this.restFetchParams(this.contractOpenUrl,
                    params,
                    this.handleContracts)
            }
        },
        handleContracts: function (response) {
            if (response && response.data) {
                this.contracts = response.data
                this.filteredContracts = this.contracts
                if (this.selectedContract) {
                    let exists = this.contracts.find(item => item.id === this.selectedContract.id)
                    if (!exists) {
                        this.selectedContract = null
                    }
                }
                if (this.contracts && this.contracts.length === 1) {
                    // Pre-select the only contract available
                    this.selectedContract = this.contracts[0]
                }
                this.filterCompanies()
                this.filterContracts()
            }
            this.fetchingContracts--
            this.$emit('loadingCompleted')
            this.$emit('contractsFetched', this.contracts)
        },
        filterCompanies: function () {
            this.companies = []
            if (this.contracts && this.contracts.length > 0) {
                this.contracts.forEach(item => {
                    let customer = item.customer
                    let existing = this.companies.find(comp => comp.id === customer.id)
                    if (!existing) {
                        this.companies.push(customer)
                    }
                })
                if (this.selectedCompany) {
                    let exists = this.companies.find(item => item.id === this.selectedCompany)
                    if (!exists) {
                        this.selectedCompany = null
                    }
                }
            } else {
                this.contract = null
            }
        },
        resetData() {
            this.onlyUserOwnedContracts = false
        },
        resetSelection() {
            this.selectedContract = null
        },
        reset() {
            this.selectedContract = null
            this.onlyUserOwnedContracts = false
        }
    }
}
</script>
