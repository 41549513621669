<template>
<div class="comment-content" :class="{'users-comment': isUserComment}">
    <b-row>
        <b-col class="nopads pr-1">
            <span class="comment-writer">{{ comment.user.first_name }} {{ comment.user.last_name}}</span>
        </b-col>
        <b-col class="nopads text-right pr-1">
            <span class="comment-date">{{ toLocalTime(comment.created )}}</span>
        </b-col>
    </b-row>
    <b-row v-if="comment.photo">
        <div style="height: 25em" class="mt-1 mb-2">
            <b-img
                :src="comment.photo"
                :fluid="true"
                center
                class="m-auto pointer"
                style="object-fit: contain; border: none; height: 100% !important;"
                @click="showPhoto = true"
            />
        </div>
    </b-row>
    <b-row>
        <span class="comment-text">{{ comment.comment }}</span>
    </b-row>
    <transition name="fade">
        <fullscreen-base64-image
            v-if="showPhoto"
            :base64-string="comment.photo"
            @close="showPhoto = false"/>
    </transition>
</div>

</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from "@/components/mixins/TimeUtils";
import FullscreenBase64Image from "@/components/view/FullscreenBase64Image";

export default {
    name: 'Comment',
    components: {FullscreenBase64Image},
    mixins: [restApi, timeUtils],
    data: function () {
        return {
            showPhoto: false,
            loading: true
        }
    },
    props: {
        comment: {
            type: Object,
            required: true
        },
        user: {
            type: Object,
            required: true
        }
    },
    computed: {
       isUserComment() {
           if (this.user && this.user.id) {
               return this.comment.user.id === this.user.id
           }
           return false
       }
    },
    methods: {
        // eslint-disable-next-line
        saveRotation(rotation) {
            // TODO - Add an endpoint to backend that rotates comments image by given rotation
        },
        rotationSaveResponse() {
            // Re-fetch photo to have the rotated photo shown
        }
    }
}
</script>
<style>

.comment-content {
    padding-left: .5em;
    padding-right: .5em;
    padding-top: .25em;
    padding-bottom: .25em;
    border: solid 1px #BCBCBC;
    border-radius: .5em;
    background: #f6f6f6;
    margin-bottom: .25em;
    margin-right: 15vw;
}

.users-comment {
    margin-right: 0;
    margin-left: 15vw;
    background: #f3fced;
}

@media (min-width: 768px) {

    .comment-content {
        margin-left: 0;
        margin-right: 20vw;
    }

    .users-comment {
        margin-right: 0;
        margin-left: 20vw;
    }

}


.comment-date, .comment-writer {
    font-size: .9em;
}

.comment-date {
    font-style: italic;
}

.comment-writer {
    font-weight: bold;
    color: #484848;
}

.comment-text {
    padding-top: .2em;
    font-size: 1em;
}

</style>
