<template>
    <b-container fluid
                 class="details-container"
    >
        <div class="col-sm-12">
            <b-form class="col-sm-12 editor-form">
                <div class="col-sm-12 form-title editor-title">
                    <h2
                        v-if="assignmentUpdate.id > 0 || assignments.length === 1"
                        class="form-title editor-title"
                    >
                        {{ $t('work_assignment.edit') }}
                    </h2>
                    <h2
                        v-else-if="assignments && assignments.length > 1"
                        class="form-title editor-title"
                    >
                        {{ $t('work_assignment.edit_multiple') }}
                    </h2>
                    <h2
                        v-else
                        class="form-title editor-title"
                    >
                        {{ $t('work_assignment.add') }}
                    </h2>
                </div>
                <div class="col-sm-12 form-data">
                    <!-- Show open contracts  -->
                    <div class="col-12 nopads">
                        <b-form-group
                            class="title nopads"
                            label-for="contract"
                        >
                            <span class="span-title">{{ $t('orders.contract') }}</span>
                            <b-form-select
                                id="contract"
                                v-model="assignmentUpdate.contract.id"
                                size="sm"
                                class="sm-3"
                            >
                                <template slot="first">
                                    <option :value="null">
                                        {{ $t('orders.select_contract') }}
                                    </option>
                                </template>
                                <option
                                    v-for="option in contracts"
                                    :key="option.id"
                                    :value="option.id"
                                >
                                    {{ option.name }}
                                </option>
                            </b-form-select>
                        </b-form-group>
                    </div>

                    <div class="col-12 nopads">
                        <b-form-group
                            class="title nopads"
                            label-fomapr="contract"
                        >
                            <contract-order-filter
                                v-model="assignmentUpdate.work_order"
                                :pre-selected-order="assignmentUpdate.work_order.id"
                                :contract-id="assignmentUpdate.contract.id"
                            />
                        </b-form-group>
                    </div>

                    <div class="col-sm-12 button-container">
                        <b-button
                            variant="danger"
                            class="routa-button"
                            @click.stop="$emit('closeForm')"
                        >
                            <i class="fa fa-times"/>
                            <span class="button-text">
                              {{ $t('common.cancel') }}
                            </span>
                        </b-button>
                        <b-button
                            variant="success"
                            class="routa-button"
                            :disabled="loading"
                            @click.stop="submitMultiple"
                        >
                            <i class="fa fa-save"/>
                            <span class="button-text">
                              {{ $t('common.save') }}
                            </span>
                        </b-button>
                    </div>
                </div>
            </b-form>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </b-container>
</template>

<script>
import {restApi} from "../mixins/RestApiMixin";
import {attachmentHelper} from "../mixins/AttachmentMixin";
import {workAssignmentHelper} from "../mixins/WorkAssignmentMixin";
import {timeUtils} from "../mixins/TimeUtils";
import ContractOrderFilter from "../order/ContractOrderFilter";
import {EventBus} from "../../event-bus";

export default {
    name: "WorkAssignmentEditorDialog",
    components: {
        ContractOrderFilter
    },
    mixins: [workAssignmentHelper, restApi, timeUtils, attachmentHelper],
    props: {
        assignments: null,
        isAdmin: Boolean,
        isObserver: Boolean,
    },
    data: function (){
        return{
            assignmentUpdate: this.newWorkAssignmentMultiple(this.workAssignment),
            contracts: [],
            loading: false,
        }
    },
    mounted: function() {
        if (this.isAdmin || this.isObserver) {
            this.fetchOpenContracts()
        }
    },
    methods: {
        newWorkAssignmentMultiple: function (like) {
            return {
                id: -1,
                contract: like && like.contract ? like.contract : {id: null},
                work_order: like && like.work_order ? like.work_order : {id: null},
                ...like
            }
        },
        submitMultiple:function (){
            let newJsonAssignment = this.setDefaultValues(this.assignmentUpdate)
            if (newJsonAssignment) {
                this.loading = true
                if(!this.assignments) this.fail
                var assignmentIds = []
                this.assignments.forEach(function (assignment) {
                    assignmentIds.push(assignment.id)
                })
                const params = newJsonAssignment
                params.assignments = assignmentIds
                this.restAdd(this.workAssignmentsUrl, params, this.success, this.fail)
            }
        },
        success: function (workAssignment) {
            this.assignmentUpdate = workAssignment
            this.loading = false
            this.$emit('closeAndUpdate', this.assignmentUpdate)
            this.$emit('showWorkAssignments')
        },
        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        },
        setDefaultValues: function (assignment) {
            assignment = JSON.parse(JSON.stringify(assignment))
            if (assignment.contract && assignment.contract.id) {
                assignment.contract = assignment.contract.id
            } else {
                assignment.contract = undefined
            }
            if (assignment.work_order && assignment.work_order.id) {
                assignment.work_order = assignment.work_order.id
            } else {
                assignment.work_order = undefined
            }
            return assignment
        },
    }
}
</script>

<style scoped>

</style>
