<template>
    <div class="col-sm-12 nopads">
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 form-title editor-title">
                <h2
                    v-if="keyUpdate.id > 0"
                    class="form-title editor-title"
                >
                    {{ $t('api_keys.edit_key') }}
                </h2>
                <h2
                    v-else
                    class="form-title editor-title"
                >
                    {{ $t('api_keys.add_key') }}
                </h2>
            </div>
            <div class="col-sm-12 form-data">
                <span class="span-title required">
                    {{ $t('api_keys.purpose') }}
                </span>
                <b-form-group
                    class="title"
                    label-for="purpose"
                >
                    <b-form-input
                        id="purpose"
                        v-model="keyUpdate.purpose"
                        type="text"
                        :state="purposeState"
                        size="sm"
                    />
                </b-form-group>

                <div class="nopads" v-if="isAdmin">
                    <span class="span-title">
                      {{ $t('api_keys.company') }}
                    </span>
                    <b-form-group
                        class="title"
                        label-for="company"
                    >
                        <b-form-select
                            id="customer"
                            v-model="company"
                            size="sm"
                            class="mb-3"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t('users.select_company') }}
                                </option>
                            </template>
                            <option
                                v-for="c in companies"
                                :key="c.id"
                                :value="c.id"
                            >
                                {{ c.name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>

                <span class="span-title required">
                    {{ $t('api_keys.type') }}
                </span>
                <b-form-group
                    class="title"
                    label-for="type"
                >
                    <b-form-select
                        id="type"
                        v-model="keyUpdate.type"
                        size="sm"
                        class="mb-3"
                    >
                        <template slot="first">
                            <option
                                :value="null"
                                disabled
                            >
                                {{ $t('api_keys.select_type') }}
                            </option>
                        </template>
                        <option
                            :value="1"
                        >
                            {{ $t('api_keys.type_contract') }}
                        </option>
                        <option
                            :value="2"
                        >
                            {{ $t('api_keys.type_delivery') }}
                        </option>
                        <option
                            :value="3"
                        >
                            {{ $t('api_keys.type_gis') }}
                        </option>
                        <option
                            :value="4"
                        >
                            {{ $t('api_keys.type_ticket') }}
                        </option>
                        <option
                            :value="5"
                        >
                            {{ $t('api_keys.type_spreader') }}
                        </option>
                        <option
                            :value="6"
                        >
                            Harja
                        </option>
                        <option
                            :value="7"
                        >
                            {{ $t('api_keys.type_integration') }}
                        </option>
                    </b-form-select>
                </b-form-group>


                <div class="nopads" v-if="keyUpdate.type === 5 || keyUpdate.type === 7">
                    <span class="span-title required">
                        {{ $t('api_keys.service_provider') }}
                    </span>
                    <b-form-group
                        class="title"
                        label-for="type"
                    >
                        <b-form-select
                            id="type"
                            v-model="keyUpdate.service_provider"
                            :options="serviceProviders"
                            size="sm"
                            class="mb-3"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t('api_keys.select_service_provider') }}
                                </option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                </div>

                <span class="span-title">
                    {{ $t('api_keys.ext_username') }}
                </span>
                <b-form-group
                    class="title"
                    label-for="purpose"
                >
                    <b-form-input
                        id="purpose"
                        v-model="keyUpdate.ext_username"
                        type="text"
                        size="sm"
                    />
                </b-form-group>

                <span class="span-title">
                    {{ $t('api_keys.ext_password') }}
                </span>
                <b-form-group
                    class="title"
                    label-for="purpose"
                >
                    <b-form-input
                        id="purpose"
                        v-model="keyUpdate.ext_password"
                        type="text"
                        size="sm"
                    />
                </b-form-group>

                <span class="span-title">
                    {{ $t('api_keys.ext_url') }}
                </span>
                <b-form-group
                    class="title"
                    label-for="purpose"
                >
                    <b-form-input
                        id="purpose"
                        v-model="keyUpdate.ext_url"
                        type="text"
                        size="sm"
                    />
                </b-form-group>

                <span class="span-title required">
                    {{ $t('api_keys.status') }}
                </span>
                <b-form-group
                    class="title"
                    label-for="status"
                >
                    <b-form-checkbox
                        id="status"
                        v-model="keyUpdate.status"
                        :value=1
                        :unchecked-value=0
                        type="text"
                        size="sm"
                    >
                        {{ keyUpdate.status === 1 ? $t('api_keys.active') : $t('api_keys.inactive') }}
                    </b-form-checkbox>
                </b-form-group>

                <span class="span-title required">
                    {{ $t('api_keys.key') }}
                </span>
                <b-form-group
                    class="title"
                    label-for="apiKey"
                >
                    <b-form-input
                        id="apiKey"
                        v-model="keyUpdate.api_key"
                        type="text"
                        :state="keyState"
                        size="sm"
                    />
                </b-form-group>
            </div>
            <div class="col-sm-12 button-container pb-3">
                <b-button
                    variant="danger"
                    class="form-button"
                    @click.stop="$emit('close')"
                >
                    {{ $t('common.cancel') }}
                </b-button>
                <b-button
                    variant="success"
                    class="form-button"
                    :disabled="formState"
                    @click.stop="submitKey"
                >
                    {{ $t('common.save') }}
                </b-button>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../mixins/RestApiMixin'
import {apiKeyHelper} from '../mixins/ApiKeyMixin'

export default {
    name: 'ApiKeyEditor',
    mixins: [restApi, apiKeyHelper],
    props: {
        keyToEdit: {
            type: Object,
            default: null
        },
        isAdmin: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            loading: 0,
            company: null,
            companies: [],
            keyUpdate: this.jsonToApiKey(this.keyToEdit),
            serviceProviders: [],
            spreaderServiceProviders: [
                {text: 'Arctic Machine', value: 1},
                {text: 'Fleetlogis', value: 3},
                {text: 'Rasco', value: 2}
            ],
            integrationServiceProviders: [
                {text: 'Buildie', value: 1},
            ]
        }
    },
    computed: {
       purposeState() {
           return !!(this.keyUpdate.purpose && this.keyUpdate.purpose.length > 2)
       },
       keyState() {
           return !!(this.keyUpdate.api_key && this.keyUpdate.api_key.length > 2 )
       },
       formState () {
           return !this.purposeState || !this.keyState || !!this.loading
       }
    },

    watch: {
       'keyUpdate.type': function (val) {
           if (val === 5) {
               this.serviceProviders = this.spreaderServiceProviders
           } else if(val === 7) {
                this.serviceProviders = this.integrationServiceProviders
           } else {
               this.serviceProviders = []
           }
       }
    },

    mounted() {
        this.fetchCompanies()
        if (this.keyUpdate.company) {
            this.company = this.keyUpdate.company.id
            this.companies.push(this.keyUpdate.company)
        }
    },

    methods: {

        async fetchCompanies() {
            this.loading++;
            const { err, ...response } = await this.axios.get(this.companyUrl).catch((err) => ({err}));
            this.loading--;
            if (err) {
                return;
            }
            this.companies = response.data;
        },

        setDefaultValues(apiKey) {
            if (!apiKey.purpose) {
                EventBus.$emit('show-alert', this.$t('api_keys.err_purpose'))
                return
            }
            if (!apiKey.api_key) {
                EventBus.$emit('show-alert', this.$t('api_keys.err_key'))
                return
            }
            if(this.company) {
                apiKey.company = {id: this.company}
            } else {
                EventBus.$emit('show-alert', this.$t('api_keys.err_company'))
                return
            }
            if (apiKey.type === 5 && !apiKey.service_provider ) {
                EventBus.$emit('show-alert', this.$t('api_keys.err_service_provider'))
                return
            }
            return apiKey
        },

        submitKey() {
            var jsonApiKey = this.setDefaultValues(this.keyUpdate)
            if (jsonApiKey === undefined) {
                return;
            }
            this.loading++;

            if (jsonApiKey.id < 1) {
                this.restAdd(this.apiKeyUrl, jsonApiKey, this.success, this.fail)
            } else {
                this.restUpdate(this.apiKeyUrl, jsonApiKey, this.success, this.fail)
            }
        },

        success () {
            this.loading--
            this.$emit('closeAndUpdate')
        },

        fail () {
            this.loading--
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'));
        },

    }
}
</script>
