<template>
    <div v-if="inspections && inspections.length > 0" class="nopads">
        <b-row>
            <span class="section-title">{{ $t('contract_reports.other_inspections') }} ({{inspections.length}})</span>
            <b-col class="nopads">
                <font-awesome-icon
                    icon="arrow-down"
                    class="pointer right no-focus arrow-icon dont-print"
                    v-bind:class="{rotated : inspectionsVisible}"
                    v-b-toggle.inspectionDetails
                    @click.stop="inspectionsVisible = !inspectionsVisible"/>
            </b-col>
        </b-row>
        <b-collapse
            id="inspectionDetails"
            class="mt-2 mb-2 item-details show-print"
        >
            <contract-inspections-list
                :inspections="inspections"
                @inspectionSelected="showInspectionReport"/>
        </b-collapse>

        <b-modal
            ref="inspectionReportDetails"
            body-class="fill-modal"
            size="lg"
            :hide-header="true"
            :hide-footer="true"
        >
            <inspection-report-details
                id="details"
                ref="details"
                :report-details="inspection"
                :is-observer="isObserver"
                :user="user"
                @close="inspection = null"
                @closeAndUpdate="closeAndUpdate"
            />
        </b-modal>
        <hr class="report-divider nopads"/>
    </div>
</template>

<script>
import {restApi} from "@/components/mixins/RestApiMixin";
import ContractInspectionsList from "@/components/inspections/ContractInspectionsList";
import InspectionReportDetails from "@/components/inspections/InspectionReportDetails.vue";

export default {
    name: "contract-report-inspections",
    components: {
        InspectionReportDetails,
        ContractInspectionsList},
    mixins: [restApi],
    props: {
        searchParams: {
            type: Object,
            default() {
                return null
            }
        },
        isObserver: Boolean,
        user: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            inspections: [],
            inspection: null,
            inspectionsVisible: false
        }
    },
    watch: {
        searchParams: function () {
            this.fetchInspections()
        }
    },
    mounted() {
        this.fetchInspections()
    },
    methods: {
        fetchInspections: function () {
            if (this.searchParams && this.searchParams.dateTo && this.searchParams.dateFrom && this.searchParams.contract) {
                let params = {
                    from: new Date(this.searchParams.dateFrom).toISOString(),
                    to: new Date(this.searchParams.dateTo).toISOString(),
                    contract: this.searchParams.contract
                }
                this.inspections = []
                this.$emit('loading', true)
                this.restFetchParams(this.inspectionReportGenericUrl, params, this.handleResponse, this.handleFail)
            }
        },
        handleResponse: function (response) {
            this.$emit('loading', false)
            this.inspections = response.data
        },
        handleFail: function () {
            this.$emit('loading', false)
        },
        showInspectionReport(item) {
            this.inspection = item
            this.$refs.inspectionReportDetails.show()
        },
        closeAndUpdate() {
            this.$refs.inspectionReportDetails.hide()
            this.inspection = null
            this.inspectionsVisible = false
            this.fetchInspections()
        }
    }
}
</script>
