// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
/* global require */
import Vue from 'vue'
import App from './App'
import router from './router'
import VueI18n from 'vue-i18n'
import store from './store'

import Multiselect from 'vue-multiselect'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faAngleDoubleDown,
    faAngleDoubleUp,
    faArchway,
    faArrowCircleDown,
    faArrowCircleUp,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faBan,
    faBarcode,
    faBars,
    faBoxOpen,
    faBuilding,
    faCalendarCheck,
    faCamera,
    faCar,
    faCaretDown,
    faCaretRight,
    faCarSide,
    faCertificate,
    faChartLine,
    faCheck,
    faCheckCircle,
    faCheckSquare,
    faChevronDown,
    faChevronUp,
    faCircle,
    faClipboard,
    faClipboardCheck,
    faClipboardList,
    faClock,
    faCloudSun,
    faCog,
    faCogs,
    faCoins,
    faComment,
    faCopy,
    faCrosshairs,
    faDollyFlatbed,
    faDotCircle,
    faDrawPolygon,
    faEdit,
    faEnvelopeOpenText,
    faEuroSign,
    faExclamation,
    faExclamationCircle,
    faExclamationTriangle,
    faEye,
    faFileContract,
    faFileCsv,
    faFileInvoice,
    faFilter,
    faFlag,
    faHandsHelping,
    faHardHat,
    faHatWizard,
    faHelicopter,
    faIndustry,
    faInfoCircle,
    faLightbulb,
    faList,
    faLock,
    faMap,
    faMapMarker,
    faMapPin,
    faMinus,
    faMinusSquare,
    faMoneyCheck,
    faPaintBrush,
    faPaperclip,
    faPause,
    faPlay,
    faPlayCircle,
    faPrint,
    faQuestion,
    faQuestionCircle,
    faRoad,
    faRoute,
    faRulerHorizontal,
    faSearch,
    faSkiingNordic,
    faSnowplow,
    faSquare,
    faTablet,
    faTachometerAlt,
    faTasks,
    faTicketAlt,
    faTimes,
    faTimesCircle,
    faTractor,
    faTrailer,
    faTrash,
    faTruck,
    faTruckMonster,
    faTruckMoving,
    faUser,
    faUsers,
    faUserTie,
    faVideo,
    faWarehouse,
    faWaveSquare,
    faWrench,
    faCloudUploadAlt,
    faStar,
    faPlus,
    faPlug,
    faFilePdf
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import Clipboard from 'v-clipboard'
import visibility from "vue-visibility-change";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';


Vue.use(visibility);
Vue.use(Clipboard)
Vue.use(VueI18n)

Vue.component('multiselect', Multiselect)
library.add(
    faPlus,
    faMinus,
    faMinusSquare,
    faBars,
    faTachometerAlt,
    faTruck,
    faUsers,
    faUserTie,
    faUser,
    faMap,
    faMapPin,
    faCog,
    faCogs,
    faCheckSquare,
    faClipboardCheck,
    faClipboardList,
    faExclamationTriangle,
    faCalendarCheck,
    faCoins,
    faMoneyCheck,
    faFileContract,
    faFileInvoice,
    faArrowDown,
    faRoad,
    faCar,
    faDrawPolygon,
    faRoute,
    faHandsHelping,
    faQuestionCircle,
    faTruckMonster,
    faTruckMoving,
    faCarSide,
    faExclamationCircle,
    faCarSide,
    faBan,
    faCheck,
    faWrench,
    faPrint,
    faClipboard,
    faEye,
    faMapMarker,
    faBarcode,
    faClock,
    faSquare,
    faVideo,
    faPlayCircle,
    faTicketAlt,
    faSearch,
    faEdit,
    faTrash,
    faCertificate,
    faCheckCircle,
    faQuestion,
    faExclamation,
    faPaperclip,
    faTasks,
    faAngleDoubleUp,
    faAngleDoubleDown,
    faCrosshairs,
    faInfoCircle,
    faCaretDown,
    faSkiingNordic,
    faSnowplow,
    faHelicopter,
    faTablet,
    faCopy,
    faWarehouse,
    faBoxOpen,
    faIndustry,
    faHardHat,
    faArrowLeft,
    faArrowRight,
    faEnvelopeOpenText,
    faLock,
    faList,
    faBuilding,
    faTimesCircle,
    faArrowCircleDown,
    faTractor,
    faTrailer,
    faDollyFlatbed,
    faRulerHorizontal,
    faArrowUp,
    faDotCircle,
    faArchway,
    faCloudSun,
    faEuroSign,
    faFileCsv,
    faComment,
    faWaveSquare,
    faTimes,
    faCircle,
    faArrowCircleUp,
    faFilter,
    faCaretRight,
    faLightbulb,
    faChartLine,
    faHatWizard,
    faPaintBrush,
    faPlay,
    faPause,
    faFlag,
    faCamera,
    faChevronUp,
    faChevronDown,
    faCloudUploadAlt,
    faStar,
    faPlug,
    faFilePdf
)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('VueSlider', VueSlider)

Vue.config.productionTip = false

const messages = {
    en: require('@/locales/en.json'),
    fi: require('@/locales/fi.json'),
    fr: require('@/locales/fr.json'),
    sv: require('@/locales/sv.json')
}

const dateTimeFormats = {
    fi: {
        long: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        },
        short: {
            hour: 'numeric',
            minute: 'numeric'
        },
        shortDate: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        },
        weekDay: {
            weekday: 'short'
        }
    },
    en: {
        long: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        },
        short: {
            hour: 'numeric',
            minute: 'numeric'
        },
        shortDate: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        },
        weekDay: {
            weekday: 'short'
        }
    },
    fr: {
        long: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        },
        short: {
            hour: 'numeric',
            minute: 'numeric'
        },
        shortDate: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        },
        weekDay: {
            weekday: 'short'
        }
    },
    sv: {
        long: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        },
        short: {
            hour: 'numeric',
            minute: 'numeric'
        },
        shortDate: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        },
        weekDay: {
            weekday: 'short'
        }
    }
}

export const i18n = new VueI18n({
    locale: navigator.language.split('-')[0], // set locale,
    fallbackLocale: 'en',
    messages, // set locale messages
    dateTimeFormats // set locale date formats
})

export const app = new Vue({
    el: '#app',
    i18n,
    store,
    router,
    components: { App },
    template: '<App/>'
})
