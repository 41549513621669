<template>
    <div class="nopads">
        <b-row v-if="showCompany" class="nopads">
            <div class="col-sm-6 nopads pl-1 pr-1">
                <b-form-group label-for="companies">
                    <span class="span-title">{{ $t('contracts.customer') }}</span>
                    <b-form-select
                        id="companies"
                        v-model="selectedCompany"
                        size="sm"
                        class="sm"
                        :disabled="fetchingContracts > 0 || companies.length < 1"
                    >
                        <template slot="first">
                            <option :value="null">
                                {{ $t('observation_filter.select_company') }}
                            </option>
                        </template>
                        <option
                            v-for="option in companies"
                            :key="option.id"
                            :value="option.id"
                        >
                            {{ option.name }}
                        </option>
                    </b-form-select>
                </b-form-group>
            </div>
            <div class="col-sm-6 nopads pl-1 pr-1">
                <b-form-group
                    class="nopads"
                    label-for="status"
                >
                    <span v-if="showTitle" class="span-title">{{ $t('orders.contract') }}</span>
                    <multiselect
                        v-model="selectedContracts"
                        :options="filteredContracts"
                        :multiple="true"
                        :close-on-select="false"
                        label="name"
                        track-by="name"
                        :searchable="true"
                        :selected-label="$t('common.selected')"
                        :select-label="$t('common.select')"
                        :deselect-label="$t('common.remove_selection')"
                        :placeholder="$t('orders.select_contract')"
                        :disabled="fetchingContracts > 0 || contractDisabled"
                    >
                        <template
                            slot="singleLabel"
                            slot-scope="props"
                        >
                  <span class="option__desc">
                    <span class="option__title">{{ props.option.name }}</span>
                  </span>
                        </template>
                        <template
                            slot="option"
                            slot-scope="props"
                        >
                            <div class="option__desc">
                                <span class="option__title multiselect_detail_block">{{ props.option.name }}</span>
                            </div>
                        </template>
                        <span slot="noResult">{{ $t('common.not_found') }}</span>
                        <span slot="noOptions">{{ $t('common.no_results') }}</span>
                    </multiselect>
                </b-form-group>
                <div v-if="showOnlyMyContractsOption">
                    <b-form-group class="no-margin" label-for="companies">
                        <b-form-checkbox
                            v-model="onlyUserOwnedContracts"
                            size="sm"
                            type="text"
                            value=1
                            unchecked-value=0>
                            {{ $t('contracts.only_user_owned_contracts') }}
                        </b-form-checkbox>
                    </b-form-group>
                </div>
                <div class="nopads" v-if="showSelectAllContractsOption && contractCollection && contractCollection.id">
                    <b-form-group label-for="selectAllContracts">
                        <b-form-checkbox
                            v-model="selectAllContracts"
                            size="md"
                            type="text"
                            :value=true
                            :unchecked-value=false>
                            {{ $t('contracts.select_all_contracts') }}
                        </b-form-checkbox>
                    </b-form-group>
                </div>
            </div>
        </b-row>
        <b-row v-else>
            <div class="col-sm-12">
            <b-form-group
                class="nopads"
                label-for="status"
            >
                <span v-if="showTitle" class="span-title">{{ $t('orders.contract') }}</span>
                <multiselect
                    v-model="selectedContracts"
                    :options="filteredContracts"
                    :multiple="true"
                    :close-on-select="false"
                    label="name"
                    track-by="name"
                    :searchable="true"
                    :internal-search="true"
                    :selected-label="$t('common.selected')"
                    :select-label="$t('common.select')"
                    :deselect-label="$t('common.remove_selection')"
                    :placeholder="$t('orders.select_contract')"
                    :disabled="fetchingContracts > 0 || contractDisabled"
                >
                    <template
                        slot="singleLabel"
                        slot-scope="props"
                    >
                      <span class="option__desc">
                        <span class="option__title">{{ props.option.name }}</span>
                      </span>
                    </template>
                    <template
                        slot="option"
                        slot-scope="props"
                    >
                        <div class="option__desc">
                            <span class="option__title multiselect_detail_block">{{ props.option.name }}</span>
                        </div>
                    </template>
                    <span slot="noResult">{{ $t('common.not_found') }}</span>
                    <span slot="noOptions">{{ $t('common.no_results') }}</span>
                </multiselect>
            </b-form-group>
            <div class="nopads mb-0" v-if="showOnlyMyContractsOption">
                <b-form-group class="no-margin" label-for="companies">
                    <b-form-checkbox
                        v-model="onlyUserOwnedContracts"
                        size="md"
                        type="text"
                        value=1
                        unchecked-value=0>
                        {{ $t('contracts.only_user_owned_contracts') }}
                    </b-form-checkbox>
                </b-form-group>
            </div>
            <div class="nopads" v-if="showSelectAllContractsOption && contractCollection && contractCollection.id">
                <b-form-group label-for="selectAllContracts">
                    <b-form-checkbox
                        v-model="selectAllContracts"
                        size="md"
                        type="text"
                        :value=true
                        :unchecked-value=false>
                        {{ $t('contracts.select_all_contracts') }}
                    </b-form-checkbox>
                </b-form-group>
            </div>
            </div>
        </b-row>
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {contractHelper} from "@/components/mixins/ContractMixin";
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: 'OpenContractFilterMultiSelect',
    mixins: [timeUtils, restApi, contractHelper],
    props: {
        from: {
            type: String,
            default: null
        },
        to: {
            type: String,
            default: null
        },
        company: {
            type: Object,
            default: null
        },
        contractDisabled: {
            type: Boolean,
            default: false
        },
        contractCollection: {
            type: Object,
            default: null
        },
        showCompany: {
            type: Boolean,
            default() {
                return true
            }
        },
        showTitle: {
            type: Boolean,
            default: true
        },
        showOnlyMyContractsOption: {
            type: Boolean,
            default: false
        },
        showSelectAllContractsOption: {
            type: Boolean,
            default: false
        },
        preSelectedContract: {
            type: Number,
            defaul: null
        }
    },
    data: function () {
        return {
            selectedContracts: [],
            contracts: [],
            companies: [],
            selectedCompany: null,
            filteredContracts: [],
            fetchingContracts: 0,
            onlyUserOwnedContracts: 0,
            selectAllContracts: false,
            preSelect: null
        }
    },
    watch: {
        from: function () {
            this.fetchContracts()
        },
        to: function () {
            this.fetchContracts()
        },
        company: function (value) {
            if (value) {
                if (value.id !== this.selectedCompany) {
                    this.selectedCompany = value.id
                }
            } else {
                this.selectedCompany = null
            }
        },
        selectedCompany: function (value) {
            this.filterContracts()
            this.updateContracts()
            if (this.companies && this.companies.length > 0) {
                let company = this.companies.find(item => item.id === value)
                this.$emit('companySelected', company)
            }
        },
        contract: function () {
            this.updateContracts()
        },
        selectAllContracts() {
            if(this.selectAllContracts) {
                this.selectedContracts = this.filteredContracts
            } else {
                this.selectedContracts = []
            }
        },
        contractCollection() {
            this.selectedContracts = []
            this.filterContracts()
        },
        selectedContracts: function () {
            this.filterCompanies()
            if (this.contracts && this.contracts.length > 0) {
                this.$emit('contractsSelected', this.selectedContracts)
            }
        },
        onlyUserOwnedContracts: function() {
            this.fetchContracts()
        }
    },
    mounted: function () {
        this.preSelect = this.preSelectedContract
        this.fetchContracts()
    },
    methods: {
        updateContracts() {
            this.selectedContracts = this.filteredContracts.filter(item => {
                return this.selectedContracts.find(selected => selected.id === item.id) !== undefined;
            })
        },
        fetchContracts: function () {
            if (this.from) {
                var params = {}
                this.contracts = []
                this.companies = []
                this.filteredContracts = []
                this.$emit('loading')
                this.fetchingContracts++
                params.startDate = this.localTimeToUtcTime(this.from).toISOString()
                params.endDate = this.to ? this.getEndOfTheDay(this.to).toISOString() : null
                if(parseInt(this.onlyUserOwnedContracts) === 1) {
                    params.onlyUserOwnedContracts = 1
                }
                this.restFetchParams(this.contractOpenUrl,
                    params,
                    this.handleContracts)
            }
        },
        handleContracts: function (response) {
            if (response && response.data) {
                this.contracts = response.data
                this.filteredContracts = this.contracts
                this.filterCompanies()
                this.filterContracts()
                if (this.preSelect) {
                    let selected = this.filteredContracts.find(item => item.id === this.preSelect)
                    if (selected) {
                        this.selectedContracts.push(selected)
                    }
                    this.preSelect = null
                }
            }
            this.fetchingContracts--
            this.$emit('loadingCompleted')
            this.$emit('contractsFetched', this.contracts)
        },
        filterCompanies: function () {
            this.companies = []
            if (this.contracts && this.contracts.length > 0) {
                this.contracts.forEach(item => {
                    let customer = item.customer
                    let existing = this.companies.find(comp => comp.id === customer.id)
                    if (!existing) {
                        this.companies.push(customer)
                    }
                })
                if (this.selectedCompany) {
                    let exists = this.companies.find(item => item.id === this.selectedCompany)
                    if (!exists) {
                        this.selectedCompany = null
                    }
                }
            } else {
                this.contract = null
            }
        },
        filterContracts: function () {
            // Initialize the filteredContracts to an empty array
            this.filteredContracts = [];

            // If both selectedCompany and contractCollection are defined
            if (this.selectedCompany && this.contractCollection && this.contractCollection.id) {
                this.filteredContracts = this.contracts.filter(item =>
                    item.customer.id === this.selectedCompany &&
                    item.contract_collection &&
                    item.contract_collection.id === this.contractCollection.id
                );
                return;
            }

            // If only selectedCompany is defined
            if (this.selectedCompany) {
                this.filteredContracts = this.contracts.filter(item =>
                    item.customer.id === this.selectedCompany
                );
                return;
            }

            // If only contractCollection is defined
            if (this.contractCollection && this.contractCollection.id) {
                this.filteredContracts = this.contracts.filter(item =>
                    item.contract_collection &&
                    item.contract_collection.id === this.contractCollection.id
                );
                return;
            }

            // If neither selectedCompany nor contractCollection is defined, include all contracts
            this.filteredContracts = this.contracts;
        }
    }
}
</script>
