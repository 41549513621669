<template>
    <div class="w-100">

        <div class="map-item-filter">
            <div
                class="col-sm-12 nopads vehicle-list"
                v-bind:class="{ list_closed: !listOpen }"
            >
                <span
                    class="vehicle-list-title"
                    @click.stop="toggleList">
                {{ $t('observations.observation_types_title') }} ({{ typeEntries ? typeEntries.length : 0 }})
                </span>
                <div
                    class="vehicle-list-caret"
                    v-bind:class="{ caret_open: listOpen }"
                    @click.stop="toggleList">
                    <font-awesome-icon icon="caret-down"/>
                </div>
                <div class="col-sm-12 nopads vehicle-list-container" v-if="listOpen">
                    <div v-if="selectedContractIds && selectedContractIds.length > 0" class="badge-filters__wrapper">
                        <div class="badge-filters">
                            <b-badge
                                v-for="item in timeFilters"
                                :key="item.value"
                                class="ml-2"
                                @click="toggleTimeFilter(item.value)"
                                :variant="isActiveTimeFilter(item.value) ? 'primary' : 'light'"
                                style="padding: .5em 1em"
                            >
                                {{ item.text }}
                            </b-badge>
                        </div>
                    </div>
                    <div
                        class="map-select-item"
                    >
                        <b-form-checkbox
                            v-model="allSelected"
                            name="checkboxAllTypes"
                            class="text-light"
                            @change="toggleAll"
                        >
                            {{ allSelected ? $t('observations.deselect_all') : $t('observations.select_all') }}
                        </b-form-checkbox>
                    </div>
                    <div
                        class="map-select-item"
                    >
                        <!-- Check box for select/ unselect types -->
                        <span class="text-light">{{ $t('observations.type_select_hint') }}</span>
                        <b-form-group>
                            <b-form-checkbox
                                v-for="option in typeEntries"
                                v-model="selectedTypes"
                                :key="option.value"
                                :value="option.value"
                                class="text-light pt-2"
                            >
                                {{ option.text }}
                            </b-form-checkbox>
                        </b-form-group>
                    </div>
                </div>
            </div>
        </div>

        <transition name="fade">
            <observation-editor
                v-if="editMode"
                id="editor"
                :observation="observation"
                :is-observer="isObserver"
                @scrollTop="$emit('scrollTop')"
                @close="hideObservationEditor"
                @closeAndUpdate="closeAndUpdate"
            />
        </transition>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />

    </div>

</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {mapHelper} from '../mixins/MapMixin'
import ObservationEditor from './ObservationEditor'
import {restApi} from '../mixins/RestApiMixin'
import  {geometryMixin} from "@/components/mixins/GeometryMixin";
import {promisify} from "../../modules/promise";

const STATUS = {
    ALL: 0,
    OPEN: 1,
    CLOSED: 2,
}

const INIT_STATUS = STATUS.OPEN

export default {
    name: 'ObservationMap',
    components: {ObservationEditor},
    mixins: [timeUtils, mapHelper, restApi, geometryMixin],
    props: {
        observationResults: {
            type: Array,
            default() {
                return null
            }
        },
        observationItem: {
            type: Object,
            default: null
        },
        map: {
            type: Object,
            default: null
        },
        infoVisible: {
            type: Boolean,
            default: true
        },
        zoomOnDrawMarker: {
            type: Boolean,
            default: false
        },
        draggable: {
            type: Boolean,
            default: false
        },
        zoomToObservations: {
            type: Boolean,
            default: false
        },
        selectedContractId: {
            type: Number,
            default: null
        },
        selectedContractIds: {
            type: Array,
            default() {
                return []
            }
        },
        selectedOrder: {
            type: Number,
            default: null
        },
        boundingBox: {
            type: Array,
            default: null
        },
        requireBoundingBox: {
            type: Boolean,
            default: false
        },
        isObserver: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            loading: false,
            editMode: false,
            observations: [],
            visibleObservations: [],
            typeEntries: [],
            selectedTypes: [],
            observation: null,
            listOpen: false,
            allSelected: true,
            indeterminate: false,
            visibilityZoomLevel: 7,
            TYPE: {
                POINT: 1,
                POLY_LINE: 2
            },
            Z_INDEX: {
                BEHIND: 2,
                INFRONT: 5
            },
            selectedTimeFilter: INIT_STATUS,
            timeFilters: [
                {
                    text: this.$t("common.all"),
                    value: STATUS.ALL,
                },
                {
                    text: this.$t("common.open"),
                    value: STATUS.OPEN,
                },
                {
                    text: this.$t("common.closed"),
                    value: STATUS.CLOSED,
                },
            ],
        }
    },

    watch: {
        map() {
            this.$nextTick(function () {
                if (this.observationResults && this.observationResults.length > 0) {
                    this.initView()
                } else if (this.isBBoxValid(this.boundingBox)) {
                    this.fetchObservations(true)
                }
            })
        },
        selectedTypes(newVal) {
            if (newVal.length === 0) {
                this.indeterminate = false
                this.allSelected = false
            } else if (newVal.length === this.typeEntries.length) {
                this.indeterminate = false
                this.allSelected = true
            } else {
                this.indeterminate = true
                this.allSelected = false
            }
            this.filterByTypes()
        },
        selectedTimeFilter(newVal) {
            if (newVal.length > 1 && this.isActiveTimeFilter(STATUS.ALL)) {
                this.selectedTimeFilter = newVal.filter((activeFilter) => activeFilter !== STATUS.ALL);
            }
            if (newVal.length === 0) {
                this.selectedTimeFilter = INIT_STATUS;
            }
            this.fetchObservations(true)
        },
        boundingBox() {
            if (!this.observationResults && this.map && this.isBBoxValid(this.boundingBox)) {
                this.fetchObservations()
            }
        },
        selectedContractIds() {
            if (!this.observationResults && this.map) {
                this.fetchObservations(true)
            }
        },
        selectedOrder() {
            if (!this.observationResults && this.map) {
                this.fetchObservations(true)
            }
        },
        requireBoundingBox: {
            type: Boolean,
            default: false
        },
        'observation.geometry': {
            handler: function () {
                if (this.observation && (!this.observations && this.observations.length < 1)) {
                    this.drawObservation(this.observation)
                }
            },
            deep: true
        },
    },
    mounted: function () {
        if (this.observationItem) {
            this.observation = this.observationItem
        } else if (this.observationResults && this.observationResults.length > 0) {
            this.observations = this.observationResults
            this.initView()
        } else if (this.map && (!this.requireBoundingBox || this.isBBoxValid(this.boundingBox))) {
            this.fetchObservations()
        }
    },
    beforeDestroy() {
        this.hideObservations()
    },
    methods: {

        fetchObservations(force) {
            if (this.map.getMapZoomLevel() < this.visibilityZoomLevel) {
                this.bbox = null
                this.hideObservations()
            } else if (force || (!this.requireBoundingBox || (this.boundingBox && (!this.bbox || this.isBboxOutsideBbox(this.boundingBox, this.bbox))))) {
                this.loading = true
                let params = {}
                if (this.requireBoundingBox && this.boundingBox) {
                    const extendRate = 0.01
                    this.bbox = [
                        this.boundingBox[0] - extendRate,
                        this.boundingBox[1] - extendRate,
                        this.boundingBox[2] + extendRate,
                        this.boundingBox[3] + extendRate
                    ]
                    params.bbox = this.bbox
                }
                if(this.selectedContractIds) {
                    params.contract = this.selectedContractIds
                    if(this.selectedOrder) {
                        params.order = this.selectedOrder
                    }
                } else if(this.selectedContractId) {
                    params.contract = this.selectedContractId
                    if(this.selectedOrder) {
                        params.order = this.selectedOrder
                    }
                }
                if(this.selectedTimeFilter !== null) {
                    params.status = this.selectedTimeFilter !== 0 ? this.selectedTimeFilter : null
                } else {
                    // if selectedTimeFilter is undefined we don't want to fetch all observations initially
                    params.status = 1
                }
                this.restFetchParams(this.observationMapUrl, params, this.handleObservationResponse, this.handleObservationsError)
            }
        },

        handleObservationResponse: function (response) {
            this.observations = []
            if (response && response.data) {
                this.observations.push(...response.data)
            }
            let params = {}
            this.restFetchParams(this.observationMapPublicUrl, params, this.handlePublicObservationResponse, this.handleObservationsError)
        },

        handleObservationsError: function () {
            this.loading = false
            this.initView()
        },

        handlePublicObservationResponse: function (response) {
            this.loading = true
            if (response.data) {
                response.data.forEach(item => {
                    // Observations that user can edit are shown already with open obs for company
                    if (!this.observations.find(obs => obs.id === item.id)) {
                        this.observations.push(item);
                    }
                })
            }
            this.initView()
            this.loading = false
        },

        async initView() {
            if (this.observations || this.observation) {
                this.hideObservations()
            }
            if (this.observations && this.observations.length > 0) {
                this.visibleObservations = this.observations
                this.initTypeEntries()
                this.filterByTypes()
            } else if (this.observation) {
                this.drawObservation(this.observation, this.infoVisible)
            }
            if (this.map && this.observations && this.zoomToObservations) {
                await this.$nextTick()
                this.map.zoomToGroup(this.OBSERVATION)
            }
        },

        drawObservations: function () {
            this.visibleObservations.forEach(function (observation) {
                this.drawObservation(observation, false)
            }, this)
        },

        drawObservation: function (observation) {
            var pointToZoom = null
            let markerStore = this.map.getMarkerStore()
            if (observation.geometry.point) {
                let icon = observation.closed_time ?
                    markerStore.getClosedObservationMarkerIcon() :
                    observation.public === true ? markerStore.getPublicObservationMarkerIcon() : markerStore.getObservationMarkerIcon()
                this.map.showMapMarker(observation.id, this.OBSERVATION, observation.geometry.point.y,
                    observation.geometry.point.x, icon, this.draggable, observation.closed_time ? this.Z_INDEX.BEHIND : this.Z_INDEX.INFRONT)
                pointToZoom = observation.geometry.point
            } else if (observation.geometry.line_string) {
                var closed = !!observation.closed_time
                var color = closed ? '#bcbcbc' : observation.public === true ? '#faba1b' : '#bb330e'
                var points = observation.geometry.line_string.points.map(point => {
                    return {x: point[0], y: point[1]}
                })
                this.map.drawPolyLine(observation.id, this.OBSERVATION, points, color, false, false, 5, 1, true, closed)
                pointToZoom = points[0]
            }
            if (pointToZoom && this.zoomOnDrawMarker) {
                this.map.zoomToPosition(pointToZoom.y, pointToZoom.x)
            }
        },

        hideObservations: function () {
            if (this.map) {
                this.map.removeMapItemsByType(this.OBSERVATION)
            }
            this.visibleObservations = []
            this.showDetails = false
        },

        setNewObservationTypeOn(observation) {
            const typeId = observation ? observation.type_id : null
            if(typeId) {
                let selectedType = this.selectedTypes.find(element => element === typeId)
                if (!selectedType) {
                    this.selectedTypes.push(typeId)
                }
            }
        },

        async onMarkerTap(data) {
            if (data.type !== this.OBSERVATION) {
                return;
            }
            if (this.observation && data.id === this.observation.id && this.editMode) {
                this.editMode = false;
                return;
            }
            this.loading = true;
            const response = await promisify(this.restFetch)(this.observationDetailsUrl + '/' + data.id)
                .catch(this.handleError)
                .finally(() => this.loading = false);
            if (!response || !response.data) {
                return;
            }
            this.editObservation(response.data)
        },

        handleError: function () {
            // TODO - Indicate error somehow
        },

        showObservationTitle: function (data) {
            // Show info bubble
            if (data.type === this.OBSERVATION) {
                let item = this.observations.find(o => o.id === data.id)
                if (item) {
                    let x, y
                    if (item.geometry && item.geometry.point) {
                        x = item.geometry.point.x
                        y = item.geometry.point.y
                    }
                    if (item.geometry && item.geometry.line_string) {
                        x = item.geometry.line_string.points[0][0]
                        y = item.geometry.line_string.points[0][1]
                    }
                    if (item) {
                        this.map.addMarkerLabel(item.id, item.name, y, x, data.type)
                    }
                }
            }
        },

        hideObservationTitle: function (data) {
            // Hide info bubble
            this.map.hideMarkerLabel(data.id, data.type)
        },

        hideObservationEditor: function () {
            this.editMode = false
        },

        editObservation: function (observation) {
            this.observation = observation
            if (this.observation) {
                this.editMode = true
            }
            this.$emit('scrollTop')
        },

        closeAndUpdate: function () {
            this.editMode = false
            this.fetchObservations(true)
        },

        initTypeEntries: function () {
            this.typeEntries = []
            if (this.observations && this.observations.length > 0) {
                this.observations.forEach(item => {
                    let existingType = this.typeEntries.find(element => element.value === item.type_id)
                    if (!existingType) {
                        this.typeEntries.push({text: item.name, value: item.type_id})
                    }
                })
                this.sortTypeEntries()
            }
            // By default, select all types
            if (!this.selectedTypes || this.selectedTypes.length < 1 || this.allSelected) {
                this.toggleAll(true)
            }
        },

        sortTypeEntries: function () {
            this.typeEntries.sort(function (a, b) {
                if (a.text < b.text) {
                    return -1;
                }
                if (a.text > b.text) {
                    return 1;
                }
                return 0;
            });
        },


        filterByTypes: function () {
            this.hideObservations()
            this.visibleObservations = []
            if (this.allSelected) {
                this.visibleObservations = this.observations
            } else {
                this.observations.forEach(item => {
                    let selectedType = this.selectedTypes.find(element => element === item.type_id)
                    if (selectedType) {
                        this.visibleObservations.push(item)
                    }
                })
            }
            this.$emit('visibleObservationsChanged', this.visibleObservations)
            this.drawObservations()
        },

        toggleList: function () {
            this.listOpen = !this.listOpen
        },

        toggleAll(checked) {
            this.selectedTypes = []
            if (checked) {
                this.typeEntries.forEach(item => {
                    this.selectedTypes.push(item.value)
                })
            }
        },
        toggleTimeFilter(val) {
            this.selectedTimeFilter = val
        },
        isActiveTimeFilter(val) {
            return val === this.selectedTimeFilter
        },
        getVisibleObservations() {
            return this.visibleObservations
        }
    }
}
</script>

<style lang="scss" scoped>
.badge-filters {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
    flex: 5;
    flex-wrap: wrap;

    & > * {
        cursor: pointer;
    }

    &__wrapper {
        display: flex;
        align-items: flex-start;
    }
}
</style>
