export const spreaderDataMixin = {
    data() {
        return {
            spreaderSystems: {
                SYSTEM_AM: 1,
                SYSTEM_RASCO: 2,
                SYSTEM_FLEET: 3,
                SYSTEM_ROUTA: 4
            },
            materialTypes: {
                DRY: 1,
                LIQUID: 2,
                BOTH: 3
            },
            ROUTA_MATERIAL_NA_CL: 1, // Dry
            ROUTA_MATERIAL_SAND: 2, // Dry
            ROUTA_MATERIAL_MACADAM: 3, // Dry
            ROUTA_MATERIAL_NACL_SAND: 4, // Dry
            ROUTA_MATERIAL_CACL_K: 5, // Dry
            ROUTA_MATERIAL_NAFO_K: 6, // Dry
            ROUTA_MATERIAL_CACL_SAND: 7, // Dry
            ROUTA_MATERIAL_NA_CL_SOLUTION: 8, // Liquid
            ROUTA_MATERIAL_CACL2_L: 9, // Liquid
            ROUTA_MATERIAL_K_FORM_SOLUTION: 10, // Liquid
            ROUTA_MATERIAL_NAFO_L: 11, // Liquid
            ROUTA_MATERIAL_MGCL: 12, // Liquid
            ROUTA_MATERIAL_OTHER: 13
        }
    },

    methods: {
        getDryMaterials() {
            return [
                { value: this.ROUTA_MATERIAL_NA_CL, text: this.$t('spreader_data.material_na_cl'), type: this.materialTypes.DRY },
                { value: this.ROUTA_MATERIAL_SAND, text: this.$t('spreader_data.material_sand'), type: this.materialTypes.DRY },
                // Harja doesn't support this materials, so don't allow user to select it
                // { value: this.ROUTA_MATERIAL_MACADAM, text: this.$t('spreader_data.material_macadam'), type: this.materialTypes.DRY },
                { value: this.ROUTA_MATERIAL_NACL_SAND, text: this.$t('spreader_data.material_na_cl_sand'), type: this.materialTypes.DRY },
                { value: this.ROUTA_MATERIAL_CACL_K, text: this.$t('spreader_data.material_ca_cl_k'), type: this.materialTypes.DRY },
                { value: this.ROUTA_MATERIAL_NAFO_K, text: this.$t('spreader_data.material_nafo_k'), type: this.materialTypes.DRY },
                { value: this.ROUTA_MATERIAL_CACL_SAND, text: this.$t('spreader_data.material_ca_cl_sand'), type: this.materialTypes.DRY }
            ]
        },
        getLiquidMaterials() {
            return [
                { value: this.ROUTA_MATERIAL_NA_CL_SOLUTION, text: this.$t('spreader_data.material_na_cl_solution'), type: this.materialTypes.LIQUID },
                { value: this.ROUTA_MATERIAL_CACL2_L, text: this.$t('spreader_data.material_ca_cl2_l'), type: this.materialTypes.LIQUID },
                { value: this.ROUTA_MATERIAL_K_FORM_SOLUTION, text: this.$t('spreader_data.material_k_form_solution'), type: this.materialTypes.LIQUID },
                { value: this.ROUTA_MATERIAL_NAFO_L, text: this.$t('spreader_data.material_nafo_l'), type: this.materialTypes.LIQUID },
                // Harja doesn't support this materials, so don't allow user to select it
                // { value: this.ROUTA_MATERIAL_MGCL, text: this.$t('spreader_data.material_mgcl'), type: this.materialTypes.LIQUID }
            ]
        },
        getMaterialName(materialCode) {
            switch (materialCode) {
                case this.ROUTA_MATERIAL_NA_CL: return this.$t('spreader_data.material_na_cl')
                case this.ROUTA_MATERIAL_SAND: return this.$t('spreader_data.material_sand')
                case this.ROUTA_MATERIAL_MACADAM: return this.$t('spreader_data.material_macadam')
                case this.ROUTA_MATERIAL_NACL_SAND: return this.$t('spreader_data.material_na_cl_sand')
                case this.ROUTA_MATERIAL_CACL_K: return this.$t('spreader_data.material_ca_cl_k')
                case this.ROUTA_MATERIAL_NAFO_K: return this.$t('spreader_data.material_nafo_k')
                case this.ROUTA_MATERIAL_CACL_SAND: return this.$t('spreader_data.material_ca_cl_sand')
                case this.ROUTA_MATERIAL_NA_CL_SOLUTION: return this.$t('spreader_data.material_na_cl_solution')
                case this.ROUTA_MATERIAL_CACL2_L: return this.$t('spreader_data.material_ca_cl2_l')
                case this.ROUTA_MATERIAL_K_FORM_SOLUTION: return this.$t('spreader_data.material_k_form_solution')
                case this.ROUTA_MATERIAL_NAFO_L: return this.$t('spreader_data.material_nafo_l')
                case this.ROUTA_MATERIAL_MGCL: return this.$t('spreader_data.material_mgcl')
                case this.ROUTA_MATERIAL_OTHER: return this.$t('spreader_data.material_other')
            }
            return materialCode
        },
        getMaterialColor(materialCode) {
           switch (materialCode) {
                case this.ROUTA_MATERIAL_NA_CL:
                    return '#7FC000'
                case this.ROUTA_MATERIAL_NA_CL_SOLUTION:
                    return '#34B98B'
                case this.ROUTA_MATERIAL_NAFO_K:
                case this.ROUTA_MATERIAL_NAFO_L:
                    return '#FFEB44'
                case this.ROUTA_MATERIAL_NACL_SAND:
                case this.ROUTA_MATERIAL_SAND:
                case this.ROUTA_MATERIAL_CACL_SAND:
                    return '#EFC58D'
                case this.ROUTA_MATERIAL_CACL_K:
                case this.ROUTA_MATERIAL_CACL2_L:
                    return '#94d2d0'
                case this.ROUTA_MATERIAL_K_FORM_SOLUTION:
                    return '#ccd250'
           }
            return "#bcbcbc"
        }
    }
}
