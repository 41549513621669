<template>
    <div>
        <b-navbar
            variant="faded"
            @click="closeAndUpdate"
        >
            <div
                v-if="!editMode"
                class="header pl-0 ml-0"
            >
                <span class="title pl-0">{{ $t('calendar.title') }}</span>
            </div>
            <div
                v-else
                class="header"
                @click="closeAndUpdate"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer"> {{ $t('common.back') }}</span>
            </div>
        </b-navbar>
        <!-- Calendar controls -->
        <b-row>
            <div class="col-sm-4">
            </div>
        </b-row>
        <b-row class="col-sm-12 nopads" v-if="!showWizard">
            <div class="col-md-4 pb-1 pt-1">
                <b-btn-group class="w-100">
                    <b-button
                        class="fa fa-arrow-left nopads"
                        id="fa-arrow-date-left"
                        @click="addDays(+7)">
                    </b-button>
                    <b-form-input
                        size="sm"
                        v-model="calendarStartDate"
                        type="date"
                    />
                    <b-button
                        class="fa fa-arrow-right nopads"
                        id="fa-arrow-date-right"
                        @click.stop="addDays(-7)">
                    </b-button>
                </b-btn-group>
            </div>
                <div v-if="!disableContractActions" class="col-md-2 pt-2">
                <b-form-checkbox
                    v-model="onlyUserContracts"
                    size="sm"
                    value=1
                    unchecked-value=0>
                    {{ $t('contracts.only_user_owned_contracts') }}
                </b-form-checkbox>
            </div>
            <div id="filter" class="col-md-3">
                <span style="font-size: .9em">{{ $t('calendar.order_status') }} </span>
                    <multiselect
                        class="col-12"
                        :multiple="true"
                        :options="orderStatusValues"
                        :custom-label="value => orderStatusNames[value]"
                        v-model="value"
                        :searchable="true"
                        :close-on-select="false"
                        :placeholder="$t('orders.select_status')"
                        :select-label="$t('orders.select_state')"
                        :selected-label="$t('common.selected')"
                        :deselect-label="$t('common.remove_selection')"
                    ></multiselect>
            </div>
            <div id="filter" class="col-md-3">
                <span style="font-size: .9em">{{ $t('calendar.assignment_status') }} </span>
                <multiselect
                    :multiple="true"
                    class="col-sm-12"
                    :options="assignmentStatusValues"
                    :custom-label="assignmentsValue => assignmentStatusNames[assignmentsValue]"
                    v-model="assignmentsValue"
                    :close-on-select="false"
                    :searchable="false"
                    :placeholder="$t('orders.select_status')"
                    :select-label="$t('orders.select_state')"
                    :selected-label="$t('common.selected')"
                    :deselect-label="$t('common.remove_selection')"
                />
            </div>
            <div v-if="!disableContractActions" class="col-md-2 text-right">
                <b-button
                    variant="success"
                    class="result-button"
                    @click="addContract"
                >
                    {{ $t('calendar.new_contract') }}
                </b-button>
            </div>
        </b-row>
        <div v-if="!showWizard" class="calendar-row p-2 mb-2">
            <!-- Calendar header -->
            <calendar-header
                :text-col-width-percentage="40"
                :time-col-width="timeColWidth"
                :visible-weeks-limit="visibleWeeksLimit"
                :calendar-start-week="calendarStartWeek"
            />

            <!-- Contracts -->
            <contract-calendar-item
                v-for="contract in contracts"
                v-bind:key="contract.id"
                ref="contracts"
                :contract="contract"
                :calendar-start-week="calendarStartWeek"
                :calendar-start-date="new Date(calendarStartDate)"
                :visible-weeks-limit="visibleWeeksLimit"
                :time-col-width="timeColWidth"
                :text-col-width-percentage="40"
                :filter="value"
                :assignment-filter="assignmentsValue"
                :auto-expand="autoExpand"
                :disable-contract-selection="disableContractActions"
                @showContract="showContract"
                @showOrder="showOrder"
                @addOrder="addOrder"
                @orderUpdated="$emit('orderUpdated')"
                @addAssignment="addAssignment"
                @showAssignment="showAssignment"/>
        </div>
        <transition name="fade">
            <contract-wizard
                v-if="showWizard"
                :user="user"
                @customerSet="setCustomer"
                @contractorSet="setContractor"
                @closeWizard="closeWizard"
            />
        </transition>
        <div v-if="(selectedContract && !showWizard) || (editMode && !showWizard)" class="details-container">
            <div class="details dynamic_width">
                <contract-editor
                    v-if="selectedContract"
                    v-model="selectedContract"
                    :contract-trip-list="true"
                    :active-order-editor="activeOrderEditor"
                    :user="user"
                    :show-title="false"
                    @close="closeAndUpdate(selectedContract)"
                />
            </div>
        </div>
        <div v-if="selectedOrder" class="details-container">
            <div class="details dynamic_width">
                <order-editor
                    v-if="selectedOrder"
                    @close="closeOrder"
                    @closeAndUpdate="closeOrder"
                    :order="selectedOrder"
                    :contract="contract ? contract : clickedContract"
                    :user="user"
                    :show-title="false"
                />
            </div>
        </div>
        <div v-if="selectedAssignment" class="details-container">
            <div class="details dynamic_width">
                <work-assignment-editor
                    v-if="selectedAssignment"
                    @close="closeAssignment"
                    @closeAndUpdate="closeAssignment"
                    :work-assignment="selectedAssignment"
                    :is-observer="this.hasObserver(this.user.roles)"
                    :user="user"
                    :no-margin="true"
                />
            </div>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>


<script>
import {orderStatus, workManagementHelper} from '../mixins/WorkManagementMixin'
import {workAssignmentHelper} from '../mixins/WorkAssignmentMixin'
import {restApi} from '../mixins/RestApiMixin'
import CalendarHeader from "@/components/contractcalendar/CalendarHeader";
import ContractCalendarItem from "@/components/contractcalendar/ContractCalendarItem";
import {timeUtils} from "@/components/mixins/TimeUtils";
import OrderEditor from "@/components/order/OrderEditor";
import ContractWizard from "@/components/contract/ContractWizard";
import WorkAssignmentEditor from "@/components/workassignment/WorkAssignmentEditor";
import {userHelper} from "@/components/mixins/UserMixin";
import ContractEditor from "@/components/contract/ContractEditor";

export default {
    name: 'ContractCalendar',
    components: {
        ContractEditor,
        WorkAssignmentEditor, ContractWizard,
        OrderEditor, ContractCalendarItem, CalendarHeader},
    mixins: [workManagementHelper, workAssignmentHelper, restApi, timeUtils, userHelper],
    props: {
        contract: {
            type: Object,
            default: null
        },
        user: {
            type: Object,
            default: null
        },
        disableContractActions: {
            type: Boolean,
            default: false
        },
        autoExpand: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            visibleWeeksLimit: 20,
            calendarStartDate: this.getCurrentDay(),
            calendarStartWeek: this.getWeekNumber(this.getCurrentDay()),
            timeColWidth: 50,
            contracts: [],
            orders: [],
            selectedContract: null,
            showWizard: false,
            selectedOrder: null,
            selectedAssignment: null,
            activeOrderEditor: null,
            onlyUserContracts: 1,
            editMode: false,
            loading: false,
            filter: [],
            value: [],
            assignmentFilter: [],
            assignmentsValue: [],
            clickedContract: null
        }
    },

    watch: {
        calendarStartDate() {
            this.calendarStartWeek = this.getWeekNumber(this.calendarStartDate)
        },
        onlyUserContracts() {
            this.lastSearchedParams.onlyUserOwnedContracts = this.onlyUserContracts
            this.fetchContracts(this.lastSearchedParams)
        },
    },
    computed: {
        orderStatusNames() {
            return {
                [orderStatus.STORED]: this.$t('orders.status_stored'),
                [orderStatus.PLAN]: this.$t('orders.status_plan'),
                [orderStatus.OPEN]: this.$t('orders.status_open'),
                [orderStatus.IN_PROGRESS]: this.$t('orders.status_in_progress'),
                [orderStatus.COMPLETED]: this.$t('orders.status_completed'),
                [orderStatus.CANCELLED]: this.$t('orders.status_cancelled'),
                [orderStatus.ABORTED]: this.$t('orders.status_aborted'),
                [orderStatus.APPROVED]: this.$t('orders.status_approved'),
                [orderStatus.INVOICED]: this.$t('orders.status_invoiced'),
                [orderStatus.CLOSED]: this.$t('orders.status_closed'),
            }
        },
        orderStatusValues() {
            return Object.values(orderStatus)
        },
        assignmentStatusNames() {
            return {
                [this.OPEN]: this.$t('orders.status_open'),
                [this.COMPLETED]: this.$t('orders.status_completed'),
                [this.ASSIGNED]: this.$t('work_assignment.assigned'),
                [this.IN_PROGRESS]: this.$t('orders.status_approved'),
                [this.CANCELLED]: this.$t('orders.status_cancelled'),
            }
        },
        assignmentStatusValues() {
            return [this.OPEN, this.ASSIGNED, this.IN_PROGRESS, this.COMPLETED, this.CANCELLED]
        }
    },
    mounted() {
        if (this.contract) {
            //If start_date is null use current day as fallback to keep calendar working
            if (this.contract.start_date) {
                this.calendarStartDate = this.timestampToDateInput(this.contract.start_date)
            }
            this.contract.start_date = this.timestampToISOString(this.contract.start_date)
            this.contract.end_date = this.timestampToISOString(this.contract.end_date)
            this.contracts = [this.contract]
        } else {
            let now = this.calendarStartDate;
            let to = new Date()
            to.setDate(to.getDate() + (7 * this.visibleWeeksLimit));
            this.lastSearchedParams = {
                startDate: now,
                endDate: to.toISOString(),
                onlyUserOwnedContracts: this.onlyUserContracts
            }
            this.fetchContracts(this.lastSearchedParams)
        }
        // Pre-select order values to ongoing or open ones only
        this.value=[this.OPEN, this.IN_PROGRESS]
    },
    methods: {
        fetchContracts: function (params) {
            this.loading = true
            this.lastSearchedParams = params
            this.contracts = []
            this.restFetchParams(this.contractOpenUrl, this.lastSearchedParams, this.handleResponse)
        },

        handleResponse: function (response) {
            this.loading = false
            if (response && response.data) {
                this.contracts = response.data
            }
        },
        closeAndUpdate(updatedContract) {
            let index = this.contracts.findIndex(item => item.id === updatedContract.id)
            if (index >= 0) {
                this.$refs.contracts[index].refreshContract(updatedContract)
            }
            this.selectedContract = null
            this.editMode = !this.editMode
            this.fetchContracts(this.lastSearchedParams)
            this.showWizard = false
        },
        closeWizard() {
            this.showWizard = false
            this.editMode = false
            this.selectedContract = null
        },
        addContract: function () {
            this.showWizard = true
            this.editMode = true
            this.selectedContract = this.newContract()
        },
        addDays(days) {
            let now = new Date(this.calendarStartDate)
            now.setDate(now.getDate()-days)
            now.setHours(12)
            this.calendarStartDate = now.toISOString().slice(0, 10);
        },
        showContract(contract) {
            if (!this.disableContractActions) {
                this.editMode = !this.editMode
                if (contract && contract.id) {
                    this.fetchContract(contract.id)
                }
            }
        },
        fetchContract: function(id) {
            this.loading = true
            let params = {}
            params.id = id
            this.restFetchParams(this.contractUrl, params, this.handleContract, this.fail)
        },
        handleContract: function (response) {
            let fetchedContract = response ? response.data[0] : {}
            this.selectedContract = this.jsonToItem(fetchedContract)
            this.loading = false
        },
        addOrder: function (contract) {
            this.selectedOrder = this.createEditorOrder()
            this.selectedOrder.contract = contract ? contract.id : null
            this.selectedOrder.contract_name = contract ? contract.name : null
            this.selectedOrder.customer = contract ? contract.customer.id : null
            this.selectedOrder.customer_name = contract ? contract.customer.name : null
            this.selectedOrder.contractor = contract ? contract.contractor.id : null
            this.selectedOrder.contractor_name = contract ? contract.contractor.name : null
        },
        showOrder(order, contract) {
            if (order && order.id) {
                this.clickedContract = contract
                this.fetchOrder(order.id)
            }
        },
        fetchOrder: function (id) {
            this.loading = true
            this.restFetch(this.orderUrl + "/" + id, this.handleOrders, this.fail)
        },
        handleOrders: function (response) {
            let fetchedOrder = response ? response.data : {}
            this.selectedOrder = this.createEditorOrder(fetchedOrder)
            this.loading = false
        },
        addAssignment: function (contract) {
            let newAssignment = {
                contract: contract,
                work_order: contract.work_order && contract.work_order.id ? contract.work_order : null
            }
            this.selectedAssignment = this.newWorkAssignment(newAssignment)
        },
        showAssignment(assignment) {
            if (assignment) {
                this.selectedAssignment = assignment
            }
        },
        checkActiveOrder: function(active){
            this.activeOrderEditor = active
        },
        setCustomer: function (customer) {
            this.selectedContract.customer = customer
            this.editMode = (this.selectedContract.customer && this.selectedContract.customer.id && this.selectedContract.contractor && this.selectedContract.contractor.id)
            this.showWizard = !this.editMode
        },
        setContractor: function (contractor) {
            this.selectedContract.contractor = contractor
            this.editMode = (this.selectedContract.customer && this.selectedContract.customer.id && this.selectedContract.contractor && this.selectedContract.contractor.id)
            this.showWizard = !this.editMode
        },
        closeOrder() {
            if (this.selectedOrder !== null) {
                this.refresh()
                this.$emit('orderUpdated')
                this.selectedOrder = null
            }
        },
        closeAssignment() {
            if (this.selectedAssignment != null) {
                let index = this.contracts.findIndex(item => item.id === this.selectedAssignment.contract.id)
                if (index >= 0){
                    this.$refs.contracts[index].fetchAssignments()
                }
                this.selectedAssignment = null
            }
        },
        refresh() {
            if (this.contracts.length === 1) {
                this.$refs.contracts[0].refreshContract(this.contracts[0])
            }
        },
        refreshDutyOfficer() {
            if (this.contracts.length === 1) {
                this.$refs.contracts[0].fetchDutyOfficerSchedules()
            }
        }
    }
}
</script>
<style scoped>

#fa-arrow-date-right, #fa-arrow-date-left {
    background: #ffffff;
    border-top: 1px #BCBCBC solid;
    border-right: 1px #BCBCBC solid;
    color: #3B3933;
    margin: .1em;
    padding: .3em;
    font-size: .8em;
    user-select: none;
    min-height: .3em;
}
#fa-arrow-date-right:hover, #fa-arrow-date-left:hover {
    font-weight: bold;
}
@media (min-width: 768px) {
    #filter {
        top: -20px;
    }
}


</style>
