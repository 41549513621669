<template>
    <!-- Company List -->
    <div class="col-sm-12">
        <div
            v-if="isAdmin"
            class="data-list-item add"
            @click.stop="$emit('add')"
        >
            <div class="item-img-cont add-title">
                <i class="fa fa-plus"/>
            </div>
            <div class="item-text-cont">
        <span class="item-title-add">
          {{ $t('common.add_new') }}
        </span>
                <font-awesome-icon
                    icon="coins"
                    class="card-body-icon"
                />
            </div>
        </div>
        <transition name="fade">
            <div
                class="col-sm-12 pb-2 result-table"
            >
                <b-table
                    :small="true"
                    :responsive="true"
                    :items="results"
                    :fields="fields"
                    :bordered="true"
                    hover
                    class="pointer"
                    @row-clicked="emitEdit"
                >
                    <template slot="empty">
                        <div class="text-center my-2">
                            {{ $t('area_report.no_results') }}
                        </div>
                    </template>
                </b-table>
            </div>
        </transition>
    </div>
</template>

<script>
import {workManagementHelper} from '../mixins/WorkManagementMixin'

export default {
    name: 'CompanyList',
    mixins: [workManagementHelper],
    data() {
      return {
          fields: [
              {key: 'id', label: 'Id', sortable: true},
              {key: 'name', label: this.$t('companies.name'), sortable: true},
              {key: 'business_id', label: this.$t('companies.business_id'), sortable: false},
              {key: 'vat_id', label: this.$t('companies.vat_id'), sortable: false},
              {key: 'work_order', label: this.$t('companies.work_order'), sortable: true},
          ]
      }
    },
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        },
        isAdmin: Boolean
    },
    methods: {
        emitEdit(item) {
            this.$emit('edit', item)
        }
    }
}
</script>
