import { render, staticRenderFns } from "./MaterialStorageEditor.vue?vue&type=template&id=22f387d6&scoped=true"
import script from "./MaterialStorageEditor.vue?vue&type=script&lang=js"
export * from "./MaterialStorageEditor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22f387d6",
  null
  
)

export default component.exports