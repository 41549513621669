<template>
    <!-- Draggable and resizable time line -->
    <div style="display: flex; position: absolute; left: 0; top: 0; bottom: 0; width: 100%">
        <div style="width: 100%; position: relative; height: 100%"
             class="time-line-container"
             :class="{'resize': isResizeMode, 'dragging': isDraggingMode}"
             @mousemove="containerMouseMove"
             @mouseup="containerMouseUp"
             @mousedown="containerMouseDown"
             @mouseout="containerMouseOut">
            <div
                ref="timeline"
                class="time-line"
                :style="{left: timelineStartX + 'px', width: timelineWidthValue + 'px', background: colorValue}">
                <span class="start-date-text">{{ itemStartDate ? getShortDateWithoutTimezone(itemStartDate.toISOString()) : '' }}</span>
                <span class="end-date-text">{{ itemEndDate ? getShortDateWithoutTimezone(itemEndDate.toISOString()) : '' }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    name: 'TimeLineItem',
    mixins: [timeUtils],
    data: function () {
        return {
            NONE: 0,
            DRAG: 1,
            RESIZE_START: 2,
            RESIZE_END: 3,
            dragging: false,
            resizing: false,
            timelineStartX: 0,
            timelineWidthValue: 100,
            timelineEndDate: null,
            itemStartDate: null,
            itemEndDate: null,
            datesUpdated: false,
            lastX: 0,
            hoverMode: 0,
            colorValue: null,
            weeks: []
        }
    },
    props: {
        itemId: {
            type: Number,
            default: -1
        },
        color: {
            type: String,
            default: '#052f5b'
        },
        timeColWidth: {
            type: Number,
            default: null,
            required: true
        },
        timelineStartDate: {
            type: Date,
            default: null,
            required: true
        },
        startDate: {
            type: Date,
            default: null
        },
        endDate: {
            type: Date,
            default: null
        },
        static: {
            type: Boolean,
            default: false
        },
        calendarStartWeek: {
            type: Number,
            default: 70
        },
        visibleWeeksLimit: {
            type: Number,
            default: 20
        },
        calendarStartDate: {
            type: Date,
            default: null
        }
    },
    computed: {
        weekColWidth() {
            return Math.round(this.timeColWidth / 7) * 7
        },
        isResizeMode() {
            return this.hoverMode === this.RESIZE_END || this.hoverMode === this.RESIZE_START
        },
        isDraggingMode() {
            return this.hoverMode === this.DRAG
        }
    },
    watch: {
        calendarStartDate: function () {
            this.initTable()
        },
        startDate: function () {
            if (this.startDate instanceof Date && !isNaN(this.startDate)) {
                this.itemStartDate = new Date(this.startDate)
                this.init()
            }
        },
        endDate: function () {
            if (this.endDate instanceof Date && !isNaN(this.endDate)) {
                this.itemEndDate = new Date(this.endDate)
                this.init()
            }
        },
        weeks: function () {
            this.calculateTimeline()
        },
        color: function () {
            this.colorValue = this.color
        }
    },
    created() {
        this.itemStartDate = new Date(this.startDate)
        this.itemEndDate = new Date(this.endDate)
        this.colorValue = this.color
        this.init()
    },
    methods: {
        initTable() {
            this.weeks = []
            let startWeek = this.calendarStartWeek
            for (let i = 0; i < this.visibleWeeksLimit; i++) {
                this.weeks.push(this.$t('calendar.wk_prefix') + (startWeek+i))
            }
        },
        init() {
            if (this.itemStartDate) {
                this.itemStartDate.setHours(0, 0, 0, 0)
            }
            if (this.itemEndDate) {
                this.itemEndDate.setHours(24, 0, 0, 0)
            }
            this.timelineEndDate = new Date()
            this.timelineEndDate.setDate(this.timelineEndDate.getDate() + (7 * this.weeks));
            this.timelineEndDate.setHours(23, 59, 59, 0)
            this.calculateTimeline()
        },
        calculateTimeline() {
            let diffInDays = 0
            let totalVisibleDays = this.weeks * 7
            let dayMovement = this.weekColWidth / 7
            // Start time and position handling
            if (!this.itemStartDate && !this.itemEndDate) {
                this.timelineStartX = 0
                this.timelineWidthValue = dayMovement
            } else if (!this.itemStartDate) {
                // No start date given
                this.timelineStartX = 0
            } else {
                // Set time line starting point and position
                let diff = this.itemStartDate.getTime() - new Date(this.timelineStartDate).getTime()
                diffInDays = Math.floor(diff / (1000 * 60 * 60 * 24))
                this.timelineStartX =  diffInDays * dayMovement
                // Set the width
                if (this.itemEndDate) {
                    let durationInDays = (this.itemEndDate.getTime() - this.itemStartDate.getTime()) / (1000 * 60 * 60 * 24)
                    this.timelineWidthValue = durationInDays * dayMovement
                } else {
                    // No end time given - Stretch to end of calendar
                    let dayDuration = totalVisibleDays - diffInDays
                    this.timelineWidthValue = dayDuration * dayMovement
                }
            }
        },
        containerMouseMove(e) {
            if (!this.static) {
                if (this.resizing) {
                    //Element resize
                    this.resizingDateElement(e)
                } else if (this.dragging) {
                    // Move the timeline by one day
                    this.draggingDateElement(e)
                } else {
                    // No mouse down action on going - Decide based on x coordinate and timeline width/ position if we show resize or drag
                    this.selectClickArea(e)
                }
            }
        },
        resizingDateElement: function (e) {
            if (this.static) return;
            if (!this.resizing) return;
            let moved = e.offsetX - this.lastX
            let dayMovement = this.weekColWidth / 7
            if (Math.abs(moved) <= dayMovement) return;
            this.lastX = e.offsetX
            this.$nextTick(() => {
                dayMovement = moved > 0 ? dayMovement : dayMovement * -1
                moved = (moved / 7).toFixed(0) * 7
                if (this.hoverMode === this.RESIZE_START) {
                    this.timelineWidthValue -= moved
                    if (this.timelineWidthValue < 7) return this.timelineWidthValue += moved
                    this.timelineStartX += moved
                    moved > 0 ? dayMovement += moved - 7 : dayMovement += moved + 7
                    this.updateStartDate(Math.floor(dayMovement / (this.weekColWidth / 7)))
                } else if (this.hoverMode === this.RESIZE_END) {
                    this.timelineWidthValue += moved
                    if (this.timelineWidthValue < 7) return this.timelineWidthValue -= moved
                    moved > 0 ? dayMovement += moved - 7 : dayMovement += moved + 7
                    this.updateEndDate(Math.floor(dayMovement / (this.weekColWidth / 7)))
                }
            })
        },
        draggingDateElement: function (e) {
            let moved = e.offsetX - this.lastX
            let dayMovement = this.weekColWidth / 7
            if (Math.abs(moved) > dayMovement) {
                dayMovement = moved > 0 ? dayMovement : dayMovement * -1
                this.lastX = e.offsetX
                this.$nextTick(() =>  {
                    e.preventDefault();
                    moved = ( moved/7).toFixed(0)*7
                    this.timelineStartX +=  moved
                    moved > 0 ? dayMovement += moved-7 : dayMovement += moved+7
                    this.updateDates(Math.floor(dayMovement / (this.weekColWidth/7)))
                })
            }
        },
        selectClickArea: function (e){
            let timelineEnd = this.timelineStartX + this.timelineWidthValue
            if (e.offsetX >= this.timelineStartX && e.offsetX <= timelineEnd) {
                // Mouse is over timeline
                const limit = 6
                if (e.offsetX < (this.timelineStartX + limit)) {
                    this.hoverMode = this.RESIZE_START
                } else if (e.offsetX > (timelineEnd - limit)) {
                    this.hoverMode = this.RESIZE_END
                } else {
                    this.hoverMode = this.DRAG
                }
            } else {
                // Mouse outside time line
                this.hoverMode = this.NONE
            }
        },
        containerMouseUp() {
            if (this.resizing) {
                this.resizing = false
            } else if (this.dragging) {
                this.dragging = false
            }
            if (this.datesUpdated) {
                this.datesUpdated = false
                this.$emit('datesChanged', this.itemId, this.itemStartDate, this.itemEndDate)
            }
        },
        containerMouseOut() {
            this.containerMouseUp()
        },
        containerMouseDown(e) {
            if (!this.dragging && this.hoverMode === this.DRAG) {
                this.lastX = e.offsetX
                this.dragging = true
            }
            if (!this.resizing && (this.hoverMode === this.RESIZE_START || this.hoverMode === this.RESIZE_END)) {
                this.lastX = e.offsetX
                this.resizing = true
            }
        },
        updateDates(days) {
            this.datesUpdated = true
            this.itemStartDate.setDate(this.itemStartDate.getDate() + days)
            this.itemEndDate.setDate(this.itemEndDate.getDate() + days)
        },
        updateStartDate(days) {
            this.datesUpdated = true
            this.itemStartDate.setDate(this.itemStartDate.getDate() + days)
        },
        updateEndDate(days) {
            this.datesUpdated = true
            this.itemEndDate.setDate(this.itemEndDate.getDate() + days)
        }
    }
}
</script>
<style scoped>
.time-line-container {
    overflow: hidden;
}
.time-line {
    display: block;
    position: absolute;
    top: 0;
    left: 5em;
    bottom: 0;
    border-radius: .3em;
    margin-top: .2em;
    margin-bottom: .2em;
    z-index: 4;
    pointer-events: none;
    overflow: hidden;
}
.resize {
    cursor: e-resize;
}
.dragging {
    cursor: grab;
}
.start-date-text, .end-date-text {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    max-width: 50%;
    font-size: .8em;
    color: #FFFFFF;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    pointer-events: none;
    padding-right: .5em;
    padding-left: .2em;
    margin: 0;
    user-select: none;
}
.end-date-text {
    text-align: right;
    left: auto;
    right: 0;
    padding-left: .5em;
    padding-right: .2em;
}
</style>
