<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div
        id="inspection-report-list"
        class="col-sm-12 nopads"
    >
        <div class="search-form">
            <div class="col-sm-12 search-form-title">
                <font-awesome-icon icon="search"/>
                {{ $t('common.search') }}
            </div>
            <b-form
                @submit.prevent="onSubmitSearch"
                @reset="onResetSearch"
            >
                <!-- Show this conditionally, if vehicle type is selected -->
                <!--
                <div class="col-sm-6">
                  <span class="search-title">{{ $t('ins_report.search_with_license') }}</span>
                  <b-form-group
                    id="vehicleInputGroup"
                    class="title no-margin"
                    label-for="vehicleInput"
                  >
                    <b-form-input
                      id="vehicleInput"
                      v-model="licensePlate"
                      type="text"
                      size="sm"
                      class="no-margin"
                      :placeholder="$t('ins_report.enter_license')"
                    />
                  </b-form-group>
                </div>
                -->
                <b-row class="nopads">
                    <div class="col-sm-6">
                        <span class="search-title">{{ $t('ins_report.from_date') }}</span>
                        <b-form-group
                            class="title no-margin"
                            label-for="from_date"
                        >
                            <b-form-input
                                id="from_date"
                                v-model="fromDate"
                                type="date"
                                class="no-margin"
                                size="sm"
                            />
                        </b-form-group>
                    </div>

                    <div class="col-sm-6">
                        <span class="search-title">{{ $t('ins_report.to_date') }}</span>
                        <b-form-group
                            class="title"
                            label-for="to_date"
                        >
                            <b-form-input
                                id="to_date"
                                v-model="toDate"
                                type="date"
                                size="sm"
                            />
                        </b-form-group>
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-6">
                        <span class="span-title">{{ $t('ins_report.select_type') }}</span>
                        <b-form-group
                            class="title"
                            label-for="type"
                        >
                            <b-form-select
                                id="type"
                                v-model="type"
                                size="sm"
                            >
                                <option
                                    v-for="type in types"
                                    :key="type.value"
                                    :value="type.value"
                                >
                                    {{ type.text }}
                                </option>
                            </b-form-select>
                        </b-form-group>
                    </div>
                </b-row>

                <div class="col-sm-12 button-container">
                    <b-button
                        class="form-button"
                        type="submit"
                        variant="primary"
                    >
                        {{ $t('ins_report.search') }}
                    </b-button>
                    <b-button
                        class="form-button"
                        type="reset"
                        variant="danger"
                    >
                        {{ $t('ins_report.clear') }}
                    </b-button>
                </div>
            </b-form>
        </div>
        <transition name="fade">
            <div class="col-sm-12 list-container">
                <div
                    v-if="results"
                    class="col-sm-12 list-content result-table"
                >
                    <!-- Filtering functions -->
                    <div class="col-12 p-3">
                        <b-form-group
                            label-for="sort-by-select"
                            label-cols-sm="6"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-1"
                        >
                            <b-input-group size="sm">
                                <b-form-select
                                    id="sort-by-select"
                                    v-model="sortBy"
                                    class="mr-1"
                                    :options="sortOptions"
                                >
                                    <template #first>
                                        <option value="">{{ placeholderField }}</option>
                                    </template>
                                </b-form-select>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group
                            label-for="filter-input"
                            label-cols-sm="6"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-1 mt-2"
                        >
                            <b-input-group size="sm">
                                <b-form-input
                                    id="filter-input"
                                    v-model="filter"
                                    type="search"
                                    :placeholder="placeholderQuery"
                                ></b-form-input>
                            </b-input-group>
                        </b-form-group>
                    </div>

                    <!-- Main table element -->
                    <b-table
                        hover
                        show-empty
                        small
                        :items="filteredItems"
                        :fields="fields"
                        class="pointer"
                        @row-clicked="emitDetails"
                    >
                        <template v-slot:cell(created)="row">
                            {{ toLocalTime(row.value) }}
                        </template>
                        <template v-slot:cell(outcome)="data">
                            <font-awesome-icon
                                v-if="hasStatus(data.item.results, resultBan)"
                                icon="exclamation-circle"
                                class="ban"
                            />
                            <font-awesome-icon
                                v-else-if="hasStatus(data.item.results, resultDefect)"
                                icon="exclamation-circle"
                                class="defect"
                            />
                        </template>
                    </b-table>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import {inspectionHelper} from '../mixins/InspectionMixin'
import {timeUtils} from '../mixins/TimeUtils'

export default {
    name: 'InspectionReportList',
    mixins: [inspectionHelper, timeUtils],
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            sortBy: 'created',
            sortDesc: true,
            sortDirection: 'asc',
            licensePlate: '',
            fromDate: '',
            toDate: '',
            filter: '',
            type: null,
            tableItems: [],
            types: [
                {value: null, text: this.$t('observations.search_all')},
                {value: 1, text: this.$t('inspection_template.type.vehicle')},
                {value: 2, text: this.$t('inspection_template.type.work')},
                {value: 3, text: this.$t('inspection_template.type.task')},
                {value: 4, text: this.$t('inspection_template.type.company')},
                {value: 5, text: this.$t('inspection_template.type.bridge')},
                {value: 6, text: this.$t('inspection_template.type.culvert')},
                {value: 7, text: this.$t('inspection_template.type.bridge')}
            ],
            fields: [
                {key: 'outcome', label: '', sortable: false},
                {key: 'id', label: this.$t('common.id'), sortable: true},
                {key: 'created', label: this.$t('ins_report.list_label.created'), sortable: true, filterByFormatted: true},
                {key: 'type', label: this.$t('ins_report.list_label.report_type'), sortable: true, filterByFormatted: true},
                {key: 'name', label: this.$t('ins_report.list_label.name'), sortable: true, filterByFormatted: true},
                {key: 'frequency', label: this.$t('inspection_editor.inspection_frequency'), sortable: true, filterByFormatted: true},
                {key: 'target', label: this.$t('ins_report.list_label.target'), sortable: true, filterByFormatted: true},
                {key: 'inspector', label: this.$t('ins_report.list_label.inspector'), sortable: true, filterByFormatted: true},
                {key: 'contract', label: this.$t('ins_report.list_label.contract'), sortable: true, filterByFormatted: true }
            ]
        }
    },
    computed: {
        sortOptions() {
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        placeholderField() {
            return this.$t('ins_report.choose_field')
        },
        placeholderQuery() {
            return this.$t('ins_report.query')
        },
        filteredItems() {
            if (!this.filter) {
                // If no search query, return all items
                return this.tableItems;
            }
            return this.tableItems.filter(item => String(item[this.sortBy])
                        .toLowerCase()
                        .includes(this.filter.toLowerCase())
            )
        },
    },
    watch: {
        results() {
            // Conver to table items
            let temp = []
            this.results.forEach(item =>
                temp.push({
                    'id': item.id,
                    'type': this.getInspectionTypeName(item.type),
                    'created': item.created,
                    'name': item.name,
                    'frequency': this.getInspectionFrequency(item.frequency),
                    'target': this.getInspectionTarget(item),
                    'inspector': this.getInspector(item.reporter),
                    'results': item.results,
                    'contract': item.contract ? item.contract.name : ''
                })
            )
            this.tableItems = temp
        }
    },
    mounted() {
        if (!this.fromDate) {
            let now = new Date()
            now.setDate(1)
            this.fromDate = this.timestampToDateInput(now.getTime())
        }
    },
    methods: {
        onResetSearch: function () {
            this.licensePlate = ''
            this.fromDate = ''
            this.toDate = ''
        },
        onSubmitSearch: function () {
            var params = {}
            if (this.licensePlate.trim()) {
                params.license_plate = this.licensePlate
            }
            if (this.fromDate !== null && this.fromDate.trim()) {
                params.from = this.localTimeToUtcTime(this.fromDate).toISOString()
            }
            if (this.toDate !== null && this.toDate.trim()) {
                params.to = this.getEndOfTheDay(this.toDate).toISOString()
            }
            params.type = this.type
            this.$emit('search', params)
        },
        emitDetails: function (details) {
            let item = this.results.find(report => report.id === details.id)
            if (item) {
                this.$emit('details', item)
            }
        },
        hasStatus: function (results, status) {
            return results.find(item => item.result === status) !== undefined
        },
        sortCompare(aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {
            if (key === 'inspector') {
                const a = this.getInspector(aRow['reporter'])
                const b = this.getInspector(bRow['reporter'])
                return a.localeCompare(b, compareLocale, compareOptions)
            } else if (key === 'target') {
                const a = this.getInspectionTarget(aRow)
                const b = this.getInspectionTarget(bRow)
                return a.localeCompare(b, compareLocale, compareOptions)
            } else if (key === 'created') {
                const a = aRow.created
                const b = bRow.created
                return a < b ? -1 : a > b ? 1 : 0
            } else if (key === 'frequency') {
                const a = this.getInspectionFrequency(aRow.frequency)
                const b = this.getInspectionFrequency(bRow.frequency)
                return a < b ? -1 : a > b ? 1 : 0
            }
            return false
        }
    }
}
</script>
