<template>
    <div class="nopads">
    <!-- subcontractor selection-->
        <b-form-group
            class="title nopads"
            label-for="contractors"
        >
            <span class="span-title">{{ $t('relationships.executor') }}</span>
            <b-form-select
                v-model="selectedExecutor"
                size="sm"
                :disabled="executors.length < 1">
                <template slot="first">
                    <option :value="null">
                        {{ $t('orders.select_contractor') }}
                    </option>
                </template>
                <option
                    v-for="option in executors"
                    :key="option.id"
                    :value="option.id"
                >
                    {{ option.name }}
                </option>
            </b-form-select>
        </b-form-group>
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: 'ContractExecutorsFilter',
    mixins: [timeUtils, restApi],
    props:
        {
        contractIds: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data: function () {
        return {
            executors: [],
            selectedExecutor: null,
        }
    },
    watch: {
        contractIds: function () {
            this.fetchContractors()
        },
        selectedExecutor: function (){
            if(this.selectedExecutor){
                this.$emit('executorSet', this.executors.find(item => item.id === this.selectedExecutor))
            }else{
                this.$emit('executorSet', null)
            }
        }
    },
    mounted: function () {
        this.fetchContractors()
    },
    methods: {
        fetchContractors: function () {
            this.executors = []
            if (this.contractIds.length > 0) {
                this.restFetchParams(this.contractCompanyUrl, {contractIds: this.contractIds}, this.handleContractCompanies)
            }
        },
        handleContractCompanies: function (response) {
            if (response && response.data) {
                this.executors = response.data
                if (this.selectedExecutor) {
                    let exists = this.executors.find(item => item.id === this.selectedExecutor)
                    if (!exists) {
                        this.selectedExecutor = null
                    }
                }
            }
        },
    }
}
</script>
