<template>
    <div class="nopads">
    <!-- Contractor selection-->
        <b-form-group
            class="title nopads"
            label-for="contractors"
        >
            <span class="span-title">{{ $t('relationships.main_contractor') }}</span>
            <b-form-select
                v-model="selectedContractor"
                size="sm"
                :disabled="contractors.length < 1">
                <template slot="first">
                    <option :value="null">
                        {{ $t('orders.select_contractor') }}
                    </option>
                </template>
                <option
                    v-for="option in contractors"
                    :key="option.id"
                    :value="option.id"
                >
                    {{ option.name }}
                </option>
            </b-form-select>
        </b-form-group>
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: 'ContractContractorsFilter',
    mixins: [timeUtils, restApi],
    props:
        {
        contractIds: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data: function () {
        return {
            contractors: [],
            selectedContractor: null,
        }
    },
    watch: {
        contractIds: function () {
            this.fetchContractors()
        },
        selectedContractor: function (){
            if(this.selectedContractor){
                this.$emit('contractorSet', this.contractors.find(item => item.id === this.selectedContractor))
            }else{
                this.$emit('contractorSet', null)
            }
        }
    },
    mounted: function () {
        this.fetchContractors()
    },
    methods: {
        fetchContractors: function () {
            this.contractors = []
            this.loading = true
            this.restFetch(this.contractorUrl, this.handleContractors)
        },
        handleContractors: function (response) {
            if (response && response.data) {
                this.contractors = response.data
                if (this.selectedContractor) {
                    let exists = this.contractors.find(item => item.id === this.selectedContractor)
                    if (!exists) {
                        this.selectedContractor = null
                    }
                }
            }
            this.loading = false
        },
    }
}
</script>
