<template>
    <div class="col-12 nopads">
        <b-row>
            <span class="header" >{{ $t('contracts.duty_officer_schedule') }}</span>
        </b-row>
        <contract-duty-officer
            @save="saveSchedule"
            :users="users"
            :dutyOfficerSchedules="dutyOfficerSchedules"
            ref="schedule"
        >
        </contract-duty-officer>
    </div>
</template>

<script>
import contractDutyOfficer from "@/components/contract/ContractDatePicker.vue";
import {restApi} from "@/components/mixins/RestApiMixin";
import {EventBus} from "@/event-bus";

export default {
    mixins: [restApi],
    components: {
        contractDutyOfficer
    },
    props: {
        contractId: {
            type: Number,
            default: null
        },

    },
    data() {
        return {
            dutyOfficerSchedules: [{}],
            users: [],
        };
    },
    mounted() {
        this.fetchUsers()
    },
    methods: {
        updateCalendar() {
            this.$emit('updateCalendar');
        },
        saveSchedule(schedule) {
            const params = {}
            params.schedules = schedule
            this.loading = true
            this.restUpdate(this.dutyOfficerScheduleUrl + `?contract=${this.contractId}`, params, () => {
                this.loading = false
                this.resetForm()
                this.updateCalendar()
                EventBus.$emit('show-alert', this.$t('common.alert_update_success'), 'success')
            }, () => {
                this.loading = false
                EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            })
        },
        resetForm() {
            this.$refs.schedule.clearForm()
        },
        fetchUsers() {
            this.loading = true;
            this.restFetch(this.userUrl, response => {
                this.loading = false
                this.users = []
                const users = response.data
                users.forEach(user => {
                    this.users.push({
                        id: user.id,
                        first_name: user.first_name,
                        last_name: user.last_name,
                    })
                })
            })
        },
    }
}
</script>
